import { UserAutocomplete } from 'components/AutocompleteAjax';
import Breadcrumb from 'components/Breadcrumbs/Breadcrumb';
import Table from 'components/Table';
import { WithUser, withUser } from 'contexts/UserContext';
import { IEngagement, IUser, IUserSummary, UserType } from 'interfaces';
import ApiService from 'services/ApiService';
import pushServerErrorToast from 'utils/pushServerErrorToast';
import switcherDidUpdate from 'utils/switcherDidUpdate';

import React, { ChangeEvent, Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';

import {
  Avatar,
  Button,
  Checkbox,
  EButtonSizes,
  EButtonVariant,
  Tooltip,
  pushToast
} from '@ryan/components';
import { ITableColumn } from '@ryan/components/dist/lib/components/Table';

import UserNameAndProfile from '../../components/Table/UserNameAndProfile/UserNameAndProfile';
import { formatToUserNameAndProfile } from '../../components/Table/UserNameAndProfile/utils';
import UnsavedChangesContext from '../../contexts/UnsavedChangesContext/UnsavedChangesContext';
import getAvatarUrl from '../../utils/getAvatarUrl';

import './EditTeam.scss';

interface IEditTeamProps
  extends WithUser,
    RouteComponentProps<{ engagementGuid: string }>,
    WithTranslation {}

interface IEditTeamState {
  engagement: IEngagement | null;
  searchQuery: string;
  userToAdd: IUserSummary | null;
  userIsValid: boolean;
  invalidUserText: string;
  usersToAddToTeam: IEngagementUserSummary[];
  currentUsers: IUserSummary[];
  pendingUsers: IUserSummary[];
}

interface IEngagementUserSummary extends IUserSummary {
  isGhosted: boolean;
}

type TGetUsersAutocomplete = {
  firstName: string;
  fullName: string;
  lastName: string;
  memberGuid: string;
  title: string;
  userAvatarDocumentGuid: string;
  userGuid: string;
};

interface ITrimDataToEditTeam {
  (data: any): TGetUsersAutocomplete[];
}

type TEditTeamUser = Omit<IUserSummary, 'firstName' | 'lastName'> & {
  firstName: string;
  lastName: string;
};

class EditTeam extends Component<IEditTeamProps, IEditTeamState> {
  private columns: ITableColumn<IEngagementUserSummary>[];

  constructor(props: IEditTeamProps) {
    super(props);
    const { t: getTextToDisplay } = props;

    this.state = {
      engagement: null,
      searchQuery: '',
      userToAdd: null,
      userIsValid: true,
      invalidUserText: '',
      usersToAddToTeam: [],
      currentUsers: [],
      pendingUsers: []
    };

    this.columns = [
      {
        id: 'firstName',
        label: getTextToDisplay('Name'),
        render: (row: IEngagementUserSummary) => (
          <UserNameAndProfile
            user={formatToUserNameAndProfile({ user: row as unknown as IUser })}
          />
        )
      },
      {
        id: 'title',
        label: getTextToDisplay('Title'),
        render: (row: IEngagementUserSummary) => (
          <div className="plainText">{row.title}</div>
        )
      },
      {
        id: 'showToClient',
        label: getTextToDisplay('Show to Client'),
        align: 'center',
        render: (row: IEngagementUserSummary) => (
          <div className="button-margins">
            <Checkbox
              checked={!row.isGhosted}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                this.onChangeGhosting(row, e.target.checked);
              }}
              value={row.userGuid}
            />
          </div>
        )
      },
      {
        id: 'removeUserFromList',
        label: '',
        render: (row: IEngagementUserSummary) => (
          <div className="button-margins">
            <Button
              className="removeUserFromList"
              icon="trash"
              onClick={this.handleRemoveUserFromList(row.userGuid)}
              text=""
              variant={EButtonVariant.TEXT}
            />
          </div>
        )
      }
    ];
  }

  static contextType = UnsavedChangesContext;
  context!: React.ContextType<typeof UnsavedChangesContext>;

  async componentDidMount() {
    const { setActiveAccountForEngagement } = this.props;
    const { engagementGuid } = this.props.match.params;

    try {
      const [{ data: engagement }, { data: users }] = await Promise.all([
        ApiService.getEngagement(engagementGuid),
        ApiService.getAllEngagementMembers(engagementGuid, UserType.Ryan)
      ]);

      setActiveAccountForEngagement(engagement);
      this.setState({
        engagement,
        currentUsers: users.currentUsers,
        pendingUsers: users.pendingUsers
      });
    } catch {
      pushServerErrorToast();
    }
  }

  async componentDidUpdate(prevProps: IEditTeamProps) {
    const { history, isEngagementInView } = this.props;
    const { engagement } = this.state;

    if (engagement && switcherDidUpdate(prevProps, this.props)) {
      const isInView = await isEngagementInView(engagement.engagementGuid);
      if (!isInView) {
        history.push('/app/projects');
      }
    }
  }

  clearError = () => {
    this.setState({ invalidUserText: '', userIsValid: true });
  };

  handleRemoveUserFromList = (userGuid: string) => () => {
    const { usersToAddToTeam } = this.state;
    this.setState({
      usersToAddToTeam: (usersToAddToTeam || []).filter(
        x => x.userGuid !== userGuid
      )
    });
  };

  handleRemoveAllUsersFromList = () => {
    this.setState({
      usersToAddToTeam: []
    });
  };

  handleSaveTeam = async () => {
    const { t: getTextToDisplay, history } = this.props;
    const { engagement, usersToAddToTeam } = this.state;
    if (engagement && usersToAddToTeam.length > 0) {
      try {
        await ApiService.addRyanUsersToEngagement(engagement.engagementGuid, {
          assignmentStates: usersToAddToTeam.map(user => ({
            employeeGuid: user.userGuid,
            isGhosted: user.isGhosted
          }))
        });

        pushToast({
          type: 'success',
          title: getTextToDisplay('Success'),
          content: getTextToDisplay('manageTeam.usersAdded', {
            count: usersToAddToTeam.length,
            projectName: engagement.engagementDisplayNameShort
          })
        });

        this.setState({
          usersToAddToTeam: []
        });

        // Redirect the user back to the project team page
        history.push(`/app/project/${engagement.engagementGuid}/team`);
      } catch {
        pushServerErrorToast();
      }
    }
  };

  onUserSearchChange = async (
    user: (IUserSummary & { canAssignToProject?: boolean }) | null
  ) => {
    const { currentUsers, usersToAddToTeam } = this.state;
    const { t: getTextToDisplay } = this.props;

    // check if a user is already on the team
    if (user) {
      if (currentUsers.filter(u => u.userGuid === user.userGuid).length > 0) {
        this.setState({
          userIsValid: false,
          invalidUserText: getTextToDisplay('manageTeam.userOnTeam')
        });
      }
      // check if a user is already going to be added to the team
      else if (
        usersToAddToTeam &&
        usersToAddToTeam.filter(u => u.userGuid === user.userGuid).length > 0
      ) {
        this.setState({
          userIsValid: false,
          invalidUserText: getTextToDisplay('manageTeam.userOnTeam')
        });
      } else if (
        user.hasOwnProperty('canAssignToProject') &&
        !user.canAssignToProject
      ) {
        this.setState({
          userIsValid: false,
          invalidUserText: getTextToDisplay(
            'manageTeam.notEligibleToBeAddedToProject'
          )
        });
      } else {
        this.setState({
          usersToAddToTeam: [
            ...(usersToAddToTeam || []),
            { ...user, isGhosted: true }
          ],
          userIsValid: true,
          invalidUserText: ''
        });
      }
    }

    return '';
  };

  onChangeGhosting = (changedUser: IUserSummary, checked: boolean) => {
    const users = this.state.usersToAddToTeam;
    users[
      users.findIndex(user => user.userGuid === changedUser.userGuid)
    ].isGhosted = !checked; // Column name is 'Show To Client', so results should be flipped
    this.setState({
      usersToAddToTeam: [...users]
    });
  };

  trimDataToEditTeam: ITrimDataToEditTeam = data =>
    data.map(
      ({
        firstName,
        fullName,
        lastName,
        memberGuid,
        title,
        userAvatarDocumentGuid,
        userGuid
      }: any) => ({
        firstName,
        fullName,
        lastName,
        memberGuid,
        title,
        userAvatarDocumentGuid,
        userGuid
      })
    );

  renderAddUserTable() {
    const { usersToAddToTeam } = this.state;
    const { t: getTextToDisplay } = this.props;
    this.context.setIsUnsavedChanges(usersToAddToTeam.length > 0);

    if (usersToAddToTeam.length > 0) {
      return (
        <div>
          <Table<IEngagementUserSummary>
            columns={this.columns}
            data={usersToAddToTeam}
            loading={false}
            rowId="memberGuid"
          />
          <div className="add-team-table-buttons">
            <Button
              onClick={this.handleRemoveAllUsersFromList}
              size={EButtonSizes.LARGE}
              text={getTextToDisplay('Cancel')}
              variant={EButtonVariant.SECONDARY}
            />
            <Button
              onClick={this.handleSaveTeam}
              size={EButtonSizes.LARGE}
              text={getTextToDisplay('Add')}
              variant={EButtonVariant.PRIMARY}
            />
          </div>
        </div>
      );
    }
  }

  renderUser(user: TEditTeamUser) {
    return (
      <Tooltip
        content={
          <div className="add-ryan-team-member-tooltip">
            <div className="add-ryan-team-member-tooltip__name">
              {user.fullName}
            </div>
            <div className="add-ryan-team-member-tooltip__info">
              {user.roleName}
            </div>
          </div>
        }
        key={user.userGuid}
        placement="top"
        renderTarget={({ open, ...props }) => (
          <span
            aria-expanded={open}
            aria-haspopup="true"
            className="project-edit-team__team-user-avatar"
            {...props}
          >
            <Avatar
              firstName={user.firstName}
              lastName={user.lastName}
              profileImageSrc={getAvatarUrl({
                avatarUrl: user.avatarUrl,
                userAvatarDocumentGuid: user.userAvatarDocumentGuid
              })}
            />
          </span>
        )}
      />
    );
  }

  render() {
    const { t: getTextToDisplay, activeView } = this.props;
    const engagement: IEngagement = this.state.engagement!;
    const {
      userToAdd,
      currentUsers,
      pendingUsers,
      userIsValid,
      invalidUserText
    } = this.state;

    return engagement === null ? (
      `${getTextToDisplay('Loading')}...`
    ) : (
      <div className="project-edit-team">
        <Breadcrumb
          label={getTextToDisplay('projects.link')}
          to="/app/projects"
        />
        <Breadcrumb
          label={engagement.engagementDisplayNameShort}
          to={`/app/project/${engagement.engagementGuid}`}
        />
        <Breadcrumb
          label="Add Ryan Team Members"
          to={`/app/project/${engagement.engagementGuid}/team/edit`}
        />
        <div className="project-edit-team__header">
          <h4 className="ry-h4">{activeView.name}</h4>
          <h1 className="ry-h1">{engagement.engagementDisplayNameLong}</h1>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-5">
            <div className="project-edit-team__add-ryan-team-member">
              <h2 className="ry-h2">
                {getTextToDisplay('manageTeam.AddRyanUserButton')}
              </h2>
              <p>{getTextToDisplay('manageTeam.updateWorkdayNotice')}</p>
              <div className="project-edit-team__user-search">
                <UserAutocomplete
                  clearErrorCallback={this.clearError}
                  engagementGuid={engagement.engagementGuid}
                  feedback={invalidUserText}
                  invalid={!userIsValid}
                  isQueryEngagementGuid={true}
                  label={getTextToDisplay('team.userSearch')}
                  onChange={this.onUserSearchChange}
                  userType={UserType.Ryan}
                  value={userToAdd}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-8 col-md-7 project-edit-team__team">
            <h2 className="ry-h2">{getTextToDisplay('Team')}</h2>
            <div>{this.renderAddUserTable()}</div>
            <div className="current-team">
              <div className="row current-team-header">
                {getTextToDisplay('manageTeam.currentTeam', {
                  count: currentUsers.length
                })}
              </div>
              <div className="row current-team-display">
                {currentUsers.map(user =>
                  this.renderUser(user as TEditTeamUser)
                )}
              </div>
              {pendingUsers.length > 0 && (
                <>
                  <div className="row current-team-header">
                    {getTextToDisplay('manageTeam.pendingTeam', {
                      count: pendingUsers.length
                    })}
                  </div>
                  <div className="row current-team-display">
                    {pendingUsers.map(user =>
                      this.renderUser(user as TEditTeamUser)
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withUser(withTranslation()(EditTeam));
