import {
  IDataRequestDescriptionDetailsProps,
  IRenderSelections
} from './DataRequestOverviewDetailsByType.interfaces';

import classNames from 'classnames';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, EButtonVariant } from '@ryan/components';

import { DataRequestTypeGuidEnum } from '../../../../../../../utils/enums/DataRequestsEnums';
import RecordsModal from '../../../../../../RecordsModal/RecordsModal';

import './DataRequestOverviewDetailsByType.scss';

const DataRequestOverviewDetailsByType = ({
  dataRequestDetailsByType
}: IDataRequestDescriptionDetailsProps) => {
  const ROOT_TO_TEXT =
    'table.expandedRow.worklist.dataRequestExpandedRow.overview.detailsByType';

  const { AUDIT_WORKPAPERS, DOCUMENT_IMAGES, ERP_DATA, TAX_RETURNS, OTHER } =
    DataRequestTypeGuidEnum;
  const {
    dateRanges,
    documentTypes,
    engagementDisplayNameLong,
    erpSystemName,
    jurisdictions,
    taxEngineName,
    title,
    typeGuid
  } = dataRequestDetailsByType;

  const { t: getTextToDisplay } = useTranslation();

  const [recordsModalData, setRecordsModalData] = useState<{
    data: string[];
    modalTitle: string;
  } | null>(null);

  const isShowDateRanges = dateRanges.length > 0;
  const isShowDocumentImages = typeGuid === DOCUMENT_IMAGES;
  const isShowErpData = typeGuid === ERP_DATA;
  const isShowJurisdictions =
    [AUDIT_WORKPAPERS, TAX_RETURNS].includes(
      typeGuid as DataRequestTypeGuidEnum
    ) ||
    (typeGuid === OTHER && jurisdictions.length > 0);

  const renderSelections: IRenderSelections = ({
    modalTitle,
    selections,
    setRecordsModalDataCallback,
    viewSelections
  }) => {
    return selections.length < 3 ? (
      selections.map(selection => <span key={selection}>{selection}</span>)
    ) : (
      <Button
        onClick={() =>
          setRecordsModalDataCallback({ data: selections, modalTitle })
        }
        variant={EButtonVariant.LINK}
      >
        {viewSelections}
      </Button>
    );
  };

  return (
    <div
      className={classNames('data-request-overview-details-by-type', {
        'data-request-overview-details-by-type--not-empty':
          isShowDateRanges ||
          isShowDocumentImages ||
          isShowErpData ||
          isShowJurisdictions
      })}
    >
      {isShowDocumentImages && (
        <div className="data-request-overview-details-by-type__document-images">
          <h4>{getTextToDisplay(`${ROOT_TO_TEXT}.documentTypes`)}</h4>
          {renderSelections({
            modalTitle: 'documentTypes',
            selections: documentTypes,
            setRecordsModalDataCallback: setRecordsModalData,
            get viewSelections() {
              return getTextToDisplay(`${ROOT_TO_TEXT}.viewSelections`, {
                count: this.selections.length
              });
            }
          })}
        </div>
      )}

      {isShowErpData && (
        <div className="data-request-overview-details-by-type__epr-data">
          <div>
            <h4>{getTextToDisplay(`${ROOT_TO_TEXT}.erpSystem`)}</h4>
            <span>{erpSystemName}</span>
          </div>
          <div>
            <h4>{getTextToDisplay(`${ROOT_TO_TEXT}.taxEngine`)}</h4>
            <span>{taxEngineName}</span>
          </div>
        </div>
      )}

      {isShowJurisdictions && (
        <div className="data-request-overview-details-by-type__jurisdictions">
          <h4>{getTextToDisplay(`${ROOT_TO_TEXT}.jurisdictions`)}</h4>
          {renderSelections({
            modalTitle: 'jurisdictions',
            selections: jurisdictions,
            setRecordsModalDataCallback: setRecordsModalData,
            get viewSelections() {
              return getTextToDisplay(`${ROOT_TO_TEXT}.viewSelections`, {
                count: this.selections.length
              });
            }
          })}
        </div>
      )}

      {isShowDateRanges && (
        <div className="data-request-overview-details-by-type__date-ranges">
          <h4>{getTextToDisplay(`${ROOT_TO_TEXT}.dateRanges`)}</h4>
          {renderSelections({
            modalTitle: 'dateRanges',
            selections: dateRanges,
            setRecordsModalDataCallback: setRecordsModalData,
            get viewSelections() {
              return getTextToDisplay(`${ROOT_TO_TEXT}.viewSelections`, {
                count: this.selections.length
              });
            }
          })}
        </div>
      )}

      {recordsModalData && (
        <RecordsModal
          data={recordsModalData.data}
          dataRequestTitle={title}
          engagementDisplayName={engagementDisplayNameLong}
          modalTitle={getTextToDisplay(
            `dataRequest.recordsModal.${recordsModalData.modalTitle}`
          )}
          onClose={() => {
            setRecordsModalData(null);
          }}
        />
      )}
    </div>
  );
};

export default DataRequestOverviewDetailsByType;
