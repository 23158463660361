import { TDataRequestActionsProps } from './DataRequestActions.interfaces';
import {
  getEditActionsForDataRequests,
  getNonEditActionsForDataRequests
} from './DataRequestActions.utils';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { EButtonSizes, SplitButton } from '@ryan/components';

import { useAppReadOnly, useUser } from '../../../../../../hooks';
import { Permission } from '../../../../../../interfaces';
import { StateActionEnums } from '../../../../../../routes/Worklist/WorklistOpen/WorklistOpen.utils';
import { IconEnums } from '../../../../../../utils/enums/IconEnums';

const DataRequestActions = ({
  codeAssistance,
  isEngagementReadOnly,
  isMarkedAsDataDelivered,
  onMarkDataDelivered,
  onSetWorklistItemToState,
  queueItemGuid,
  statusId,
  typeGuid
}: TDataRequestActionsProps) => {
  const ROOT_TO_TEXT = 'table.columns.worklist.actionsByEntityType.dataRequest';

  const isAppReadOnly = useAppReadOnly();
  const history = useHistory();
  const { t: getTextToDisplay } = useTranslation();
  const { permissionService } = useUser();

  const defaultButtonProps = {
    block: true,
    onClick: () => {
      history.push(`/app/data-request/${queueItemGuid}`);
    },
    size: EButtonSizes.SMALL
  };
  const isDisabled = isAppReadOnly || isEngagementReadOnly;
  const splitButtonOptionsMapper = {
    assignDataUploadOption: {
      disabled: isDisabled,
      icon: IconEnums.USER_CIRCLE,
      label: getTextToDisplay(`${ROOT_TO_TEXT}.assignDataUpload`),
      onClick: () => {
        onSetWorklistItemToState(StateActionEnums.TO_ASSIGN_DATA_UPLOAD);
      }
    },
    completeOption: {
      disabled: isDisabled,
      icon: IconEnums.APPROVE,
      label: getTextToDisplay(`${ROOT_TO_TEXT}.complete`),
      onClick: () => {
        onSetWorklistItemToState(StateActionEnums.TO_COMPLETE);
      }
    },
    deleteOption: {
      disabled: isDisabled,
      icon: IconEnums.TRASH,
      label: getTextToDisplay(`${ROOT_TO_TEXT}.delete`),
      negative: true,
      onClick: () => {
        onSetWorklistItemToState(StateActionEnums.TO_DELETE);
      }
    },
    editOption: {
      disabled: isDisabled,
      icon: IconEnums.PENCIL,
      label: getTextToDisplay(`${ROOT_TO_TEXT}.edit`),
      onClick: () => {
        onSetWorklistItemToState(StateActionEnums.TO_EDIT);
      }
    },
    markDataDeliveredOption: {
      disabled: isDisabled,
      icon: IconEnums.CLIPBOARD_CHECK,
      label: getTextToDisplay(`${ROOT_TO_TEXT}.markDataDelivered`),
      onClick: onMarkDataDelivered
    }
  };

  let DataRequestActionsElement = null;
  const hasDataRequestContributePermission = permissionService.hasPermission(
    Permission.DataRequestsContribute
  );

  if (permissionService.hasPermission(Permission.DataRequestsEdit)) {
    const { actionButtonProps } = getEditActionsForDataRequests({
      defaultButtonProps,
      details: { isMarkedAsDataDelivered, statusId },
      hasDataRequestContributePermission,
      optionsMapper: splitButtonOptionsMapper
    });

    DataRequestActionsElement = (
      <SplitButton {...actionButtonProps}>
        {getTextToDisplay(`${ROOT_TO_TEXT}.${isDisabled ? 'view' : 'review'}`)}
      </SplitButton>
    );
  } else {
    const { actionButtonProps, actionButtonText, ActionButtonToRender } =
      getNonEditActionsForDataRequests({
        defaultButtonProps,
        details: {
          codeAssistance,
          isMarkedAsDataDelivered,
          statusId,
          typeGuid
        },
        hasDataRequestContributePermission,
        isActiveUserTypeRyan: permissionService.isRyan(),
        isDisabled,
        optionsMapper: {
          assignDataUploadOption:
            splitButtonOptionsMapper.assignDataUploadOption,
          markDataDeliveredOption:
            splitButtonOptionsMapper.markDataDeliveredOption
        }
      });

    DataRequestActionsElement = (
      <ActionButtonToRender {...actionButtonProps}>
        {getTextToDisplay(`${ROOT_TO_TEXT}.${actionButtonText}`)}
      </ActionButtonToRender>
    );
  }

  return DataRequestActionsElement;
};

export default DataRequestActions;
