import * as yup from 'yup';

import { startOfDay } from 'date-fns';
import {
  FormikErrors,
  FormikProps,
  validateYupSchema,
  withFormik,
  yupToFormErrors
} from 'formik';
import { TFunction } from 'i18next';
import React, { useEffect, useRef } from 'react';
import { withTranslation } from 'react-i18next';

import {
  Button,
  ButtonGroup,
  EButtonVariant,
  InfoWell
} from '@ryan/components';

import { withUser } from '../../../../contexts/UserContext';
import MultiSelectDateRange from '../../../MultiSelectDateRange/MultiSelectDateRange';
import {
  IDataRequestFormProps,
  IDataRequestFormValues,
  IDataRequestInnerFormProps
} from './utils';

const SecondStepForm: React.FC<
  IDataRequestInnerFormProps & FormikProps<IDataRequestFormValues>
> = props => {
  const formRef = useRef<HTMLFormElement>(null);

  const {
    dateRanges,
    engagement,
    handleSubmit,
    isSubmitting,
    onNextStepClick,
    setDateRanges,
    status,
    t: getTextToDisplay,
    title,
    validateForm,
    values
  } = props;

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleSubmit(e);
  };

  const handleNextClick = async () => {
    const errors = await validateForm();
    if (Object.keys(errors).length === 0) {
      onNextStepClick(4);
    }
  };

  useEffect(() => {
    const allDates = dateRanges.every(
      range => range.startDate === null || range.endDate === null
    );
    if (!allDates) {
      setDateRanges(
        dateRanges === values.daterange ? dateRanges : values.daterange
      );
    } else {
      setDateRanges(values.daterange);
    }
  }, [values.daterange, setDateRanges, dateRanges]);

  return (
    <>
      {status.error}
      <h2 className="step-title">
        {getTextToDisplay('modal.dataRequestModal.steps.step-3.title')}
      </h2>
      <InfoWell
        accountName={engagement?.accountName || ''}
        dataRequestTitle={title}
        projectName={engagement?.engagementDisplayNameLong || ''}
      />
      <form autoComplete="off" onSubmit={handleFormSubmit} ref={formRef}>
        <MultiSelectDateRange
          daterange={values.daterange}
          formik={props}
          isRequired={true}
          name="daterange"
        />
        <ButtonGroup>
          <Button
            loading={status.loading}
            onClick={() => onNextStepClick(2)}
            text={getTextToDisplay('modal.dataRequestModal.back')}
            type="button"
            variant={EButtonVariant.SECONDARY}
          />
          <Button
            disabled={isSubmitting}
            loading={status.loading}
            onClick={handleNextClick}
            text={getTextToDisplay('modal.dataRequestModal.next')}
            type="submit"
            variant={EButtonVariant.PRIMARY}
          />
        </ButtonGroup>
      </form>
    </>
  );
};

const validationSchema = ({ t: getTextToDisplay }: { t: TFunction }) =>
  yup.object({
    daterange: yup
      .array()
      .of(
        yup.object().shape({
          startDate: yup
            .date()
            .nullable()
            .required(
              getTextToDisplay('multiSelectDateRange.startDateRequired')
            )
            .test(
              'is-before-end',
              getTextToDisplay('multiSelectDateRange.startDateAfterEndDate'),
              function (startDate) {
                const { endDate } = this.parent;
                return !endDate || (startDate && startDate <= endDate);
              }
            ),
          endDate: yup
            .date()
            .nullable()
            .required(getTextToDisplay('multiSelectDateRange.endDateRequired'))
            .min(
              yup.ref('startDate'),
              getTextToDisplay('multiSelectDateRange.endDateAfterStartDate')
            )
        })
      )
      .required(getTextToDisplay('multiSelectDateRange.dateRangeRequired'))
  });
export default withTranslation()(
  withFormik<IDataRequestFormProps, IDataRequestFormValues>({
    mapPropsToValues: props => {
      const periodBegin = props.engagement?.periodBegin
        ? new Date(props.engagement.periodBegin)
        : null;
      const periodEnd = props.engagement?.periodEnd
        ? new Date(props.engagement.periodEnd)
        : null;

      return {
        defaultFolder: null,
        engagement: props.engagement,
        daterange:
          props.dateRanges &&
          props.dateRanges.some(
            ({ startDate, endDate }) => startDate !== null || endDate !== null
          )
            ? props.dateRanges
            : [
                {
                  startDate: periodBegin,
                  endDate: periodEnd
                }
              ]
      };
    },

    mapPropsToStatus: () => ({
      loading: null
    }),
    validationSchema: ({ t: getTextToDisplay }: { t: TFunction }) =>
      validationSchema({ t: getTextToDisplay }),
    validateOnChange: false,
    validateOnBlur: false,
    validate: async (values, { t: getTextToDisplay }) => {
      let errors: FormikErrors<IDataRequestFormValues> = {};
      try {
        await validateYupSchema(
          values,
          validationSchema({ t: getTextToDisplay }),
          true
        );
      } catch (err) {
        errors = { ...yupToFormErrors(err) };
      }
      const dateRanges = values.daterange || [];
      const rangeOccurrences: Record<string, number[]> = {};
      dateRanges.forEach((range, index) => {
        if (range.startDate && range.endDate) {
          const rangeKey = `${startOfDay(
            range.startDate as Date
          ).getTime()}-${startOfDay(range.endDate as Date).getTime()}`;

          if (!rangeOccurrences[rangeKey]) {
            rangeOccurrences[rangeKey] = [];
          }

          rangeOccurrences[rangeKey].push(index);
        }
      });

      Object.values(rangeOccurrences).forEach(occurrences => {
        if (occurrences.length > 1) {
          occurrences.forEach(index => {
            if (!errors.daterange) {
              errors.daterange = [];
            }
            const daterangeErrors = errors.daterange as FormikErrors<any>[];

            daterangeErrors[index] =
              typeof daterangeErrors[index] === 'object'
                ? {
                    ...daterangeErrors[index],
                    range: getTextToDisplay(
                      'multiSelectDateRange.duplicateDateRange'
                    )
                  }
                : {
                    range: getTextToDisplay(
                      'multiSelectDateRange.duplicateDateRange'
                    )
                  };
          });
        }
      });
      if (errors.daterange && errors.daterange.length > dateRanges.length) {
        errors.daterange = errors.daterange.slice(0, dateRanges.length);
      }

      return errors;
    },
    handleSubmit: async (values, formik) => {
      formik.props.onNextStepClick(2);
    }
  })(withUser(SecondStepForm))
);
