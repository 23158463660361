import * as yup from 'yup';

import { withFormik } from 'formik';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { withTranslation } from 'react-i18next';

import {
  Button,
  ButtonGroup,
  EButtonVariant,
  InfoWell,
  Multiselect,
  TextInput,
  Textarea
} from '@ryan/components';

import { withUser } from '../../../../../contexts/UserContext';
import { FolderSelection, IDocumentTypes } from '../../../../../interfaces';
import ApiService from '../../../../../services/ApiService';
import { formikFieldProps } from '../../../../../utils/forms';
import { isFolderNameExist } from '../../../../../utils/isFolderNameExist';
import pushServerErrorToast from '../../../../../utils/pushServerErrorToast';
import SelectFolder from '../../../../SelectFolder/SelectFolder';
import {
  IDataRequestFormProps,
  IDataRequestFormValues,
  IDataRequestInnerFormProps,
  TDropdownOption
} from './utils';

const FirstStepFormDocumentImages: React.FC<
  IDataRequestInnerFormProps
> = props => {
  const formRef = useRef<HTMLFormElement>(null);
  const [documentTypesFetching, setDocumentTypesFetching] = useState(false);
  const [otherDocumentTypeGuid, setOtherDocumentTypeGuid] =
    useState<string>('');
  const [submitClicked, setSubmitClicked] = useState(false);

  const {
    dataSpecs,
    description,
    documentType,
    engagement,
    errors,
    folders,
    foldersFetching,
    handleSubmit,
    isSubmitting,
    onNextStepClick,
    otherDocumentType,
    setDataSpecs,
    setDescription,
    setDocumentType,
    setFieldValue,
    setOtherDocumentType,
    setStatus,
    setTitle,
    setTouched,
    setTransferDestination,
    status,
    t: getTextToDisplay,
    title,
    transferDestination,
    values
  } = props;

  const selectedDocumentType = values.selectedDocumentType;
  const isOtherDocumentTypeSelected = selectedDocumentType.includes(
    otherDocumentTypeGuid
  );

  const handleFormSubmit = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    setSubmitClicked(true);

    const transferDestinationError =
      transferDestination === null
        ? null
        : transferDestination?.folderName.trim().length === 0
        ? getTextToDisplay(
            'modal.dataRequestModal.fields.transferDestination.required'
          )
        : null;

    setStatus({
      ...status,
      transferDestinationError
    });

    setTouched({
      dataRequestTitle: true,
      dataSpecs: true,
      descriptionOptional: true,
      selectedDocumentType: true
    });
    handleSubmit();
  };

  const handleDropdownChange = (data: string[]) => {
    setFieldValue('selectedDocumentType', data);
    setDocumentType(data);

    if (!data.includes(otherDocumentTypeGuid)) {
      setFieldValue('otherDocumentType', '');
    }
  };

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selected = e.target.value;
    setFieldValue('dataRequestTitle', selected);
    setTitle(selected);
  };

  const handleDataSpecsChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const selected = e.target.value;
    setFieldValue('dataSpecs', selected);
    setDataSpecs(selected);
  };

  const handleDescriptionChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const selected = e.target.value;
    setFieldValue('descriptionOptional', selected);
    setDescription(selected);
  };

  const handleOtherTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    setOtherDocumentType(value);
    setFieldValue('otherDocumentType', value);
  };

  const dropdownProps = {
    ...formikFieldProps('selectedDocumentType', props),
    disabled: documentTypesFetching,
    label: getTextToDisplay(
      'modal.dataRequestModal.fields.documentTypes.label'
    ),
    options: values.documentTypes as TDropdownOption[],
    onChange: (data: any) => handleDropdownChange(data),
    value: documentType || values.selectedDocumentType
  };

  const moveOtherToLastPosition = useCallback(
    (documentTypesData: IDocumentTypes[]) => {
      const index = documentTypesData.findIndex(type => type.name === 'Other');

      if (index !== -1) {
        const [otherObject] = documentTypesData.splice(index, 1);
        documentTypesData.push(otherObject);
      }

      return documentTypesData;
    },
    []
  );

  const fetchDocumentTypes = useCallback(async () => {
    setDocumentTypesFetching(true);
    try {
      const response = await ApiService.getDocumentTypes();
      let documentTypesData = response.data as unknown as IDocumentTypes[];

      documentTypesData = moveOtherToLastPosition(documentTypesData);

      const documentTypes = documentTypesData.map((type: IDocumentTypes) => ({
        value: type.documentTypeGuid,
        label: type.name
      }));

      const otherType = documentTypes.find(type => type.label === 'Other');
      setOtherDocumentTypeGuid(otherType?.value ?? '');
      setFieldValue('otherDocumentTypeGuid', otherType?.value ?? '');

      setFieldValue('documentTypes', documentTypes);
    } catch (error) {
      setFieldValue('documentTypes', []);
      pushServerErrorToast();
    }
    setDocumentTypesFetching(false);
  }, [moveOtherToLastPosition, setFieldValue]);

  useEffect(() => {
    if (title) {
      setFieldValue('dataRequestTitle', title);
    }
    if (dataSpecs) {
      setFieldValue('dataSpecs', dataSpecs);
    }
    if (description) {
      setFieldValue('descriptionOptional', description);
    }
    if (otherDocumentType) {
      setFieldValue('otherDocumentType', otherDocumentType);
    }
    if (transferDestination) {
      setFieldValue('defaultFolder', transferDestination);
    }
    if (documentType) {
      setFieldValue('selectedDocumentType', documentType);
    }
  }, [
    dataSpecs,
    description,
    documentType,
    engagement,
    otherDocumentType,
    setFieldValue,
    title,
    transferDestination
  ]);

  useEffect(() => {
    fetchDocumentTypes();
  }, [engagement, fetchDocumentTypes]);

  const isValid = !isSubmitting && !documentTypesFetching && !foldersFetching;

  return (
    <>
      {status.error}
      <h2 className="step-title">
        {getTextToDisplay('modal.dataRequestModal.steps.step-2.title')}
      </h2>
      <InfoWell
        accountName={engagement?.accountName || ''}
        dataRequestTitle=""
        projectName={engagement?.engagementDisplayNameLong || ''}
      />
      <form autoComplete="off" ref={formRef}>
        <TextInput
          {...formikFieldProps('dataRequestTitle', props)}
          label={getTextToDisplay(
            'modal.dataRequestModal.fields.requestTitle.label'
          )}
          onChange={e => handleTitleChange(e)}
        />
        <Multiselect
          {...dropdownProps}
          feedback={
            !values.selectedDocumentType.length && submitClicked
              ? getTextToDisplay(
                  'modal.dataRequestModal.fields.documentTypes.required'
                )
              : ''
          }
          noOptionText={getTextToDisplay('dataRequest.noOptionsLabel')}
          notFoundLabel={getTextToDisplay('dataRequest.notFoundLabel')}
          placeholder={''}
          searchBoxPlaceholder={getTextToDisplay(
            'modal.dataRequestModal.search'
          )}
          selectAllLabel={getTextToDisplay(
            'modal.dataRequestModal.selectAllLabel'
          )}
          selectionLabel={getTextToDisplay('dataRequest.selectionLabel')}
          showAdditionalButtons={false}
        />
        {isOtherDocumentTypeSelected && (
          <TextInput
            {...formikFieldProps('otherDocumentType', props)}
            label={getTextToDisplay(
              'modal.dataRequestModal.fields.otherDocumentType.label'
            )}
            onChange={e => handleOtherTypeChange(e)}
          />
        )}
        <Textarea
          {...formikFieldProps('dataSpecs', props)}
          helperText={getTextToDisplay(
            'modal.dataRequestModal.fields.dataSpecs.helperText'
          )}
          label={getTextToDisplay(
            'modal.dataRequestModal.fields.dataSpecs.label'
          )}
          maxLength={600}
          onChange={e => handleDataSpecsChange(e)}
          value={dataSpecs}
        />
        <Textarea
          {...formikFieldProps('descriptionOptional', props)}
          label={getTextToDisplay(
            'modal.dataRequestModal.fields.descriptionOptional.label'
          )}
          maxLength={600}
          onChange={e => handleDescriptionChange(e)}
          value={description}
        />
        <SelectFolder
          disabled={folders === null}
          feedback={status.transferDestinationError || errors.defaultFolder}
          folders={folders || []}
          invalid={!!status.transferDestinationError || !!errors.defaultFolder}
          label={getTextToDisplay(
            'dataRequest.modal.fields.defaultFolder.label'
          )}
          moveFileCount={2}
          onChange={(folder: FolderSelection) => {
            if (status.transferDestinationError) {
              setStatus({
                ...status,
                transferDestinationError: null
              });
            }
            if (folder?.folderGuid) {
              setFieldValue('defaultFolder', folder);
              setTransferDestination(folder);
            } else if (
              folder?.folderName &&
              folder.folderName.trim().length > 0
            ) {
              setFieldValue('defaultFolder', {
                ...folder,
                folderGuid: null,
                folderName: folder.folderName
              });
              setTransferDestination({
                ...folder,
                folderGuid: null,
                folderName: folder.folderName
              });
            } else {
              const transferDestinationError =
                folder === null
                  ? null
                  : folder?.folderName.trim().length === 0
                  ? getTextToDisplay(
                      'modal.dataRequestModal.fields.transferDestination.required'
                    )
                  : null;

              setStatus({
                ...status,
                transferDestinationError
              });

              setFieldValue('defaultFolder', folder);
              setTransferDestination(folder);
            }
          }}
          rootName={engagement ? engagement.engagementDisplayNameShort : ''}
          value={values.defaultFolder}
        />
        <ButtonGroup>
          <Button
            loading={status.loading}
            onClick={() => onNextStepClick(1)}
            text={getTextToDisplay('modal.dataRequestModal.back')}
            type="submit"
            variant={EButtonVariant.SECONDARY}
          />
          <Button
            disabled={!isValid}
            loading={status.loading}
            onClick={e => handleFormSubmit(e)}
            text={getTextToDisplay('modal.dataRequestModal.next')}
            type="submit"
            variant={EButtonVariant.PRIMARY}
          />
        </ButtonGroup>
      </form>
    </>
  );
};

const defaultValues: IDataRequestFormValues = {
  dataRequestTitle: '',
  dataSpecs: '',
  defaultFolder: null,
  descriptionOptional: '',
  documentTypes: [],
  engagement: null,
  otherDocumentType: '',
  selectedDocumentType: [],
  otherDocumentTypeGuid: ''
};

export default withTranslation()(
  withFormik<IDataRequestFormProps, IDataRequestFormValues>({
    mapPropsToValues: ({ documentType, otherDocumentType }) => ({
      ...defaultValues,
      otherDocumentType: otherDocumentType
        ? otherDocumentType
        : defaultValues.otherDocumentType,
      selectedDocumentType: documentType.length
        ? documentType
        : defaultValues.selectedDocumentType
    }),
    validateOnMount: false,

    mapPropsToStatus: () => ({
      loading: null
    }),

    validationSchema: ({
      folders,
      t: getTextToDisplay
    }: IDataRequestFormProps & IDataRequestFormValues) =>
      yup.object({
        dataRequestTitle: yup
          .string()
          .required(
            getTextToDisplay(
              'modal.dataRequestModal.fields.requestTitle.required'
            )
          )
          .max(
            50,
            getTextToDisplay('modal.dataRequestModal.fields.requestTitle.max')
          ),
        dataSpecs: yup
          .string()
          .required(
            getTextToDisplay('modal.dataRequestModal.fields.dataSpecs.required')
          )
          .max(
            600,
            getTextToDisplay('modal.dataRequestModal.fields.dataSpecs.max')
          ),
        documentTypes: yup
          .string()
          .required(
            getTextToDisplay(
              'modal.dataRequestModal.fields.documentTypes.required'
            )
          ),
        descriptionOptional: yup
          .string()
          .max(
            600,
            getTextToDisplay(
              'modal.dataRequestModal.fields.descriptionOptional.max'
            )
          ),
        defaultFolder: yup
          .object()
          .nullable()
          .test(
            'valid-folder',
            getTextToDisplay(
              'modal.dataRequestModal.fields.transferDestination.required'
            ),
            function (value) {
              const isValidFolderName =
                value?.folderName && value.folderName.trim().length > 0;
              return value?.folderGuid || isValidFolderName || value === null;
            }
          )
          .test('folder-exists-or-archived', '', function (value) {
            const folderName = value?.folderName?.trim();

            if (folderName && !value?.folderGuid) {
              const result = isFolderNameExist(
                folderName,
                folders,
                value?.folderGuid,
                value?.parentFolderGuid
              );
              if (result.hasExistingName) {
                if (result.isArchived) {
                  return this.createError({
                    path: `${this.path}`,
                    message: getTextToDisplay(
                      'modal.dataRequestModal.fields.transferDestination.archived'
                    )
                  });
                } else {
                  return this.createError({
                    path: `${this.path}`,
                    message: getTextToDisplay(
                      'modal.dataRequestModal.fields.transferDestination.exists'
                    )
                  });
                }
              }
            }
            return true;
          }),
        selectedDocumentType: yup
          .array()
          .min(
            1,
            getTextToDisplay(
              'modal.dataRequestModal.fields.documentTypes.required'
            )
          ),
        otherDocumentType: yup.string().when('selectedDocumentType', {
          is: (selectedDocumentType: string[]) =>
            selectedDocumentType.length > 0,
          then: yup
            .string()
            .test(
              'validate-other-document-type',
              getTextToDisplay(
                'modal.dataRequestModal.fields.otherDocumentType.required'
              ),
              function (value) {
                const { selectedDocumentType, otherDocumentTypeGuid } =
                  this.parent;

                if (selectedDocumentType.includes(otherDocumentTypeGuid)) {
                  return !!value;
                }

                return true;
              }
            )
            .max(
              50,
              getTextToDisplay(
                'modal.dataRequestModal.fields.otherDocumentType.maxLength',
                { count: 50 }
              )
            )
        })
      }),

    handleSubmit: async (values, formik) => {
      formik.props.onNextStepClick(3);
    }
  })(withUser(FirstStepFormDocumentImages))
);
