import AutocompleteAjax, {
  IAutocompleteAjaxProps
} from 'components/AutocompleteAjax';
import filterAccounts from 'components/Switcher/filterAccounts';
import { IAccount } from 'interfaces';
import flattenAccountTree from 'utils/flattenAccountTree';
import useAccounts from 'utils/useAccounts';

import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { autocompleteHighlight } from '@ryan/components';

export interface IAccountAutocompleteProps
  extends Omit<
    IAutocompleteAjaxProps<IAccount>,
    'getOptionValue' | 'onFetchOptions' | 'renderOption'
  > {
  /**
   * Display accounts granted to user via Exectuve Access permissions instead
   * of user's default account list.
   */
  displayExecutiveAccounts?: boolean;
  excludeProspects?: boolean;
  showRelevantAccounts?: boolean;
  availableAccounts?: IAccount[];
}

/**
 * Returns account dropdown option value.
 */
const getOptionValue = (account: IAccount) => account.name;

/**
 * Renders account dropdown option.
 */
const renderOption = (account: IAccount, { query }: { query: string }) =>
  autocompleteHighlight(account.name, query);

const AccountAutocomplete: React.FC<IAccountAutocompleteProps> = ({
  displayExecutiveAccounts = false,
  excludeProspects,
  showRelevantAccounts,
  availableAccounts,
  disabled = false,
  ...rest
}) => {
  const { t } = useTranslation();
  const { loading, accounts } = useAccounts(displayExecutiveAccounts);

  /**
   * Fetches list of accounts to display in the dropdown. Flattens the user's
   * account tree into a list and sorts alphabetically.
   */
  const onFetchOptions = useCallback(
    async (query: string) => {
      if (showRelevantAccounts) {
        if (availableAccounts === undefined) {
          return [];
        }
        return availableAccounts.sort((a, b) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        });
      }

      let options = flattenAccountTree(filterAccounts(query, accounts));

      if (excludeProspects) {
        options = options.filter(account => !account.isProspect);
      }

      return options.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });
    },
    [accounts, excludeProspects, showRelevantAccounts, availableAccounts]
  );

  return (
    <AutocompleteAjax<IAccount>
      disabled={disabled || loading}
      getOptionValue={getOptionValue}
      label={t('Company')}
      loading={loading}
      onFetchOptions={onFetchOptions}
      renderOption={renderOption}
      {...rest}
    />
  );
};

export default AccountAutocomplete;
