import { getUserManager } from 'services/UserManager';
import UserService from 'services/UserService';
import history from 'services/history';

import axios, { AxiosInterceptorManager, AxiosRequestConfig } from 'axios';
import ENV from 'env';

import * as ApiAccessControl from './ApiAccessControl';
import * as ApiActivity from './ApiActivity';
import * as ApiCodeNotes from './ApiCodeNotes';
import * as ApiContracts from './ApiContracts';
import * as ApiDataRequests from './ApiDataRequests';
import * as ApiEngagements from './ApiEngagements';
import * as ApiFeatureToggles from './ApiFeatureToggles';
import * as ApiFileUpload from './ApiFileUpload';
import * as ApiFiles from './ApiFiles';
import * as ApiHelp from './ApiHelp';
import * as ApiInvoices from './ApiInvoices';
import * as ApiLearnings from './ApiLearnings';
import * as ApiLookup from './ApiLookup';
import * as ApiMilestones from './ApiMilestones';
import * as ApiNotifications from './ApiNotifications';
import * as ApiProjects from './ApiProjects';
import * as ApiReleaseNotes from './ApiReleaseNotes';
import * as ApiReporting from './ApiReporting';
import * as ApiSavings from './ApiSavings';
import * as ApiSideNavigationItems from './ApiSideNavigationItems';
import * as ApiSystemMessages from './ApiSystemMessages';
import * as ApiTasks from './ApiTasks';
import * as ApiTerms from './ApiTerms';
import * as ApiUsers from './ApiUsers';
import * as ApiViews from './ApiViews';
import * as ApiWatchers from './ApiWatchers';
import * as ApiWorklist from './ApiWorklist';
import * as ApiWorkspaces from './ApiWorkspaces';
import { EXECUTIVE_VIEW_STORAGE_KEY } from './storageKeys';

// Configure axios instance.
const axiosInstance = axios.create({
  baseURL: ENV.API_ROOT,
  withCredentials: true, // with cookies
  headers: {
    Accept: 'application/json',

    // Preventing Caching in IE11
    // https://support.microsoft.com/en-us/help/234067/how-to-prevent-caching-in-internet-explorer
    'Cache-Control': 'no-cache, no-store, must-revalidate',
    Pragma: 'no-cache',
    Expires: '0'
  }
  /**
   * This transformResponse function deserializes all ISO dates to Date objects.
   * Currently adding this to specific endpoints that can support it.
   * Once all interfaces have been updated (from string to Date), we can uncomment this.
   */
  // transformResponse
});

/**
 * Preventing Caching in IE11.
 *
 * @todo explore removal once we no longer support IE11
 * @todo make synchronous once axios support is released
 * @todo add `runWhen` function to only run in IE and only on portal-api calls
 * @see https://github.com/axios/axios/issues/3736
 */
axiosInstance.interceptors.request.use(config => {
  if (config.baseURL === ENV.API_ROOT) {
    config.params = config.params || {};
    config.params.cb = Date.now();
  }

  return config;
});

// parse key of storage item of user set by OIDC library
const userManager = getUserManager();
const OIDC_STORAGE_KEY = `oidc.user:${userManager.settings.authority}:${userManager.settings.client_id}`;

/**
 * Generates the HTTP Authorization request header for Portal UI API requests.
 */
export const getAuthorizationRequestHeader = (): string | undefined => {
  let accessToken = '';
  let tokenType = '';

  try {
    const sessionValue = window.sessionStorage.getItem(OIDC_STORAGE_KEY);

    if (sessionValue) {
      const oidcUser = JSON.parse(sessionValue);
      accessToken = oidcUser.access_token;
      tokenType = oidcUser.token_type;
    }
  } catch {}

  if (accessToken && tokenType) {
    return `${tokenType} ${accessToken}`;
  }
};

/**
 * Send user access token and executive view status with requests. Exporting for
 * unit testing only.
 *
 * @ignore
 * @todo make synchronous once axios support is released
 * @todo add `runWhen` function to only run on portal-api calls
 * @see https://github.com/axios/axios/issues/3736
 */
export const applyAuthToRequest: NonNullable<
  Parameters<AxiosInterceptorManager<AxiosRequestConfig>['use']>[0]
> = config => {
  // only send on portal-api requests
  if (config.baseURL === ENV.API_ROOT) {
    // send user access token in request header
    if (!config.headers.Authorization) {
      config.headers.Authorization = getAuthorizationRequestHeader();
    }

    // send executive view status
    if (window.sessionStorage.getItem(EXECUTIVE_VIEW_STORAGE_KEY) === 'true') {
      config.params = config.params || {};
      config.params.isExecutiveView = true;
    }
  }

  return config;
};
axiosInstance.interceptors.request.use(applyAuthToRequest);

// Global Error Handling
axiosInstance.interceptors.response.use(
  response => response,
  error => {
    if (!ApiService.isCancel(error)) {
      switch (error?.response?.status) {
        case 401:
          UserService.logout();
          break;
        case 403:
          history.push('/app/403');
          break;
      }
    }

    throw error;
  }
);

const ApiService = {
  CancelToken: axios.CancelToken,
  isCancel: axios.isCancel,
  server: axiosInstance,
  ...ApiAccessControl,
  ...ApiActivity,
  ...ApiCodeNotes,
  ...ApiContracts,
  ...ApiDataRequests,
  ...ApiEngagements,
  ...ApiFeatureToggles,
  ...ApiFiles,
  ...ApiHelp,
  ...ApiInvoices,
  ...ApiLearnings,
  ...ApiLookup,
  ...ApiMilestones,
  ...ApiNotifications,
  ...ApiReleaseNotes,
  ...ApiProjects,
  ...ApiReporting,
  ...ApiSavings,
  ...ApiSystemMessages,
  ...ApiTasks,
  ...ApiTerms,
  ...ApiUsers,
  ...ApiViews,
  ...ApiWatchers,
  ...ApiWorklist,
  ...ApiWorkspaces,
  ...ApiFileUpload,
  ...ApiSideNavigationItems
};

export default ApiService;
export type { CancelTokenSource } from 'axios';
