import ApiService from 'services/ApiService';
import pushServerErrorToast from 'utils/pushServerErrorToast';

import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState
} from 'react';
import { withTranslation } from 'react-i18next';

import { InfoWell } from '@ryan/components';

import Modal from '../../../components/Modal';
import UploadModalWarning from '../../../components/UploadModalWarning/UploadModalWarning';
import { useEngagement } from '../../../contexts/EngagementContext';
import { withUser } from '../../../contexts/UserContext';
import { IDataRequestV2, IFolderTree, UserType } from '../../../interfaces';
import { useDSSManager } from '../../../utils/DSS';
import EditForm from './EditForm/EditForm';
import { IUpdateDataRequestModalProps } from './utils';

import './UpdateDataRequestModal.scss';

export const UpdateDataRequestModal: FunctionComponent<
  IUpdateDataRequestModalProps
> = ({
  dataRequest,
  isFetchCompleteDataRequest = false,
  onClose: onCloseProp,
  t: getTextToDisplay
}) => {
  const dss = useDSSManager();
  const { refreshUpdateDate } = useEngagement();
  const [folders, setFolders] = useState<IFolderTree[] | null>(null);

  const onClose = useCallback(() => {
    onCloseProp();
    dss.clearUploads();
  }, [dss, onCloseProp]);

  const handleSubmitted = (dataRequest?: IDataRequestV2) => {
    onCloseProp(dataRequest);
    refreshUpdateDate?.(dataRequest?.engagementGuid);
    dss.clearUploads();
  };

  const fetchFolders = async (engagementGuid: string) => {
    setFolders(null);

    try {
      const response = await ApiService.getEngagementFolders(engagementGuid);

      if (response && response.data) {
        const { data: folders } = response;
        const filteredFolders = folders.filter(
          folder => folder.folderVisibleToUserTypes !== UserType.Ryan
        );
        setFolders(filteredFolders);
        return filteredFolders;
      } else {
        setFolders([]);
      }
    } catch (error) {
      pushServerErrorToast();
      setFolders([]);
    }
  };

  useEffect(() => {
    fetchFolders(dataRequest.engagementGuid || '');
  }, [dataRequest]);

  return (
    <UploadModalWarning dss={dss} onClose={onClose}>
      {({ warning, onEscape }) => (
        <Modal
          className="update-data-request-modal"
          onClose={onEscape}
          open
          title={getTextToDisplay('dataRequest.modal.edit.title')}
        >
          {warning}
          <div className="update-data-request-modal__info-well">
            <InfoWell
              accountName={dataRequest.accountName}
              dataRequestTitle=""
              projectName={dataRequest.engagementDisplayNameLong}
            />
          </div>
          <EditForm
            dataRequest={dataRequest}
            dss={dss}
            engagementGuid={dataRequest.engagementGuid}
            folders={folders}
            isFetchCompleteDataRequest={isFetchCompleteDataRequest}
            onCancel={onClose}
            onSubmitted={handleSubmitted}
          />
        </Modal>
      )}
    </UploadModalWarning>
  );
};

export default withTranslation()(withUser(UpdateDataRequestModal));
