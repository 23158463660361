import React, { PureComponent } from 'react';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';

import {
  Button,
  EButtonSizes,
  EButtonVariant,
  ITableColumn,
  pushToast
} from '@ryan/components';

import Empty from '../../../../components/Empty';
import {
  FileDeleteModal,
  FileRenameModal
} from '../../../../components/FileDirectory';
import FileLink from '../../../../components/FileLink';
import Table from '../../../../components/Table';
import {
  WithDownload,
  withDownload
} from '../../../../contexts/DownloadContext';
import EngagementContext from '../../../../contexts/EngagementContext';
import { WithUser, withUser } from '../../../../contexts/UserContext';
import {
  IDirectoryFile,
  ILearningFilesSearch,
  Permission
} from '../../../../interfaces';
import ApiService from '../../../../services/ApiService';
import { formatDate } from '../../../../utils/formatDate';
import getSortParam from '../../../../utils/getSortParm';
import pushServerErrorToast from '../../../../utils/pushServerErrorToast';

import './LearningFiles.scss';

export interface ILearningFilesProps
  extends WithTranslation,
    WithUser,
    WithDownload {
  attachments: IDirectoryFile[];
  engagementGuid: string;
  isEngagementGhosting: boolean;
  isEngagementReadOnly: boolean;
  learningGuid: string;
  onUpdate: () => void;
  onUploadFiles: () => void;
}

interface ILearningFilesState {
  files: IDirectoryFile[];
  loading: boolean;
  searchQuery?: string;
  sorted?: {
    id: string;
    desc: boolean;
  } | null;
  filtered?: { [key: string]: any };
  expanded?: any;
  selected?: string[];

  renameFile: IDirectoryFile | null;
  renameFileLoading: Promise<any> | null;

  deleteFiles: string[] | null;
  deleteFilesLoading: Promise<any> | null;

  filesCanRead: boolean;
  filesCanWrite: boolean;
  filesCanContribute: boolean;
}

export class LearningFiles extends PureComponent<
  ILearningFilesProps,
  ILearningFilesState
> {
  private columns: ITableColumn<IDirectoryFile>[];

  private _isMounted = false;

  static contextType = EngagementContext;
  context!: React.ContextType<typeof EngagementContext>;

  constructor(props: ILearningFilesProps) {
    super(props);

    const { t, attachments, permissionService: ps } = props;

    this.state = {
      files: attachments,
      loading: false,
      searchQuery: '',
      sorted: {
        id: 'createDate',
        desc: true
      },
      selected: [],

      renameFile: null,
      renameFileLoading: null,

      deleteFiles: null,
      deleteFilesLoading: null,

      filesCanRead: ps.hasPermission(Permission.FilesRead),
      filesCanWrite: ps.hasPermission(Permission.FilesWrite),
      filesCanContribute: ps.hasPermission(Permission.FilesContribute)
    };

    this.columns = [
      {
        id: 'displayName',
        label: t('file.name'),
        sortable: true,
        width: '60%',
        render: this.renderName
      },
      {
        id: 'createDate',
        label: t('file.uploadedDate'),
        sortable: true,
        render: this.renderUploadedDate
      },
      {
        id: 'actions',
        label: '',
        align: 'right',
        render: this.renderActions
      }
    ];
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentDidUpdate(prevProps: ILearningFilesProps) {
    const { attachments } = this.props;

    if (prevProps.attachments !== attachments) {
      this.setState({
        files: attachments,
        searchQuery: '',
        sorted: {
          id: 'createDate',
          desc: true
        }
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleDownload = async (item?: IDirectoryFile) => {
    const { engagementGuid, onDownloadFiles } = this.props;
    const { files, selected } = this.state;
    onDownloadFiles(
      item
        ? [item]
        : files.filter(file => selected!.includes(file.documentGuid)),
      engagementGuid
    );
  };

  handleStartRename = () => {
    const { files, selected } = this.state;
    const renameDocumentGuid = selected![0];
    const renameFile = files.find(f => f.documentGuid === renameDocumentGuid)!;

    this.setState({ renameFile });
  };

  handleRename = async (friendlyName: string) => {
    const { t, learningGuid, engagementGuid } = this.props;
    const { renameFile } = this.state;

    const promise = ApiService.updateLearningFile(
      engagementGuid,
      learningGuid,
      renameFile!.documentGuid,
      { friendlyName }
    );

    this.setState({ renameFileLoading: promise });

    try {
      const response = await promise;
      const prevDisplayName = renameFile!.displayName;
      const { displayName } = response.data;

      pushToast({
        type: 'success',
        title: t('file.renameModal.success.title'),
        content: (
          <Trans i18nKey="file.renameModal.success.content">
            <b />
            {{ prevDisplayName }}
            {{ displayName }}
          </Trans>
        )
      });
      this.context.refreshUpdateDate?.(engagementGuid);
      this.fetchFiles();
    } catch (error) {
      pushServerErrorToast();
    }

    this.setState({ renameFile: null, renameFileLoading: null });
  };

  handleRenameCancel = () => {
    this.setState({ renameFile: null });
  };

  handleStartDelete = () => {
    const { selected } = this.state;

    this.setState({ deleteFiles: selected! });
  };

  handleDelete = async () => {
    const { t, learningGuid, engagementGuid } = this.props;
    const { deleteFiles } = this.state;

    const promise = ApiService.deleteLearningFiles(
      engagementGuid,
      learningGuid,
      deleteFiles!
    );

    this.setState({ deleteFilesLoading: promise });

    try {
      await promise;
      pushToast({
        type: 'success',
        title: t('file.deleteModal.success.title', {
          count: deleteFiles!.length
        })
      });
      this.context.refreshUpdateDate?.(engagementGuid);
      this.fetchFiles();
    } catch (error) {
      pushServerErrorToast();
    }

    this.setState({
      deleteFiles: null,
      deleteFilesLoading: null
    });
  };

  handleDeleteCancel = () => {
    this.setState({ deleteFiles: null });
  };

  handleSelectChange = (selected: string[]) => {
    this.setState({ selected });
  };

  handleSort = (sorted: any) => {
    this.fetchFiles({ sorted, selected: [] });
  };

  fetchFiles(updates?: Partial<ILearningFilesState>) {
    const { learningGuid, engagementGuid } = this.props;

    this.setState(
      { ...(updates as ILearningFilesState), loading: true },
      async () => {
        const { searchQuery, sorted } = this.state;

        const params: ILearningFilesSearch = {
          searchTerm: searchQuery,
          sort: getSortParam(sorted)
        };

        try {
          const { data } = await ApiService.getLearningFiles(
            engagementGuid,
            learningGuid,
            params
          );

          this.setState(
            {
              files: data,
              selected: []
            },
            this.props.onUpdate
          );
        } catch {
          pushServerErrorToast();
        } finally {
          if (this._isMounted) {
            this.setState({ loading: false });
          }
        }
      }
    );
  }

  renderSelectionActions = () => {
    const {
      activeView: { isExecutiveView },
      isAppReadOnly,
      isEngagementGhosting,
      isEngagementReadOnly,
      permissionService,
      t: getTextToDisplay
    } = this.props;
    const { files, selected, filesCanRead, filesCanContribute, filesCanWrite } =
      this.state;

    const isReadOnlyByEngagementState =
      isEngagementGhosting || isEngagementReadOnly;
    const isReadOnlyByUserState = isAppReadOnly || isExecutiveView;
    const isSelectionOne = selected!.length === 1;
    const isSelectionEmpty = selected!.length === 0;
    const isSelectionAllReady =
      !isSelectionEmpty &&
      files
        .filter(file => selected!.includes(file.documentGuid))
        .every(file => file.isReady);

    return (
      <>
        <Button
          disabled={
            !isSelectionAllReady ||
            !filesCanRead ||
            isReadOnlyByUserState ||
            isEngagementGhosting
          }
          icon="download"
          onClick={() => this.handleDownload()}
          size={EButtonSizes.SMALL}
          text={getTextToDisplay('Download')}
          variant={EButtonVariant.TEXT}
        />
        {permissionService.hasPermission(Permission.LearningsEdit) && (
          <>
            <Button
              disabled={
                (!filesCanWrite && !filesCanContribute) ||
                isReadOnlyByUserState ||
                isReadOnlyByEngagementState ||
                !isSelectionOne
              }
              icon="pencil"
              onClick={this.handleStartRename}
              size={EButtonSizes.SMALL}
              text={getTextToDisplay('Rename')}
              variant={EButtonVariant.TEXT}
            />
            <Button
              disabled={
                !filesCanWrite ||
                isReadOnlyByUserState ||
                isReadOnlyByEngagementState ||
                isSelectionEmpty
              }
              icon="trash"
              onClick={this.handleStartDelete}
              size={EButtonSizes.SMALL}
              text={getTextToDisplay('Delete')}
              variant={EButtonVariant.TEXT}
            />
          </>
        )}
      </>
    );
  };

  renderName = (file: IDirectoryFile) => {
    const {
      activeView: { isExecutiveView },
      engagementGuid,
      isAppReadOnly,
      isEngagementGhosting
    } = this.props;

    const isReadOnlyByUserState = isAppReadOnly || isExecutiveView;
    return (
      <FileLink
        engagementGuid={engagementGuid}
        file={file}
        fileDescription={file.friendlyName ? file.documentName : undefined}
        icon="file"
        isDownloadingDisabled={isReadOnlyByUserState || isEngagementGhosting}
      />
    );
  };

  renderUploadedDate = (file: IDirectoryFile) =>
    formatDate(file.uploadedDate, true);

  renderActions = (file: IDirectoryFile) => {
    const {
      activeView: { isExecutiveView },
      isAppReadOnly,
      isEngagementGhosting,
      t: getTextToDisplay
    } = this.props;
    const { filesCanRead: hasFilesReadPermission } = this.state;

    const isReadOnlyByUserState = isAppReadOnly || isExecutiveView;
    return (
      <div className="learning-files-table__actions">
        <Button
          aria-label={getTextToDisplay('Download')}
          disabled={
            !hasFilesReadPermission ||
            isReadOnlyByUserState ||
            isEngagementGhosting ||
            !file.isReady
          }
          icon="download"
          onClick={() => this.handleDownload(file)}
          size={EButtonSizes.SMALL}
          variant={EButtonVariant.TEXT}
        />
      </div>
    );
  };

  render() {
    const { onUploadFiles, t } = this.props;
    const {
      loading,
      files,
      sorted,
      selected,
      deleteFiles,
      deleteFilesLoading,
      filesCanContribute
    } = this.state;

    if (files.length === 0 && !loading) {
      return (
        <Empty icon="paperclip">
          <p>{t('learning.attachedFiles', { context: 'none' })}</p>
          <Button
            disabled={!filesCanContribute}
            icon="upload"
            onClick={onUploadFiles}
          >
            {t('Upload Files')}
          </Button>
        </Empty>
      );
    }

    return (
      <>
        <Table<IDirectoryFile, string>
          className="learning-files-table"
          columns={this.columns}
          data={files}
          headerTitle={`${t('Files')} (${files.length})`}
          loading={loading}
          onSelectChange={this.handleSelectChange}
          onSortChange={this.handleSort}
          renderSelectionActions={this.renderSelectionActions}
          rowId="documentGuid"
          selected={selected}
          selectedTitle={`${t('Files')} (${selected ? selected.length : ''})`}
          sorted={sorted}
        />

        <FileRenameModal
          file={this.state.renameFile}
          loading={this.state.renameFileLoading}
          onCancel={this.handleRenameCancel}
          onSubmit={this.handleRename}
        />

        <FileDeleteModal
          loading={deleteFilesLoading}
          multiple={deleteFiles !== null && deleteFiles.length > 1}
          onCancel={this.handleDeleteCancel}
          onSubmit={this.handleDelete}
          open={deleteFiles !== null}
        />
      </>
    );
  }
}

export default withTranslation()(withUser(withDownload(LearningFiles)));
