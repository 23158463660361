import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import {
  EButtonSizes,
  EMessageTypes,
  SplitButton,
  pushToast
} from '@ryan/components';

import UpdateDataRequestModal from '../../components/DataRequestModal/UpdateDataRequestModal/UpdateDataRequestModal';
import AssignDataUploadModal from '../../components/Modal/DataRequestModal/AssignDataUploadModal/AssignDataUploadModal';
import EngagementContext from '../../contexts/EngagementContext';
import { WithUser, withUser } from '../../contexts/UserContext';
import { IDataRequestV2, Permission, Status } from '../../interfaces';
import ApiService from '../../services/ApiService';
import { SubStatusEnums } from '../../utils/enums/SubStatusEnums';
import pushServerErrorToast from '../../utils/pushServerErrorToast';
import DataRequestCompleteModal from './DataRequestCompleteModal';
import DataRequestDeleteModal from './DataRequestDeleteModal';
import DataRequestReopenModal from './DataRequestReopenModal';
import { IGetOptions, IHandleDeleteModalClose } from './utils';

import './DataRequestActions.scss';

interface IDataRequestActionsProps
  extends RouteComponentProps,
    WithTranslation,
    WithUser {
  dataRequest: IDataRequestV2;
  isOnDetailPage?: boolean;
  onUpdate: () => void;
  size: `${EButtonSizes}`;
}

interface IDataRequestActionsState {
  isAssignDataUploadModalOpen: boolean;
  isCompleteModalOpen: boolean;
  isDeleteModalOpen: boolean;
  isEditModalOpen: boolean;
  isReopenModalOpen: boolean;
}

// Assumes Permission.DataRequestsEdit
// Otherwise this component should not be rendered.
export class DataRequestActions extends PureComponent<
  IDataRequestActionsProps,
  IDataRequestActionsState
> {
  static contextType = EngagementContext;
  context!: React.ContextType<typeof EngagementContext>;

  readonly state = {
    isAssignDataUploadModalOpen: false,
    isCompleteModalOpen: false,
    isDeleteModalOpen: false,
    isEditModalOpen: false,
    isReopenModalOpen: false
  };

  handleDeleteModalClose = ({
    history,
    isDeleted,
    isOnDetailPage,
    onUpdate
  }: IHandleDeleteModalClose) => {
    this.setState({ isDeleteModalOpen: false }, () => {
      if (isDeleted) {
        isOnDetailPage
          ? history.push('/app/data-and-files/data-requests')
          : onUpdate();
      }
    });
  };

  handleAssignDataUploadModalClose = () => {
    this.props.onUpdate();
    this.setState({ isAssignDataUploadModalOpen: false }, () => {});
  };

  handleMarkDataDelivered = async (dataRequest: IDataRequestV2) => {
    const { onUpdate, t: getTextToDisplay } = this.props;

    if (!dataRequest) return;

    try {
      const { data } = await ApiService.markDataRequestDataDelivered({
        markAsDataDelivered: true,
        queueItemGuid: dataRequest.queueItemGuid,
        status: dataRequest.statusId,
        subStatus: SubStatusEnums.DataDelivered
      });

      pushToast({
        type: EMessageTypes.SUCCESS,
        content: getTextToDisplay(
          'dataRequest.markDataDeliveredSuccessContent',
          {
            title: data.title
          }
        )
      });
      onUpdate();
    } catch (error) {
      pushServerErrorToast();
    }
  };

  getOptions({
    canContribute,
    dataRequest,
    isAppReadOnly,
    isOnDetailPage,
    t: getTextToDisplay
  }: IGetOptions) {
    const disabled = dataRequest.isEngagementReadOnly || isAppReadOnly;

    const options = [];

    if (dataRequest.statusId !== Status.Complete) {
      options.push({
        disabled,
        icon: 'pencil',
        label: getTextToDisplay('Edit'),
        onClick: () => this.setState({ isEditModalOpen: true })
      });

      // If not already marked Complete
      // and we are on the "detail" page (otherwise, the main SplitButton action
      // will be "Complete", so it doesn't need to be in the dropdown options).
      if (!isOnDetailPage) {
        options.push({
          disabled,
          icon: 'approve',
          label: getTextToDisplay('Complete'),
          onClick: () => this.setState({ isCompleteModalOpen: true })
        });
      }
    }

    // If marked Complete
    if (dataRequest.statusId === Status.Complete) {
      options.push({
        disabled,
        icon: 'file-refresh',
        label: getTextToDisplay('Reopen'),
        onClick: () => this.setState({ isReopenModalOpen: true })
      });
    }

    if (
      dataRequest.statusId === Status.InProgress &&
      !dataRequest.markAsDataDelivered &&
      canContribute
    ) {
      options.push({
        disabled,
        icon: 'clipboard-check',
        label: getTextToDisplay('dataRequest.markDataDeliveredButtonName'),
        onClick: () => this.handleMarkDataDelivered(dataRequest)
      });
    }

    if (
      dataRequest.statusId === Status.InProgress &&
      dataRequest.markAsDataDelivered
    ) {
      options.push({
        disabled,
        icon: 'user-circle',
        label: getTextToDisplay('dataRequest.assignDataUploadButtonName'),
        onClick: () => this.setState({ isAssignDataUploadModalOpen: true })
      });
    }

    options.push({
      disabled,
      icon: 'trash',
      label: getTextToDisplay('Delete'),
      negative: true,
      onClick: () => this.setState({ isDeleteModalOpen: true })
    });

    return options;
  }

  render() {
    const {
      dataRequest,
      isAppReadOnly,
      isOnDetailPage,
      history,
      onUpdate,
      permissionService,
      size,
      t: getTextToDisplay
    } = this.props;
    const isComplete = dataRequest.statusId === Status.Complete;
    const canContribute = permissionService.hasPermission(
      Permission.DataRequestsContribute
    );

    return (
      <>
        {
          <SplitButton
            block
            disabled={
              isOnDetailPage &&
              (isComplete ||
                dataRequest.isEngagementReadOnly ||
                isAppReadOnly ||
                dataRequest.isUserGhosted)
            }
            onClick={() => {
              if (isOnDetailPage) {
                this.setState({ isCompleteModalOpen: true });
              } else {
                history.push(`/app/data-request/${dataRequest.queueItemGuid}`);
              }
            }}
            options={this.getOptions({
              canContribute,
              dataRequest,
              isAppReadOnly,
              isOnDetailPage,
              t: getTextToDisplay
            })}
            size={size}
            text={getTextToDisplay(
              isOnDetailPage ? 'Complete' : isComplete ? 'View' : 'Review'
            )}
          />
        }
        {this.state.isCompleteModalOpen && (
          <DataRequestCompleteModal
            dataRequest={dataRequest}
            onClose={(updatedDataRequest?: IDataRequestV2) => {
              if (updatedDataRequest) {
                onUpdate();
                this.context.refreshUpdateDate?.(
                  this.context.engagement?.engagementGuid
                );
              }

              this.setState({ isCompleteModalOpen: false });
            }}
          />
        )}
        {this.state.isDeleteModalOpen && (
          <DataRequestDeleteModal
            dataRequest={dataRequest}
            onClose={(isDeleted: boolean) =>
              this.handleDeleteModalClose({
                history,
                isDeleted,
                isOnDetailPage,
                onUpdate
              })
            }
          />
        )}
        {this.state.isEditModalOpen && (
          <UpdateDataRequestModal
            dataRequest={dataRequest}
            onClose={(updatedDataRequest?: IDataRequestV2) => {
              if (updatedDataRequest) {
                onUpdate();
              }
              this.setState({ isEditModalOpen: false });
            }}
          />
        )}
        {this.state.isReopenModalOpen && (
          <DataRequestReopenModal
            dataRequest={dataRequest}
            onClose={(updatedDataRequest?: IDataRequestV2) => {
              if (updatedDataRequest) {
                this.props.onUpdate();
                this.context.refreshUpdateDate?.(
                  this.context.engagement?.engagementGuid
                );
              }

              this.setState({ isReopenModalOpen: false });
            }}
          />
        )}
        {this.state.isAssignDataUploadModalOpen && (
          <AssignDataUploadModal
            dataRequest={dataRequest}
            engagement={this.context.engagement}
            onClose={this.handleAssignDataUploadModalClose}
            open={this.state.isAssignDataUploadModalOpen}
          />
        )}
        {this.state.isAssignDataUploadModalOpen && (
          <AssignDataUploadModal
            dataRequest={dataRequest}
            engagement={this.context.engagement}
            onClose={this.handleAssignDataUploadModalClose}
            open={this.state.isAssignDataUploadModalOpen}
          />
        )}
      </>
    );
  }
}

export default withRouter(withUser(withTranslation()(DataRequestActions)));
