import Modal from 'components/Modal';
import { IFolder } from 'interfaces';
import { Formik, FormikHelpers, formikFieldProps, yup } from 'utils/forms';

import React, { FunctionComponent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  ButtonGroup,
  EButtonVariant,
  TextInput
} from '@ryan/components';

import { FolderErrorsEnum } from '../../SelectFolder/SelectFolder.utils';

export interface IFolderRenameModalProps {
  folder: IFolder | null; // modal closed if null
  loading: Promise<any> | null;
  onSubmit: (folderName: string) => any;
  onCancel: () => void;
}

interface IFormValues {
  folderName: string;
}

const FolderRenameModal: FunctionComponent<IFolderRenameModalProps> = ({
  folder,
  loading,
  onSubmit,
  onCancel
}) => {
  const { t: getTextToDisplay } = useTranslation();
  const maxLengthName = 255;
  const schema = useMemo(
    () =>
      yup.object({
        folderName: yup
          .string()
          .max(
            maxLengthName,
            getTextToDisplay('folder.renameModal.folderName.maxLength', {
              length: maxLengthName
            })
          )
          .required(getTextToDisplay('folder.renameModal.folderName.required'))
      }),
    [getTextToDisplay]
  );

  async function handleSubmit(
    { folderName }: IFormValues,
    formik: FormikHelpers<IFormValues>
  ) {
    const folderNameError = await onSubmit(folderName);

    if (folderNameError) {
      const mappedFolderError =
        FolderErrorsEnum[folderNameError as keyof typeof FolderErrorsEnum];
      formik.setFieldError(
        'folderName',
        getTextToDisplay(`selectFolder.${mappedFolderError}`)
      );
    }
  }

  if (folder) {
    return (
      <Modal
        onClose={onCancel}
        open
        title={getTextToDisplay('folder.renameModal.title')}
      >
        <Formik
          initialValues={{ folderName: folder.folderName }}
          onSubmit={handleSubmit}
          validationSchema={schema}
        >
          {formik => (
            <form autoComplete="off" onSubmit={formik.handleSubmit}>
              <TextInput
                {...formikFieldProps('folderName', formik)}
                label={getTextToDisplay('folder.renameModal.folderName.label')}
              />
              <ButtonGroup>
                <Button
                  disabled={formik.errors.folderName !== undefined}
                  loading={loading}
                  text={getTextToDisplay('Save')}
                  type="submit"
                  variant={EButtonVariant.PRIMARY}
                />
                <Button
                  disabled={loading !== null}
                  onClick={onCancel}
                  text={getTextToDisplay('Cancel')}
                  variant={EButtonVariant.SECONDARY}
                />
              </ButtonGroup>
            </form>
          )}
        </Formik>
      </Modal>
    );
  }

  return null;
};

export default FolderRenameModal;
