import { IProjectsOverviewCardProps } from './ProjectsOverviewCard.interfaces';
import {
  AmplitudeActionType,
  amplitudeEventDetail
} from 'utils/amplitudeUtils/amplitudeUtils';

import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import {
  Button,
  Card,
  DonutChart,
  EButtonVariant,
  IDonutChartSegment,
  Icon
} from '@ryan/components';

import { useAmplitude } from '../../../contexts/AmplitudeContext/AmplitudeConsumer';

import './ProjectsOverviewCard.scss';

const ProjectsOverviewCard: React.FC<IProjectsOverviewCardProps> = ({
  values
}) => {
  const ROOT_TO_TEXT = 'dashboard.projectsOverviewCard';

  const { initializeEventToTrack } = useAmplitude();
  const history = useHistory();
  const { t: getTextToDisplay } = useTranslation();

  const donutChartData = useMemo(() => {
    if (!values) {
      return null;
    }

    let formattedValues: IDonutChartSegment[] = values
      .map(
        ({
          count: value,
          parentPracticeAreaBackgroundColor: color,
          parentPracticeAreaName: description,
          parentPracticeAreaTextColor: textColor
        }) => ({
          color: color || '#0e247e',
          description,
          textColor: textColor || '#fff',
          value
        })
      )
      .sort(
        (a, b) =>
          b.value - a.value || a.description.localeCompare(b.description)
      );

    const maxLength = 8;

    if (formattedValues.length > maxLength) {
      formattedValues = [
        ...formattedValues.slice(0, maxLength - 1),
        formattedValues.slice(maxLength - 1).reduce(
          (others, { value }) => ({
            ...others,
            value: others.value + value
          }),
          {
            color: '#000',
            description: getTextToDisplay(`${ROOT_TO_TEXT}.other`),
            inverseLegendColor: false,
            value: 0
          }
        )
      ];
    }

    return formattedValues;
  }, [getTextToDisplay, values]);

  return (
    <Card
      className="projects-overview-card"
      role="region"
      title={getTextToDisplay(`${ROOT_TO_TEXT}.projectsOverview`)}
    >
      {donutChartData === null ? (
        <div className="center-icon">
          <Icon className="loading-spin" name="loading" />
        </div>
      ) : (
        <DonutChart
          data={donutChartData}
          dataDescription={getTextToDisplay(`${ROOT_TO_TEXT}.activeProjects`)}
          legendOrientation="right"
        />
      )}

      <Button
        block
        onClick={() => {
          initializeEventToTrack({
            eventAction: AmplitudeActionType.CLICK,
            eventName: amplitudeEventDetail.dashboard.viewProjectsEventName,
            eventProperty: {
              'all-projects-overview': (values || [])
                .map(({ parentPracticeAreaName }) => parentPracticeAreaName)
                .join(', ')
            },
            isTrackingOrigin: true
          });

          history.push('/app/projects/overview');
        }}
        variant={EButtonVariant.PRIMARY}
      >
        {getTextToDisplay(`${ROOT_TO_TEXT}.viewProjects`)}
      </Button>
    </Card>
  );
};

export default ProjectsOverviewCard;
