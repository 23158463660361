import Table from 'components/Table';
import { IFeatureToggle } from 'interfaces';
import ApiService from 'services/ApiService';
import pushServerErrorToast from 'utils/pushServerErrorToast';

import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Switch } from '@ryan/components';

import './ManageFeatureToggles.scss';

export const ManageFeatureToggles: FunctionComponent = () => {
  const { t } = useTranslation();
  const [featureToggles, setFeatureToggles] = useState<IFeatureToggle[]>([]);

  useEffect(() => {
    ApiService.getFeatureToggles()
      .then(response => {
        setFeatureToggles(response.data);
      })
      .catch(() => {
        pushServerErrorToast();
      });
  }, [t]);

  function updateToggle(featureGuid: string, changes: Partial<IFeatureToggle>) {
    setFeatureToggles(featureToggles =>
      featureToggles.map(ft =>
        ft.featureGuid === featureGuid ? { ...ft, ...changes } : ft
      )
    );
  }

  async function handleToggle(e: React.ChangeEvent<HTMLInputElement>) {
    const featureGuid = e.target.value;
    const isEnabled = e.target.checked;

    // Optimistic!
    updateToggle(featureGuid, { isEnabled });

    ApiService.toggleFeatureToggle(featureGuid, isEnabled).catch(() => {
      pushServerErrorToast();

      // Revert!
      updateToggle(featureGuid, { isEnabled: !isEnabled });
    });
  }

  if (featureToggles === null) {
    return null;
  }

  return (
    <div className="manage-feature-toggles">
      <h1 className="ry-h1">{t('Feature Toggles')}</h1>

      <Table<IFeatureToggle>
        columns={[
          {
            id: 'featureName',
            label: t('Feature Name'),
            render: 'featureName'
          },
          {
            id: 'isEnabled',
            label: t('Enabled'),
            align: 'right',
            render: featureToggle => (
              <Switch
                checked={featureToggle.isEnabled}
                onChange={handleToggle}
                value={featureToggle.featureGuid}
              />
            )
          }
        ]}
        data={featureToggles}
        rowId="featureGuid"
      />
    </div>
  );
};

export default ManageFeatureToggles;
