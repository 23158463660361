import React from 'react';
import { useTranslation } from 'react-i18next';

import DocumentTitle from '../../components/DocumentTitle';
import WorklistOpen from './WorklistOpen/WorklistOpen';

import './Worklist.scss';

const Worklist = () => {
  const ROOT_TO_TEXT = 'worklistPage';

  const { t: getTextToDisplay } = useTranslation();

  return (
    <div className="worklist">
      <DocumentTitle title={getTextToDisplay(`${ROOT_TO_TEXT}.title`)} />
      <h1 className="ry-h1">{getTextToDisplay(`${ROOT_TO_TEXT}.title`)}</h1>

      {/* V2 */}
      {/* <TabManager activeTabKey={activeTabKey} onTabChange={setActiveTabKey as any}>
        <Tabs tabs={tabs} />

        <TabPanel tabKey="open">
          <WorklistOpen />
        </TabPanel>
        <TabPanel tabKey="completed">
          <WorklistCompleted />
        </TabPanel>
      </TabManager> */}

      <WorklistOpen />
    </div>
  );
};

export default Worklist;
