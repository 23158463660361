import { FileAutocomplete } from 'components/AutocompleteAjax';
import DSSFileUpload from 'components/DSSFileUpload/DSSFileUpload';
import { FileDirectoryBrowser } from 'components/FileDirectory';
import {
  DirectoryItemType,
  IAttachmentUpdates,
  IDirectoryFile,
  IFile
} from 'interfaces';
import { DSSManager, DSSManagerEventType } from 'utils/DSS';

import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import SelectedFile from './SelectedFile';

import './FileAttach.scss';

export interface IFileAttachProps extends WithTranslation {
  // existing files to attach
  attachExisting?: boolean;

  dss: DSSManager;

  // engagement guid to display files for
  engagementGuid: string | null;

  // engagement name to display as the root directory name when browsing for
  engagementName: string | null;

  // a list of document guids that should not be displayed in existing file
  // search
  excludeFromSearchResults?: string[];

  existingFile?: IDirectoryFile[];

  // whether to show Ryan Internal folder in folder selection
  isInternalFolderShown?: boolean;

  // select view
  isSelecting?: boolean;

  // title of exising file select section
  fileSelectTitle?: string;

  // title of drag and drop section
  multiple?: boolean;

  // called on additon/removal of file attachments
  onAttachmentUpdate?: (
    attachments: IAttachmentUpdates,
    existingFilesToAttach: IFile[]
  ) => void;

  setExistingFile?: React.Dispatch<React.SetStateAction<IDirectoryFile[]>>;

  // title of DSS file upload section
  uploadTitle?: string;
  // title of DSS file select section
  selectTitle?: string;
  // dropzone size
  smallDropzone?: boolean;
}

interface IFileAttachState {
  // attachment updates
  attachments: IAttachmentUpdates;

  // list of already uploaded files to attach; this list is maintains in the
  // IFile format for rendering as IAttachmentUpdates only contains a list of
  // document guids for existing files
  existingFilesToAttach: IFile[];
}

/**
 * Supports attaching new or existing files.
 * @todo: Investigate DSSFileUpload rendering of progress bars when rendered in
 * functional component.
 */
class FileAttach extends React.Component<IFileAttachProps, IFileAttachState> {
  constructor(props: IFileAttachProps) {
    super(props);
    this.state = {
      attachments: {
        addUploaded: [],
        addExisting: [],
        deleteAttachments: []
      },
      existingFilesToAttach: []
    };
  }

  componentDidMount() {
    const { dss, existingFile } = this.props;
    dss.addListener('change', this.handleDSSChange);

    if (existingFile && existingFile.length > 0) {
      existingFile.forEach((file: IDirectoryFile) => {
        this.attachExistingFileFromProps(file);
      });
    }
  }

  componentDidUpdate({ dss: prevDss }: IFileAttachProps) {
    const { dss } = this.props;
    if (dss !== prevDss) {
      prevDss.removeListener('change', this.handleDSSChange);
      dss.addListener('change', this.handleDSSChange);
    }
  }

  componentWillUnmount() {
    const { dss } = this.props;
    dss.removeListener('change', this.handleDSSChange);
  }

  attachExistingFileFromProps(file: IDirectoryFile) {
    const { existingFilesToAttach } = this.state;
    const { dss, setExistingFile } = this.props;

    if (
      !existingFilesToAttach.some(
        existingFile => existingFile.documentGuid === file.documentGuid
      )
    ) {
      this.setState(prevState => {
        const updatedExistingFilesToAttach: IFile[] = [
          ...prevState.existingFilesToAttach,
          file as IFile
        ];
        if (setExistingFile) {
          setExistingFile(updatedExistingFilesToAttach as IDirectoryFile[]);
        }
        return {
          attachments: {
            ...prevState.attachments,
            addUploaded: dss.getUploadedDocuments(),
            addExisting: updatedExistingFilesToAttach.map(
              file => file.documentGuid
            )
          },
          existingFilesToAttach: updatedExistingFilesToAttach
        };
      }, this.handleAttachmentUpdate);
    }
  }

  handleDSSChange = (event: DSSManagerEventType) => {
    const { dss } = this.props;
    // allow parent to respond to attachments updates;
    // if new file deleted or removed, retrieve uploaded file list
    if (
      event === DSSManagerEventType.Uploaded ||
      event === DSSManagerEventType.Removed
    ) {
      this.setState(
        prevState => ({
          attachments: {
            ...prevState.attachments,
            addUploaded: dss.getUploadedDocuments()
          }
        }),
        this.handleAttachmentUpdate
      );
    }
  };

  /**
   * Allows parent components to respond to file attachment updates.
   */
  handleAttachmentUpdate() {
    const { onAttachmentUpdate } = this.props;

    if (typeof onAttachmentUpdate === 'function') {
      const { attachments, existingFilesToAttach } = this.state;
      onAttachmentUpdate(attachments, existingFilesToAttach);
    }
  }

  /**
   * Adds selected files from file autocomplete input to list of files to be
   * attached.
   */
  handleExistingFileAttach(newFile: IDirectoryFile | null) {
    const { existingFilesToAttach } = this.state;
    const { setExistingFile } = this.props;

    // only update state if file has not already been marked for attachment
    if (
      newFile &&
      !existingFilesToAttach.some(
        file => file.documentGuid === newFile.documentGuid
      )
    ) {
      this.setState(prevState => {
        const updatedExistingFilesToAttach: IFile[] = [
          ...prevState.existingFilesToAttach,
          newFile as IFile
        ];
        if (setExistingFile) {
          setExistingFile(updatedExistingFilesToAttach as IDirectoryFile[]);
        }
        return {
          attachments: {
            ...prevState.attachments,
            addExisting: updatedExistingFilesToAttach.map(
              file => file.documentGuid
            )
          },
          existingFilesToAttach: updatedExistingFilesToAttach
        };
      }, this.handleAttachmentUpdate);
    }
  }

  /**
   * Removes existing file from list of files to be attached.
   */
  handleExistingFileDelete(fileToDelete: IFile) {
    this.setState(prevState => {
      const updatedAddExisting = prevState.existingFilesToAttach.filter(
        file => file.documentGuid !== fileToDelete.documentGuid
      );
      return {
        attachments: {
          ...prevState.attachments,
          addExisting: updatedAddExisting.map(file => file.documentGuid)
        },
        existingFilesToAttach: updatedAddExisting
      };
    }, this.handleAttachmentUpdate);
  }

  render() {
    const {
      attachExisting = true,
      dss,
      engagementGuid,
      engagementName,
      excludeFromSearchResults = [],
      isInternalFolderShown,
      isSelecting = false,
      fileSelectTitle = this.props.t('Select Files', { context: 'existing' }),
      multiple = true,
      uploadTitle = this.props.t('Upload Files', { context: 'new' }),
      selectTitle = this.props.t('Select Files'),
      smallDropzone = true
    } = this.props;
    const { existingFilesToAttach } = this.state;
    const exclude = [
      ...excludeFromSearchResults,
      ...existingFilesToAttach.map(file => file.documentGuid)
    ];

    return (
      <div className="file-attach">
        <DSSFileUpload
          dssManager={dss}
          multiple={multiple}
          smallDropzone={smallDropzone}
          title={isSelecting ? selectTitle : uploadTitle}
        />
        {attachExisting && (
          <div className="file-attach__existing">
            <h3 className="ry-h3">{fileSelectTitle}</h3>
            <FileAutocomplete
              disabled={engagementGuid == null}
              engagementGuid={engagementGuid}
              exclude={exclude}
              isInternalFolderShown={isInternalFolderShown}
              onChange={directoryItem =>
                this.handleExistingFileAttach(
                  directoryItem?.item as IDirectoryFile
                )
              }
            />
            <FileDirectoryBrowser
              engagementGuid={engagementGuid}
              engagementName={engagementName}
              exclude={exclude}
              isInternalFolderShown={isInternalFolderShown}
              onChange={item => {
                if (item.type === DirectoryItemType.File) {
                  this.handleExistingFileAttach(item.item as IDirectoryFile);
                }
              }}
            />
            {existingFilesToAttach.length > 0 && (
              <ul className="ry-file-upload__files">
                {existingFilesToAttach.map((file, i) => (
                  <SelectedFile
                    file={file}
                    key={`attachment-existing-${i}-${file.documentGuid}`}
                    onFileTrashed={file => this.handleExistingFileDelete(file)}
                  />
                ))}
              </ul>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation()(FileAttach);
