import classNames from 'classnames';
import { isValid } from 'date-fns';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, EButtonVariant, Icon } from '@ryan/components';

import Datepicker from '../../../components/Datepicker/Datepicker';
import { FormikErrors, formikDatepickerProps } from '../../../utils/forms';
import useUniqueId from '../../../utils/useUniqueId';
import { TMultiSelectDateRange } from '../utils';
import { IFillDateInput, IMultiSelectDateRangeFieldProps } from './utils';

import './MultiSelectDateRangeField.scss';

const checkForDuplicates = (ranges: TMultiSelectDateRange[]) => {
  const uniqueRanges = ranges.filter(range => range.startDate && range.endDate);
  const duplicates = uniqueRanges.filter(
    (range, index, self) =>
      index !==
      self.findIndex(
        t => t.startDate === range.startDate && t.endDate === range.endDate
      )
  );
  return duplicates.length > 0;
};

export const MultiSelectDateRangeField = ({
  errors,
  formik,
  index,
  isRequired,
  item,
  locale,
  onChange,
  onRemove,
  setIsDisabled,
  values
}: IMultiSelectDateRangeFieldProps) => {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const uniqueId = useUniqueId();
  const { t: textToDisplay } = useTranslation();

  useEffect(() => {
    setStartDate(item.startDate);
    setEndDate(item.endDate);
  }, [item]);

  const FORMAT = ['MM/dd/yyyy', 'MMddyyyy'];
  const isDisabled = values.length === 1 && !isRequired;
  const isSubmitInvisible = isRequired && values.length === 1;

  const fillDateInput: IFillDateInput = ({
    date,
    dateItem,
    handleChange,
    handleValidateDate,
    order,
    setDisabledCallback
  }) => {
    if (!dateItem) return null;

    dateItem[order] = date;

    handleChange({
      startDate: dateItem.startDate,
      endDate: dateItem.endDate
    });

    if (
      handleValidateDate(dateItem.startDate) &&
      handleValidateDate(dateItem.endDate)
    ) {
      setDisabledCallback(false);
    } else {
      setDisabledCallback(true);
    }
  };

  const handleDateChange = (
    e: SyntheticEvent | undefined,
    date: Date | null,
    order: 'endDate' | 'startDate'
  ) => {
    if (date) {
      fillDateInput({
        date,
        dateItem: item,
        handleChange: onChange,
        handleValidateDate: isValid,
        order,
        setDisabledCallback: setIsDisabled
      });

      if (order === 'startDate') {
        setStartDate(date);
        formik.setFieldTouched(`daterange[${index}].startDate`, true, false);
        formik.setFieldError(`daterange[${index}].startDate`, '');
      } else {
        setEndDate(date);
        formik.setFieldTouched(`daterange[${index}].endDate`, true, false);
        formik.setFieldError(`daterange[${index}].endDate`, '');
      }
    }
  };

  const daterangeErrors = Array.isArray(errors?.daterange)
    ? (errors?.daterange as FormikErrors<any>[])
    : [];

  const startDateError = daterangeErrors[index]?.startDate || null;
  const endDateError = daterangeErrors[index]?.endDate || null;

  const rangeErrorMessage =
    typeof daterangeErrors?.[index]?.range === 'string'
      ? daterangeErrors[index].range
      : null;

  const showHelperTextForStartDate =
    Boolean(endDateError) && !Boolean(startDateError);
  const showHelperTextForEndDate =
    Boolean(startDateError) && !Boolean(endDateError);

  return (
    <div data-testid="mutliselect-daterange-field" id={uniqueId}>
      <div className="multiselect-daterange__outer-container">
        <div className="multiselect-daterange__outer-container__field">
          <div className="multiselect-daterange__outer-container__field__inner-container">
            <div
              className={classNames(
                'multiselect-daterange__outer-container__field__inner-container__inputs-wrapper',
                {
                  'multiselect-daterange__outer-container__field__inner-container__inputs-wrapper--errors':
                    Boolean(rangeErrorMessage)
                }
              )}
            >
              <div className="multiselect-daterange__outer-container__field__inner-container__inputs-wrapper__input">
                <Datepicker
                  {...formikDatepickerProps('startDate', formik)}
                  autoComplete={'off'}
                  dateFormat={FORMAT}
                  feedback={startDateError as string}
                  helperText={
                    showHelperTextForStartDate ? 'Placeholder' : undefined
                  }
                  id={`dateinput-start-${uniqueId}`}
                  invalid={!!startDateError}
                  label={textToDisplay('multiSelectDateRange.startDate')}
                  locale={locale}
                  maxDate={new Date()}
                  onChange={(e, date) => handleDateChange(e, date, 'startDate')}
                  placeholderText={FORMAT[0].toUpperCase()}
                  selectsStart
                  startDate={startDate}
                  value={startDate}
                />
              </div>
              <div
                className={classNames(
                  'multiselect-daterange__outer-container__field__inner-container__inputs-wrapper__mdash',
                  {
                    'multiselect-daterange__outer-container__field__inner-container__inputs-wrapper__mdash--errors':
                      Boolean(startDateError) && Boolean(endDateError),
                    'multiselect-daterange__outer-container__field__inner-container__inputs-wrapper__mdash--error':
                      Boolean(startDateError) !== Boolean(endDateError)
                  }
                )}
              >
                &mdash;
              </div>
              <div className="multiselect-daterange__outer-container__field__inner-container__inputs-wrapper__input">
                <Datepicker
                  {...formikDatepickerProps('endDate', formik)}
                  autoComplete={'off'}
                  dateFormat={FORMAT}
                  endDate={endDate}
                  feedback={endDateError as string}
                  helperText={
                    showHelperTextForEndDate ? 'Placeholder' : undefined
                  }
                  id={`dateinput-end-${uniqueId}`}
                  invalid={!!endDateError}
                  label={textToDisplay('multiSelectDateRange.endDate')}
                  locale={locale}
                  maxDate={new Date()}
                  onChange={(e, date) => handleDateChange(e, date, 'endDate')}
                  placeholderText={FORMAT[0].toUpperCase()}
                  selectsEnd
                  value={endDate}
                />
              </div>
            </div>
          </div>
          <Button
            className={classNames(
              'multiselect-daterange__outer-container__field__submit',
              'ry-button--negative-text',
              {
                'multiselect-daterange__outer-container__field__submit--disabled':
                  isDisabled,
                'multiselect-daterange__outer-container__field__submit--invisible':
                  isSubmitInvisible,
                'multiselect-daterange__outer-container__field__submit--error':
                  Boolean(startDateError) !== Boolean(endDateError),
                'multiselect-daterange__outer-container__field__submit--errors':
                  Boolean(startDateError) && Boolean(endDateError)
              }
            )}
            data-testid="trash-icon-button"
            disabled={isDisabled}
            icon="trash"
            onClick={async () => {
              if (!Array.isArray(values)) {
                return;
              }

              const removedDateRange = values[index];

              onRemove();

              const newDateRanges = [...values];
              newDateRanges.splice(index, 1);

              const newErrors = { ...formik.errors };
              if (newErrors.daterange && Array.isArray(newErrors.daterange)) {
                newErrors.daterange.splice(index, 1);
                if (newErrors.daterange.length === 0) {
                  delete newErrors.daterange;
                }
              }

              await formik.setFieldValue('daterange', newDateRanges, false);
              formik.setErrors(newErrors);

              const hasDuplicates = checkForDuplicates(newDateRanges);
              const isRemovedRangeEmpty =
                removedDateRange.startDate === null &&
                removedDateRange.endDate === null;

              const isLastDateRangeNotEmpty =
                newDateRanges.length > 0 &&
                newDateRanges[newDateRanges.length - 1].startDate !== null &&
                newDateRanges[newDateRanges.length - 1].endDate !== null;

              if (!isRemovedRangeEmpty) {
                if (!hasDuplicates) {
                  const updatedErrors = { ...formik.errors };
                  delete updatedErrors.daterange;
                  formik.setErrors(updatedErrors);
                }

                if (hasDuplicates) {
                  await formik.validateForm();
                } else if (isLastDateRangeNotEmpty) {
                  await formik.validateForm();
                }
              }

              setIsDisabled(
                newDateRanges.length === 0 ||
                  newDateRanges[newDateRanges.length - 1].startDate === null ||
                  newDateRanges[newDateRanges.length - 1].endDate === null
              );
            }}
            variant={EButtonVariant.TEXT}
          />
        </div>
        {rangeErrorMessage ? (
          <div className="multiselect-daterange__outer-container__error">
            <Icon name="error" />
            {rangeErrorMessage}
          </div>
        ) : (
          <div className="multiselect-daterange__outer-container__placeholder" />
        )}
      </div>
    </div>
  );
};

export default MultiSelectDateRangeField;
