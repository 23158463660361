import { UserType } from './UserType';

export interface IFile {
  archiveDate: Date | null;
  archivedBy: string | null;
  archivedByName: string | null;
  documentGuid: string;
  documentName: string;
  friendlyName: string | null;
  displayName: string; // friendlyName || documentName

  mimeType: string;
  fileType: string;
  size: number;

  createDate: Date;
  uploadedDate: Date;
  isReady: boolean; // Chunked uploads take time to piece together
  canBeDeletedByUser: boolean;

  updatedByUserType?: UserType;
  updatedByName?: string;
  updatedImpersonatedByName?: string;
}

export enum FileType {
  Document = 1,
  Invoice = 2,
  Contract = 3,
  ZipFile = 4
}
