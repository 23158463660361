import { TActionsByEntityTypeProps } from './ActionsByEntityType.interfaces';

import React from 'react';

import { EntityType } from '../../../../../interfaces';
import DataRequestActions from './DataRequestActions/DataRequestActions';
import TaskActions from './TaskActions/TaskActions';

const ActionsByEntityType = (props: TActionsByEntityTypeProps) => {
  const {
    commonEntityTypeProps,
    dataRequestTypeProps,
    entityTypeId,
    isEngagementReadOnly,
    onSetWorklistItemToState,
    taskTypeProps
  } = props;

  const defaultProps = {
    ...commonEntityTypeProps,
    isEngagementReadOnly,
    onSetWorklistItemToState
  };

  let ActionByEntityTypeElement = null;

  switch (entityTypeId) {
    case EntityType.DataRequest:
      ActionByEntityTypeElement = (
        <DataRequestActions {...defaultProps} {...dataRequestTypeProps} />
      );
      break;
    case EntityType.Task:
      ActionByEntityTypeElement = (
        <TaskActions {...defaultProps} {...taskTypeProps} />
      );
      break;
    default:
  }

  return ActionByEntityTypeElement;
};

export default ActionsByEntityType;
