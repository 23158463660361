import { TTaskModalsProps } from './TaskModals.interfaces';
import {
  TaskModalActionEnums,
  mapAssignmentToCompleteTask,
  mapAssignmentToDeleteTask,
  mapAssignmentToEditTask,
  mapAssignmentToReasignTask
} from './TaskModals.utils';

import React from 'react';

import CompleteTaskModal from '../../../components/TaskActions/CompleteTaskModal/CompleteTaskModal';
import DeleteTaskModal from '../../../components/TaskActions/DeleteTaskModal/DeleteTaskModal';
import ReassignTaskModal from '../../../components/TaskActions/ReassignTaskModal/ReassignTaskModal';
import EditTaskModal from '../../../components/TaskActions/TaskModal/TaskModal';
import { ITask } from '../../../interfaces';

const TaskModals = ({
  assignmentToComplete,
  assignmentToDelete,
  assignmentToEdit,
  assignmentToReassign,
  onClose
}: TTaskModalsProps) => {
  let modalAction: TaskModalActionEnums | null = null;
  let task;

  if (assignmentToComplete) {
    modalAction = TaskModalActionEnums.COMPLETE;
    task = mapAssignmentToCompleteTask(assignmentToComplete);
  } else if (assignmentToDelete) {
    modalAction = TaskModalActionEnums.DELETE;
    task = mapAssignmentToDeleteTask(assignmentToDelete);
  } else if (assignmentToEdit) {
    modalAction = TaskModalActionEnums.EDIT;
    task = mapAssignmentToEditTask(assignmentToEdit);
  } else if (assignmentToReassign) {
    modalAction = TaskModalActionEnums.REASSIGN;
    task = mapAssignmentToReasignTask(assignmentToReassign);
  }

  return (
    <>
      {modalAction === TaskModalActionEnums.COMPLETE && (
        <CompleteTaskModal
          onClose={(updatedTask?: ITask) => {
            onClose(updatedTask, modalAction as TaskModalActionEnums);
          }}
          task={task as ITask}
        />
      )}
      {modalAction === TaskModalActionEnums.DELETE && (
        <DeleteTaskModal
          onClose={(isDeleted: boolean) => {
            onClose(
              isDeleted ? ({} as ITask) : undefined,
              modalAction as TaskModalActionEnums
            );
          }}
          task={task as ITask}
        />
      )}
      {modalAction === TaskModalActionEnums.EDIT && (
        <EditTaskModal
          onClose={(editedTask?: ITask) => {
            onClose(editedTask, modalAction as TaskModalActionEnums);
          }}
          task={task as ITask}
        />
      )}

      {modalAction === TaskModalActionEnums.REASSIGN && (
        <ReassignTaskModal
          onClose={(editedTask?: ITask) => {
            onClose(editedTask, modalAction as TaskModalActionEnums);
          }}
          task={task as ITask}
        />
      )}
    </>
  );
};

export default TaskModals;
