import { TTaskActionsProps } from './TaskActions.interfaces';

import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, EButtonSizes, SplitButton } from '@ryan/components';

import { useAppReadOnly, useUser } from '../../../../../../hooks';
import { Permission, Status, UserType } from '../../../../../../interfaces';
import { StateActionEnums } from '../../../../../../routes/Worklist/WorklistOpen/WorklistOpen.utils';
import { IconEnums } from '../../../../../../utils/enums/IconEnums';

const TaskActions = ({
  createdByUserType,
  isEngagementReadOnly,
  onMarkTaskInProgress,
  onSetWorklistItemToState,
  statusId
}: TTaskActionsProps) => {
  const ROOT_TO_TEXT = 'table.columns.worklist.actionsByEntityType.task';

  const isAppReadOnly = useAppReadOnly();
  const { t: getTextToDisplay } = useTranslation();
  const { permissionService } = useUser();

  const defaultProps = { block: true, size: EButtonSizes.SMALL };
  const isCreatedByClient = createdByUserType === UserType.Client;
  const isDisabledSplitButtonOptions = isAppReadOnly || isEngagementReadOnly;

  const splitButtonOptions = [
    {
      disabled: isDisabledSplitButtonOptions,
      icon: IconEnums.PENCIL,
      label: getTextToDisplay(`${ROOT_TO_TEXT}.edit`),
      onClick: () => {
        onSetWorklistItemToState(StateActionEnums.TO_EDIT);
      }
    },
    ...(statusId === Status.Todo
      ? [
          {
            disabled: isDisabledSplitButtonOptions,
            icon: IconEnums.APPROVE,
            label: getTextToDisplay(`${ROOT_TO_TEXT}.complete`),
            onClick: () => {
              onSetWorklistItemToState(StateActionEnums.TO_COMPLETE);
            }
          }
        ]
      : []),
    {
      disabled: isDisabledSplitButtonOptions,
      icon: IconEnums.USER_REASSIGN,
      label: getTextToDisplay(`${ROOT_TO_TEXT}.reassign`),
      onClick: () => {
        onSetWorklistItemToState(StateActionEnums.TO_REASSIGN);
      }
    },
    ...(permissionService.isClient() && !isCreatedByClient
      ? []
      : [
          {
            disabled: isDisabledSplitButtonOptions,
            icon: IconEnums.TRASH,
            label: getTextToDisplay(`${ROOT_TO_TEXT}.delete`),
            negative: true,
            onClick: () => {
              onSetWorklistItemToState(StateActionEnums.TO_DELETE);
            }
          }
        ])
  ];

  return permissionService.hasPermission(Permission.TasksEdit) ? (
    <SplitButton
      {...defaultProps}
      disabled={isDisabledSplitButtonOptions}
      onClick={
        statusId === Status.Todo
          ? onMarkTaskInProgress
          : () => {
              onSetWorklistItemToState(StateActionEnums.TO_COMPLETE);
            }
      }
      options={splitButtonOptions}
      size={EButtonSizes.SMALL}
    >
      {getTextToDisplay(
        `${ROOT_TO_TEXT}.mark${Object.values(Status)[statusId]}`
      )}
    </SplitButton>
  ) : (
    <Button {...defaultProps} disabled>
      {getTextToDisplay(
        `${ROOT_TO_TEXT}.${Object.values(Status)[statusId - 1]}`
      )}
    </Button>
  );
};

export default TaskActions;
