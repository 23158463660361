import { RYAN_INTERNAL } from 'components/FileDirectory/utils/FileDirectoryEnums';

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon, autocompleteHighlight } from '@ryan/components';

import { useUser } from '../../../hooks';
import {
  DirectoryFilter,
  Feature,
  IDirectoryFile,
  IDirectoryItem,
  IFolderTree
} from '../../../interfaces';
import { DirectoryItemStatus } from '../../../routes/DataAndFiles/Files/Files';
import ApiService, { CancelTokenSource } from '../../../services/ApiService';
import getDirectoryItemIcon from '../../../utils/getDirectoryItemIcon';
import getSortParam from '../../../utils/getSortParm';
import pushServerErrorToast from '../../../utils/pushServerErrorToast';
import AutocompleteAjax, { IAutocompleteAjaxProps } from '../AutocompleteAjax';

import './FileAutocomplete.scss';

export interface IFileAutocompleteProps
  extends Omit<
    IAutocompleteAjaxProps<IDirectoryItem>,
    'getOptionValue' | 'onFetchOptions' | 'renderOption' | 'value'
  > {
  // the engagement to search for files within
  engagementGuid: string | null;

  // list of documentGuids to exlude from search results
  exclude?: string[];

  // whether to show Ryan Internal folder in folder selection
  isInternalFolderShown?: boolean;
}

// default parameters for searching for files within an engagement directory
const engagementDirectorySearchParams = {
  sort: getSortParam({
    id: 'createDate',
    desc: false
  }),
  pageNumber: 1,
  itemsPerPage: 100,
  filterGuids: Object.values(DirectoryFilter).filter(
    filterGuid => filterGuid !== DirectoryFilter.Folder
  )
};

/**
 * Renders a file result.
 */
const renderFileOption = (
  directoryItem: IDirectoryItem,
  { query }: { query: string }
) => {
  const file = directoryItem.item as IDirectoryFile;
  return (
    <span className="bs bs--icon bs--light">
      <Icon name={getDirectoryItemIcon(directoryItem)} />
      {autocompleteHighlight(file.displayName, query)}
      {file.documentName && (
        <small>{autocompleteHighlight(file.documentName, query)}</small>
      )}
    </span>
  );
};

/**
 * Autocomplete search for files within an engagement. The `onChange` method
 * should be used to respond to file selections.
 */
const FileAutocomplete: React.FC<IFileAutocompleteProps> = ({
  engagementGuid,
  exclude = [],
  isInternalFolderShown,
  placeholder,
  onChange,
  ...rest
}) => {
  const { t } = useTranslation();
  const { isFeatureToggled } = useUser();
  const sourceRef = useRef<CancelTokenSource>();
  const [folders, setFolders] = useState<IFolderTree[]>();
  const [isInternalFilesFeatureToggled] = useState(
    isFeatureToggled(Feature.InternalFiles)
  );

  /**
   * Fetches directories within base engagement directories. These directories
   * will also be searched for matching files.
   */
  const fetchEngagementFolders = useCallback(async () => {
    let engagementFolders: IFolderTree[] = [];
    if (engagementGuid) {
      // reset cancel token
      sourceRef.current?.cancel();
      sourceRef.current = ApiService.CancelToken.source();

      try {
        const response = await ApiService.getEngagementFolders(
          engagementGuid,
          sourceRef.current.token
        );
        engagementFolders = response.data;

        const foldersWithoutInternal = engagementFolders.filter(
          folder => folder.folderName !== RYAN_INTERNAL
        );
        if (isInternalFilesFeatureToggled && isInternalFolderShown) {
          setFolders(engagementFolders);
        } else {
          setFolders(foldersWithoutInternal);
        }
      } catch (error) {
        if (!ApiService.isCancel(error)) {
          pushServerErrorToast();
        }
      }

      return engagementFolders;
    }
  }, [engagementGuid, isInternalFilesFeatureToggled, isInternalFolderShown]);

  /**
   * Fetches matching files within an engagement directory.
   */
  const fetchMatchingFiles = useCallback(
    async (searchQuery: string) => {
      const engagementFolders = folders || (await fetchEngagementFolders());
      let files: IDirectoryItem[] = [];

      if (engagementGuid && engagementFolders) {
        // reset cancel token
        sourceRef.current?.cancel();
        sourceRef.current = ApiService.CancelToken.source();

        try {
          const response = await ApiService.getEngagementDirectoryBySearch(
            engagementGuid,
            {
              ...engagementDirectorySearchParams,
              searchTerm: searchQuery,
              status: DirectoryItemStatus.ACTIVE
            },
            engagementFolders,
            sourceRef.current.token
          );
          const directoriesResponse = response.data;

          // folders have been filtered out of results via `filterGuids`
          const filesOnly = directoriesResponse.results;

          // filter out files added to exclude list as they have already been
          // attached
          files = filesOnly.filter(
            file =>
              exclude.indexOf((file.item as IDirectoryFile).documentGuid) === -1
          );
        } catch (error) {
          if (!ApiService.isCancel(error)) {
            pushServerErrorToast();
          }
        }
      }
      return files;
    },
    [engagementGuid, exclude, folders, fetchEngagementFolders]
  );

  // cancel ongoing requests on unmount
  useEffect(
    () => () => {
      sourceRef.current?.cancel();
    },
    []
  );

  // cancel ongoing requests on unmount
  useEffect(
    () => () => {
      sourceRef.current?.cancel();
    },
    []
  );

  return (
    <div className="file-autocomplete">
      <AutocompleteAjax<IDirectoryItem>
        getOptionValue={() => ''}
        onChange={file => onChange(file)}
        onFetchOptions={fetchMatchingFiles}
        placeholder={placeholder || t('Search', { context: 'files' })}
        renderOption={renderFileOption}
        value={null}
        {...rest}
      />
    </div>
  );
};

export default FileAutocomplete;
