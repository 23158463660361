import {
  IHandleOpenDataRequestDrawer,
  IHandleOpenTaskDrawer,
  IHandleToggleFollow,
  TWorklistItem
} from './WorklistOpen.interfaces';
import {
  StateActionEnums,
  mapAssignmentToUpdateTaskApi,
  mapGetWorklistAssignmentsResponseToWorklist
} from './WorklistOpen.utils';

import { CancelToken } from 'axios';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';

import { EMessageTypes, Table, pushToast } from '@ryan/components';

import Empty from '../../../components/Empty';
import FollowersModal from '../../../components/FollowersModal/FollowersModal';
import useWorkListColumns from '../../../components/Table/Columns/Worklist/useWorkListColumns';
import { WorklistColumnEnums } from '../../../components/Table/Columns/columns.enums';
import DataRequestExpandedRow, {
  mapToDataRequestExpandedRow
} from '../../../components/Table/ExpandedRow/Worklist/DataRequestExpandedRow/DataRequestExpandedRow';
import TaskExpandedRow, {
  mapToTaskExpandedRow
} from '../../../components/Table/ExpandedRow/Worklist/TaskExpandedRow/TaskExpandedRow';
import TableEmpty from '../../../components/TableEmpty/TableEmpty';
import { useDataRequestDrawer } from '../../../contexts/DataRequestDrawerContext';
import { useTaskDrawer } from '../../../contexts/TaskDrawerContext';
import { useUser } from '../../../hooks';
import {
  EntityType,
  IDataRequest,
  IDataRequestV2,
  ITableState,
  ITask,
  Status
} from '../../../interfaces';
import ApiService, { CancelTokenSource } from '../../../services/ApiService';
import debouncedSearch from '../../../utils/debouncedSearch';
import { SubStatusEnums } from '../../../utils/enums/SubStatusEnums';
import getSortParam from '../../../utils/getSortParm';
import { isPastDue } from '../../../utils/isPastDue';
import pushServerErrorToast from '../../../utils/pushServerErrorToast';
import DataRequestModals from '../DataRequestModals/DataRequestModals';
import { DataRequestModalActionEnums } from '../DataRequestModals/DataRequestModals.utils';
import TaskModals from '../TaskModals/TaskModals';
import { TaskModalActionEnums } from '../TaskModals/TaskModals.utils';

import './WorklistOpen.scss';

const WorklistOpen = () => {
  const ROOT_TO_TEXT = 'worklistPage.openTab';

  const {
    dataRequestDrawerEvents,
    onDataRequestDrawerOpenComments,
    onDataRequestDrawerOpenHistory
  } = useDataRequestDrawer();
  const { onTaskDrawerOpen, taskDrawerEvents } = useTaskDrawer();
  const { t: getTextToDisplay } = useTranslation();
  const {
    user: { profile: activeUserProfile }
  } = useUser();

  const sourceRef = useRef<CancelTokenSource>();
  const [assigmentToAssignDataUpload, setAssigmentToAssignDataUpload] =
    useState<TWorklistItem | null>(null);
  const [assignmentToComplete, setAssignmentToComplete] =
    useState<TWorklistItem | null>(null);
  const [assignmentToDelete, setAssignmentToDelete] =
    useState<TWorklistItem | null>(null);
  const [assignmentToEdit, setAssignmentToEdit] =
    useState<TWorklistItem | null>(null);
  const [assignmentToReassign, setAssignmentToReassign] =
    useState<TWorklistItem | null>(null);
  const [assignmentToUpdateFollowers, setAssignmentToUpdateFollowers] =
    useState<TWorklistItem | null>(null);
  const [expandedRows, setExpandedRows] = useState<{
    [rowId: string]: boolean;
  }>({});
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdatingFollowStatus, setIsUpdatingFollowStatus] = useState(false);
  const [pageView, setPageView] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [searchQuery, setSearchQuery] = useState('');
  const [sorted, setSorted] = useState<ITableState['sorted']>({
    id: WorklistColumnEnums.DUE_DATE,
    desc: false
  });
  const [totalResults, setTotalResults] = useState(0);
  const [worklist, setWorklist] = useState<TWorklistItem[]>([]);

  const getWorklistAssignments = useCallback(
    async (cancelToken: CancelToken) => {
      const { data } = await ApiService.getWorklistAssignments(cancelToken, {
        itemsPerPage: pageSize,
        pageNumber: pageView,
        searchTerm: searchQuery,
        sort: getSortParam(sorted)
      });

      const mappedWorklist = mapGetWorklistAssignmentsResponseToWorklist(
        data.results
      );

      setTotalResults(
        data.totalResults - (data.results.length - mappedWorklist.length)
      );
      setWorklist(mappedWorklist);
      setIsUpdatingFollowStatus(false);
      setIsLoading(false);
    },
    [pageSize, pageView, searchQuery, setIsLoading, setWorklist, sorted]
  );

  const onSearch = useMemo(
    () =>
      debouncedSearch(
        (searchQuery: string) => {
          setSearchQuery(searchQuery);
        },
        (searchQuery: string) => {
          setSearchQuery(searchQuery);
          setPageView(1);
          setIsLoading(true);
        }
      ),
    []
  );

  useEffect(() => {
    const refreshData = () => {
      setIsLoading(true);
    };

    dataRequestDrawerEvents.addListener('commentAdded', refreshData);
    dataRequestDrawerEvents.addListener('commentEdited', refreshData);
    dataRequestDrawerEvents.addListener('commentRemoved', refreshData);
    taskDrawerEvents.addListener('commentAdded', refreshData);
    taskDrawerEvents.addListener('commentEdited', refreshData);
    taskDrawerEvents.addListener('commentRemoved', refreshData);
  }, [dataRequestDrawerEvents, taskDrawerEvents]);

  useEffect(() => {
    if (!isLoading) {
      return;
    }

    sourceRef.current = ApiService.CancelToken.source();
    getWorklistAssignments(sourceRef.current.token);

    return () => {
      sourceRef.current?.cancel();
    };
  }, [isLoading, getWorklistAssignments, sourceRef]);

  const handleOpenDataRequestDrawer: IHandleOpenDataRequestDrawer = (
    {
      engagementGuid,
      engagementDisplayNameShort,
      isEngagementReadOnly,
      queueItemGuid,
      title
    },
    onDataRequestDrawerOpenCallback
  ) => {
    onDataRequestDrawerOpenCallback({
      engagementGuid,
      engagementDisplayNameShort,
      isEngagementReadOnly,
      queueItemGuid,
      title
    } as IDataRequestV2);
  };

  const handleOpenTaskDrawer: IHandleOpenTaskDrawer = (
    {
      engagementGuid,
      engagementDisplayNameShort,
      isEngagementReadOnly,
      queueItemGuid,
      title
    },
    onTaskDrawerOpenCallback
  ) => {
    onTaskDrawerOpenCallback({
      engagementGuid,
      engagementDisplayNameShort,
      isEngagementReadOnly,
      queueItemGuid,
      title
    } as ITask);
  };

  const handleToggleFollow: IHandleToggleFollow = async (
    { engagementGuid, isFollowing, queueItemGuid },
    callbacks
  ) => {
    try {
      callbacks.setIsUpdatingFollowStatus(true);

      await ApiService.addWatcherToQueue(
        engagementGuid,
        !isFollowing,
        queueItemGuid
      );

      callbacks.setIsLoading(true);
    } catch {
      pushServerErrorToast();
    }
  };

  const onMarkDataDelivered = async (worklistItem: TWorklistItem) => {
    const { queueItemGuid, statusId } = worklistItem;

    try {
      const { data } = await ApiService.markDataRequestDataDelivered({
        markAsDataDelivered: true,
        queueItemGuid,
        status: statusId,
        subStatus: SubStatusEnums.DataDelivered
      });

      pushToast({
        content: getTextToDisplay(
          `${ROOT_TO_TEXT}.markDataDeliveredSuccessToastContent`,
          { title: data.title }
        ),
        type: EMessageTypes.SUCCESS
      });

      setIsLoading(true);
    } catch {
      pushServerErrorToast();
    }
  };

  const onMarkTaskInProgress = async (worklistItem: TWorklistItem) => {
    try {
      const { data } = await ApiService.updateTask(
        mapAssignmentToUpdateTaskApi(worklistItem) as ITask,
        { statusId: Status.InProgress }
      );

      pushToast({
        content: getTextToDisplay(
          `${ROOT_TO_TEXT}.markInProgressSucessToastContent`,
          { title: data.title }
        ),
        title: getTextToDisplay(
          `${ROOT_TO_TEXT}.markInProgressSucessToastTitle`
        ),
        type: EMessageTypes.SUCCESS
      });

      setIsLoading(true);
    } catch {
      pushServerErrorToast();
    }
  };

  const stateActionsMap = {
    [StateActionEnums.TO_ASSIGN_DATA_UPLOAD]: setAssigmentToAssignDataUpload,
    [StateActionEnums.TO_COMPLETE]: setAssignmentToComplete,
    [StateActionEnums.TO_DELETE]: setAssignmentToDelete,
    [StateActionEnums.TO_EDIT]: setAssignmentToEdit,
    [StateActionEnums.TO_REASSIGN]: setAssignmentToReassign
  };

  return (
    <div className="worklist-open">
      <Table<TWorklistItem>
        columns={useWorkListColumns({
          onDataRequestCommentClick: worklistItem => {
            handleOpenDataRequestDrawer(
              worklistItem,
              onDataRequestDrawerOpenComments
            );
          },
          onMarkDataDelivered,
          onMarkTaskInProgress,
          onSetWorklistItemToState: (stateAction, worklistItem) => {
            stateActionsMap[stateAction](worklistItem);
          },
          onTaskCommentClick: worklistItem => {
            handleOpenTaskDrawer(worklistItem, onTaskDrawerOpen);
          }
        })}
        data={worklist}
        expanded={expandedRows}
        loading={isLoading}
        onPageChange={(page: number, pageSize: number) => {
          setPageView(page);
          setPageSize(pageSize);
          setIsLoading(true);
        }}
        onSearchChange={onSearch}
        onSortChange={(sorted: any) => {
          setSorted(sorted);
          setIsLoading(true);
        }}
        onToggleExpansion={(isRowExpanded, row, rowId) => {
          setExpandedRows({
            ...expandedRows,
            [rowId]: isRowExpanded
          });
        }}
        page={pageView}
        pageSize={pageSize}
        pageSizeOptions={[10, 25, 50, 100]}
        renderEmpty={() => (
          <TableEmpty searchQuery={searchQuery}>
            <Empty icon="clipboard">
              {getTextToDisplay(`${ROOT_TO_TEXT}.emptyTable`)}
            </Empty>
          </TableEmpty>
        )}
        renderExpandedRow={row => {
          const { entityTypeId } = row;

          let ExpandedRowElement = null;

          switch (entityTypeId) {
            case EntityType.DataRequest:
              ExpandedRowElement = (
                <DataRequestExpandedRow
                  dataRequestDetails={mapToDataRequestExpandedRow(row)}
                  isDisabled={isUpdatingFollowStatus}
                  onOpenDataRequestCommentDrawer={() => {
                    handleOpenDataRequestDrawer(
                      row,
                      onDataRequestDrawerOpenComments
                    );
                  }}
                  onOpenDataRequestHistoryDrawer={() => {
                    handleOpenDataRequestDrawer(
                      row,
                      onDataRequestDrawerOpenHistory
                    );
                  }}
                  onOpenFollowersModal={() => {
                    setAssignmentToUpdateFollowers(row);
                  }}
                  setIsLoading={setIsLoading}
                  setIsUpdatingFollowStatus={setIsUpdatingFollowStatus}
                />
              );
              break;
            case EntityType.Task:
              ExpandedRowElement = (
                <TaskExpandedRow
                  isDisabled={isUpdatingFollowStatus}
                  onLinkToMilestone={() => {
                    setAssignmentToEdit(row);
                  }}
                  onOpenFollowersModal={() => {
                    setAssignmentToUpdateFollowers(row);
                  }}
                  onOpenTaskCommentDrawer={() => {
                    handleOpenTaskDrawer(row, onTaskDrawerOpen);
                  }}
                  onToggleFollow={() => {
                    handleToggleFollow(row, {
                      setIsUpdatingFollowStatus,
                      setIsLoading
                    });
                  }}
                  taskDetails={mapToTaskExpandedRow(row)}
                />
              );
              break;
            default:
          }

          return ExpandedRowElement;
        }}
        rowClassName={row =>
          isPastDue(new Date(row.dueDate), new Date())
            ? 'past-due-assignment'
            : ''
        }
        rowId="queueItemGuid"
        searchQuery={searchQuery}
        sorted={sorted}
        totalCount={totalResults}
      />

      <DataRequestModals
        assigmentToAssignDataUpload={
          assigmentToAssignDataUpload &&
          assigmentToAssignDataUpload.entityTypeId === EntityType.DataRequest
            ? assigmentToAssignDataUpload
            : null
        }
        assignmentToComplete={
          assignmentToComplete &&
          assignmentToComplete.entityTypeId === EntityType.DataRequest
            ? assignmentToComplete
            : null
        }
        assignmentToDelete={
          assignmentToDelete &&
          assignmentToDelete.entityTypeId === EntityType.DataRequest
            ? assignmentToDelete
            : null
        }
        assignmentToEdit={
          assignmentToEdit &&
          assignmentToEdit.entityTypeId === EntityType.DataRequest
            ? assignmentToEdit
            : null
        }
        onClose={(
          editedDataRequest: IDataRequest | undefined,
          modalAction: DataRequestModalActionEnums
        ) => {
          if (editedDataRequest) {
            setIsLoading(true);
          }

          switch (modalAction) {
            case DataRequestModalActionEnums.ASSIGN_DATA_UPLOAD:
              setAssigmentToAssignDataUpload(null);
              break;
            case DataRequestModalActionEnums.COMPLETE:
              setAssignmentToComplete(null);
              break;
            case DataRequestModalActionEnums.DELETE:
              setAssignmentToDelete(null);
              break;
            case DataRequestModalActionEnums.EDIT:
              setAssignmentToEdit(null);
              break;
            default:
          }
        }}
      />

      <TaskModals
        assignmentToComplete={
          assignmentToComplete &&
          assignmentToComplete.entityTypeId === EntityType.Task
            ? assignmentToComplete
            : null
        }
        assignmentToDelete={
          assignmentToDelete &&
          assignmentToDelete.entityTypeId === EntityType.Task
            ? assignmentToDelete
            : null
        }
        assignmentToEdit={
          assignmentToEdit && assignmentToEdit.entityTypeId === EntityType.Task
            ? assignmentToEdit
            : null
        }
        assignmentToReassign={
          assignmentToReassign &&
          assignmentToReassign.entityTypeId === EntityType.Task
            ? assignmentToReassign
            : null
        }
        onClose={(
          editedTask: ITask | undefined,
          modalAction: TaskModalActionEnums
        ) => {
          if (editedTask) {
            setIsLoading(true);
          }

          switch (modalAction) {
            case TaskModalActionEnums.COMPLETE:
              setAssignmentToComplete(null);
              break;
            case TaskModalActionEnums.DELETE:
              setAssignmentToDelete(null);
              break;
            case TaskModalActionEnums.EDIT:
              setAssignmentToEdit(null);
              break;
            case TaskModalActionEnums.REASSIGN:
              setAssignmentToReassign(null);
              break;
            default:
          }
        }}
      />

      {assignmentToUpdateFollowers && (
        <FollowersModal
          engagementGuid={assignmentToUpdateFollowers.engagementGuid}
          instructions={getTextToDisplay('followersModal.instructions', {
            context:
              Object.values(EntityType)[
                assignmentToUpdateFollowers.entityTypeId - 1
              ]
          })}
          onClose={() => {
            setAssignmentToUpdateFollowers(null);
          }}
          onUpdate={() => {
            setAssignmentToUpdateFollowers(null);
            setIsLoading(true);
          }}
          open
          queueItemGuid={assignmentToUpdateFollowers.queueItemGuid}
          title={getTextToDisplay('followersModal.title', {
            context:
              Object.values(EntityType)[
                assignmentToUpdateFollowers.entityTypeId - 1
              ]
          })}
          userTypeId={activeUserProfile.userTypeId}
        />
      )}
    </div>
  );
};

export default WorklistOpen;
