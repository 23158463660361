import {
  IGetEditActionsForDataRequests,
  IGetNonEditActionsForDataRequests
} from './DataRequestActions.interfaces';

import { Button, SplitButton } from '@ryan/components';

import { Status } from '../../../../../../interfaces';
import { DataRequestTypeGuidEnum } from '../../../../../../utils/enums/DataRequestsEnums';

export const getEditActionsForDataRequests: IGetEditActionsForDataRequests = ({
  defaultButtonProps,
  details,
  hasDataRequestContributePermission,
  optionsMapper
}) => {
  let hasAssignDataUploadOption = false;
  let hasMarkDataDeliveredOption = false;

  switch (details.statusId) {
    case Status.InProgress:
      hasAssignDataUploadOption = Boolean(details.isMarkedAsDataDelivered);
      hasMarkDataDeliveredOption =
        hasDataRequestContributePermission && !hasAssignDataUploadOption;
      break;
    default:
  }

  const splitButtonOptions = [
    optionsMapper.editOption,
    optionsMapper.completeOption,
    optionsMapper.deleteOption
  ];

  if (hasMarkDataDeliveredOption) {
    splitButtonOptions.splice(2, 0, optionsMapper.markDataDeliveredOption);
  }

  if (hasAssignDataUploadOption) {
    splitButtonOptions.splice(2, 0, optionsMapper.assignDataUploadOption);
  }

  return {
    actionButtonProps: { ...defaultButtonProps, options: splitButtonOptions }
  };
};

export const getNonEditActionsForDataRequests: IGetNonEditActionsForDataRequests =
  ({
    defaultButtonProps,
    details,
    hasDataRequestContributePermission,
    isActiveUserTypeRyan,
    isDisabled,
    optionsMapper
  }) => {
    let actionButtonText = 'view';
    let hasMarkDataDeliveredOption = false;
    let isTransferData = false;

    if (hasDataRequestContributePermission && !isDisabled) {
      if (isActiveUserTypeRyan) {
        actionButtonText = 'review';
      }

      switch (details.statusId) {
        case Status.Todo:
          isTransferData = !(
            details.typeGuid === DataRequestTypeGuidEnum.DOCUMENT_IMAGES &&
            details.codeAssistance === 'yes'
          );
          break;
        case Status.InProgress:
          hasMarkDataDeliveredOption = !Boolean(
            details.isMarkedAsDataDelivered
          );
          isTransferData = hasMarkDataDeliveredOption;
          break;
        default:
      }
    }

    const isSplitButton = hasMarkDataDeliveredOption;
    const options = isSplitButton
      ? [optionsMapper.markDataDeliveredOption]
      : [];

    if (isTransferData && !isActiveUserTypeRyan) {
      actionButtonText = 'transferData';
    }

    return {
      actionButtonProps: {
        ...defaultButtonProps,
        options
      },
      actionButtonText,
      ActionButtonToRender: isSplitButton ? SplitButton : Button
    };
  };
