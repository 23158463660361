import {
  EngagementAutocomplete,
  UserAutocomplete
} from 'components/AutocompleteAjax';
import CommentInput from 'components/Comments/CommentInput';
import Datepicker from 'components/Datepicker';
import ManageAttachments from 'components/ManageAttachments';
import Modal from 'components/Modal';
import UploadModalWarning from 'components/UploadModalWarning/UploadModalWarning';
import EngagementContext from 'contexts/EngagementContext';
import { WithUser, withUser } from 'contexts/UserContext';
import {
  IAttachmentUpdates,
  IEngagementSummary,
  IMilestone,
  ITask,
  ITaskValues,
  IUserSummary,
  Permission,
  UserType
} from 'interfaces';
import isEqual from 'lodash.isequal';
import ApiService, { CancelTokenSource } from 'services/ApiService';
import { DSSManager } from 'utils/DSS';
import {
  Formik,
  FormikProps,
  formikAutocompleteAjaxProps,
  formikCheckboxProps,
  formikCommentProps,
  formikDatepickerProps,
  formikFieldProps,
  yup
} from 'utils/forms';
import pushServerErrorToast from 'utils/pushServerErrorToast';

import { startOfDay } from 'date-fns';
import React, { Component, createRef } from 'react';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';

import {
  Button,
  ButtonGroup,
  Checkbox,
  Dropdown,
  EButtonVariant,
  MentionsValue,
  Message,
  TextInput,
  Textarea,
  pushToast
} from '@ryan/components';

interface ITaskModalFormValues {
  title: string;
  description: string;
  engagement: IEngagementSummary | null;
  assignedToUser: IUserSummary | null;
  dueDate: Date | null;
  engagementMilestoneGuid: string;
  comment: MentionsValue;
  attachments: IAttachmentUpdates;
  isCurrentUserWatching: boolean;
}

/**
 * The earliest due date should be today, unless we are editing
 * a Task whose due date has passed.
 * @param task
 */
function minDueDate(task?: ITask): Date {
  const today = startOfDay(new Date());
  const taskDueDate = task && startOfDay(task.dueDate);
  return taskDueDate && taskDueDate < today ? taskDueDate : today;
}

/**
 * Inner
 */

interface ITaskModalInnerProps
  extends WithTranslation,
    WithUser,
    Omit<ITaskModalProps, 'open'> {
  dss: DSSManager;
  formik: FormikProps<ITaskModalFormValues>;
  updateInitialValues: (values: Record<string, unknown>) => void;
}

interface ITaskModalInnerState {
  engagementsFetching: boolean;
  usersFetching: boolean;
  milestones: IMilestone[] | null;
  error?: React.ReactNode;
}

class TaskModalInner extends Component<
  ITaskModalInnerProps,
  ITaskModalInnerState
> {
  private _isMounted = false;

  private errorContainerRef = createRef<HTMLDivElement>();
  private formRef = createRef<HTMLFormElement>();

  // axios cancel token source for initialization requests
  private sourceInit?: CancelTokenSource;

  // axios cancel token source for milestone requests
  private sourceMilestones?: CancelTokenSource;

  // axios cancel token source for user requests
  private sourceUsers?: CancelTokenSource;

  constructor(props: ITaskModalInnerProps) {
    super(props);

    this.state = {
      engagementsFetching: false,
      usersFetching: false,
      milestones: null
    };
  }

  componentDidMount() {
    this.tryFetch();
  }

  componentDidUpdate(prevProps: ITaskModalInnerProps) {
    const { engagementGuid, task } = this.props;
    const { engagementGuid: prevEngagementGuid, task: prevTask } = prevProps;

    // if (engagementGuid !== prevEngagementGuid || task !== prevTask) {
    if (engagementGuid !== prevEngagementGuid || !isEqual(prevTask, task)) {
      this.tryFetch();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;

    // cancel any ongoing requests
    this.sourceInit?.cancel();
    this.sourceMilestones?.cancel();
    this.sourceUsers?.cancel();
  }

  fetchMilestones(engagementGuid: string) {
    // cancel any previous requests and save new token for current request
    this.sourceMilestones?.cancel(
      'cancelling previous TaskModalInner milestone fetch'
    );
    this.sourceMilestones = ApiService.CancelToken.source();

    ApiService.getMilestones(engagementGuid, this.sourceMilestones?.token)
      .then(({ data: milestones }) => {
        const { formik, task } = this.props;
        const engagementMilestoneGuid = task
          ? task.engagementMilestoneGuid
          : this.props.engagementMilestoneGuid;

        this.setState({ milestones });

        // if we are passed an engagementMilestoneGuid
        // and it exists in our options
        // then set the form field value
        if (
          engagementMilestoneGuid &&
          milestones.some(
            m => m.engagementMilestoneGuid === engagementMilestoneGuid
          )
        ) {
          formik.setFieldValue(
            'engagementMilestoneGuid',
            engagementMilestoneGuid
          );
        }
      })
      .catch(error => {
        if (!ApiService.isCancel(error)) {
          pushServerErrorToast();
        }
      });
  }

  getDropdownOptions() {
    const { milestones } = this.state;
    return [
      {
        label: '',
        value: ''
      },
      ...(milestones || []).map(milestone => ({
        label: milestone.title,
        value: milestone.engagementMilestoneGuid
      }))
    ];
  }

  // FOR MODAL //

  handleEngagementChange = (engagement: IEngagementSummary | null) => {
    const { formik } = this.props;

    // Set the new engagment, clear selected milestone and assiged user.
    formik.setValues({
      ...formik.values,
      engagement,
      engagementMilestoneGuid: '',
      assignedToUser: null
    });

    // Clear milestone options.
    this.setState({ milestones: null });

    if (
      engagement &&
      this.props.permissionService.hasPermission(Permission.TimelinesView)
    ) {
      this.fetchMilestones(engagement.engagementGuid);
    }
  };

  handleFetchAssignableUsers = async (query: string) => {
    const { engagement } = this.props.formik.values;

    if (engagement) {
      // cancel any previous requests and save new token for current request
      this.sourceUsers?.cancel(
        'cancelling previous TaskModalInner users fetch'
      );
      this.sourceUsers = ApiService.CancelToken.source();

      try {
        const response = await ApiService.getEngagementTaskAssignableUsers(
          engagement.engagementGuid,
          query,
          this.sourceUsers?.token
        );
        return response.data;
      } catch (error) {
        if (!ApiService.isCancel(error)) {
          pushServerErrorToast();
        }
      }
    }

    return [];
  };

  async tryFetchForEngagementGuid(engagementGuid: string) {
    const {
      engagementMilestoneGuid,
      formik,
      permissionService,
      updateInitialValues
    } = this.props;

    this.setState({ engagementsFetching: true });

    this.sourceInit?.cancel(
      'cancelling previous TaskModalInner initialization fetch'
    );
    this.sourceMilestones?.cancel(
      'cancelling previous TaskModalInner milestone fetch'
    );
    this.sourceInit = ApiService.CancelToken.source();
    this.sourceMilestones = ApiService.CancelToken.source();

    try {
      const { data: engagement } = await ApiService.getEngagement(
        engagementGuid,
        this.sourceInit?.token
      );
      const canReadMilestones = permissionService.hasPermission(
        Permission.TimelinesView
      );
      const { data: milestones } = canReadMilestones
        ? await ApiService.getMilestones(
            engagementGuid,
            this.sourceMilestones?.token
          )
        : { data: [] };

      this.setState({ milestones });
      formik.setFieldValue('engagement', engagement);

      const hasAssociatedEngagementMilestoneGuid =
        engagementMilestoneGuid &&
        milestones.some(
          milestone =>
            milestone.engagementMilestoneGuid === engagementMilestoneGuid
        );

      updateInitialValues({
        ...(hasAssociatedEngagementMilestoneGuid && {
          engagementMilestoneGuid
        }),
        engagement
      });
    } catch (error) {
      if (!ApiService.isCancel(error)) {
        pushServerErrorToast();
      }
    } finally {
      if (this._isMounted) {
        this.setState({ engagementsFetching: false });
      }
    }
  }

  async tryFetchForTask(task: ITask) {
    const { permissionService, updateInitialValues } = this.props;
    const {
      assignedToUserGuid: taskAssignedToUserGuid,
      engagementGuid: taskEngagementGuid,
      engagementMilestoneGuid: taskEngagementMilestoneGuid
    } = task;

    this.setState({ engagementsFetching: true, usersFetching: true });

    this.sourceInit?.cancel(
      'cancelling previous TaskModalInner initialization fetch'
    );
    this.sourceMilestones?.cancel(
      'cancelling previous TaskModalInner milestone fetch'
    );
    this.sourceInit = ApiService.CancelToken.source();
    this.sourceMilestones = ApiService.CancelToken.source();

    try {
      const [{ data: engagement }, { data: assignedToUser }] =
        await Promise.all([
          ApiService.getEngagement(taskEngagementGuid, this.sourceInit?.token),
          ApiService.getUser(taskAssignedToUserGuid, this.sourceInit?.token)
        ]);
      const canReadMilestones = permissionService.hasPermission(
        Permission.TimelinesView
      );
      const { data: milestones } = canReadMilestones
        ? await ApiService.getMilestones(
            taskEngagementGuid,
            this.sourceMilestones?.token
          )
        : { data: [] };

      this.setState({ milestones });

      const hasAssociatedEngagementMilestoneGuid =
        taskEngagementMilestoneGuid &&
        milestones.some(
          milestone =>
            milestone.engagementMilestoneGuid === taskEngagementMilestoneGuid
        );

      updateInitialValues({
        ...(hasAssociatedEngagementMilestoneGuid && {
          engagementMilestoneGuid: taskEngagementMilestoneGuid
        }),
        assignedToUser: {
          firstName: assignedToUser?.firstName,
          fullName: assignedToUser?.fullName,
          lastName: assignedToUser?.lastName,
          title: assignedToUser?.title,
          userGuid: assignedToUser?.userGuid
        },
        engagement: {
          ...engagement,
          clientName: engagement.accountName
        }
      });
    } catch (error) {
      if (!ApiService.isCancel(error)) {
        pushServerErrorToast();
      }
    } finally {
      if (this._isMounted) {
        this.setState({ engagementsFetching: false, usersFetching: false });
      }
    }
  }

  tryFetch() {
    const { engagementGuid, task } = this.props;

    if (task) {
      // Editing Task
      this.tryFetchForTask(task);
    } else if (engagementGuid) {
      // Creating a new task
      this.tryFetchForEngagementGuid(engagementGuid);
    }
  }

  render() {
    const {
      t,
      dss,
      activeView,
      engagementGuid,
      permissionService: ps,
      formik,
      task,
      onClose
    } = this.props;
    const { milestones, engagementsFetching, usersFetching } = this.state;
    const isNew = typeof task === 'undefined';
    const showExtended =
      task === undefined ||
      ps.isRyan() ||
      (ps.isClient() && task.createdByUserType === UserType.Client);

    const viewMilestonesAllowed = ps.hasPermission(Permission.TimelinesView);

    return (
      <>
        <div ref={this.errorContainerRef} />
        <form
          autoComplete="off"
          onSubmit={formik.handleSubmit}
          ref={this.formRef}
        >
          {showExtended && (
            <TextInput
              {...formikFieldProps('title', formik)}
              label={t('task.modal.fields.title.label')}
            />
          )}
          {showExtended && (
            <Textarea
              {...formikFieldProps('description', formik)}
              label={t('task.modal.fields.description.label')}
              maxLength={maxLengthDescription}
            />
          )}
          {showExtended && (
            <EngagementAutocomplete
              {...formikAutocompleteAjaxProps('engagement', formik)}
              customViewGuid={activeView.customViewGuid}
              disabled={
                engagementGuid !== undefined || !isNew || engagementsFetching
              }
              label={t('task.modal.fields.engagementGuid.label')}
              onChange={this.handleEngagementChange}
            />
          )}
          <UserAutocomplete
            {...formikAutocompleteAjaxProps(
              'assignedToUser',
              formik,
              (name, formik, ...args) => {
                const [user] = args;
                formik.setFieldValue(
                  name,
                  user
                    ? {
                        userGuid: user?.userGuid,
                        firstName: user?.firstName,
                        lastName: user?.lastName,
                        title: user?.title,
                        fullName: user?.fullName
                      }
                    : null
                );
              }
            )}
            disabled={formik.values.engagement === null || usersFetching}
            label={t('task.modal.fields.assignedToUserGuid.label')}
            onFetchOptions={this.handleFetchAssignableUsers}
          />
          {showExtended && (
            <Datepicker
              {...formikDatepickerProps('dueDate', formik)}
              label={t('task.modal.fields.dueDate.label')}
              minDate={minDueDate(task)}
            />
          )}

          <ManageAttachments
            attachments={task?.attachments || []}
            canDeleteAttachment={file => ps.canRemoveTaskAttachment(file)}
            dss={dss}
            engagementGuid={
              task?.engagementGuid ||
              formik.values.engagement?.engagementGuid ||
              null
            }
            engagementName={
              task?.engagementDisplayNameShort ||
              formik.values.engagement?.engagementDisplayNameShort ||
              null
            }
            onChange={updates => formik.setFieldValue('attachments', updates)}
            value={formik.values.attachments}
          />

          {showExtended && viewMilestonesAllowed && (
            <Dropdown
              label={t('task.modal.fields.milestone.label')}
              options={this.getDropdownOptions()}
              {...formikFieldProps('engagementMilestoneGuid', formik)}
              disabled={!milestones || milestones.length === 0}
            />
          )}
          <CommentInput
            {...formikCommentProps('comment', formik)}
            boundingParentRef={this.formRef}
            disabled={formik.values.engagement === null}
            engagementGuid={
              formik.values.engagement
                ? formik.values.engagement.engagementGuid
                : ''
            }
            label={t('task.modal.fields.comment.label')}
          />
          <Checkbox
            {...formikCheckboxProps('isCurrentUserWatching', formik)}
            label={t('Follow', { context: 'task' })}
            value="isCurrentUserWatching"
          />
          <ButtonGroup>
            <Button
              disabled={!formik.dirty}
              loading={formik.status.loading}
              text={t(isNew ? 'Create' : 'Save')}
              type="submit"
              variant={EButtonVariant.PRIMARY}
            />
            <Button
              disabled={formik.status.loading}
              onClick={() => onClose()}
              text={t('Cancel')}
            />
          </ButtonGroup>
        </form>
      </>
    );
  }
}

const TaskModalInnerWrapped = withUser(withTranslation()(TaskModalInner));

/**
 * Outer
 */

interface ITaskModalProps extends WithTranslation {
  task?: ITask;
  open?: boolean;
  onClose: (task?: ITask) => void;
  engagementGuid?: string;
  engagementMilestoneGuid?: string;
}

type ITaskModalState = {
  initialValues: ITaskModalFormValues;
  schema?: yup.ObjectSchema;
};

const defaultValues: ITaskModalFormValues = {
  title: '',
  description: '',
  engagement: null,
  assignedToUser: null,
  dueDate: null,
  engagementMilestoneGuid: '',
  attachments: {
    addUploaded: [],
    deleteAttachments: [],
    addExisting: []
  },
  comment: new MentionsValue(),
  isCurrentUserWatching: true
};

const maxLengthComment = 250;
const maxLengthDescription = 600;
const maxLengthTitle = 50;

export class TaskModal extends Component<ITaskModalProps, ITaskModalState> {
  // axios cancel token source
  private source?: CancelTokenSource;

  static contextType = EngagementContext;
  context!: React.ContextType<typeof EngagementContext>;

  private dss = new DSSManager({ onChange: () => this.forceUpdate() });

  readonly state: ITaskModalState = {
    initialValues: defaultValues
  };

  componentDidMount() {
    this.updateSchema();
    this.updateInitialValues();
  }

  componentDidUpdate(prevProps: ITaskModalProps) {
    const { task, t } = this.props;
    const { task: prevTask, t: prevT } = prevProps;
    const taskHasChanged = !isEqual(prevTask, task);

    if (taskHasChanged || t !== prevT) {
      this.updateSchema();

      if (taskHasChanged) {
        this.updateInitialValues();
      }
    }
  }

  componentWillUnmount() {
    // cancel any ongoing requests
    this.source?.cancel();
  }

  handleClose = (task?: ITask) => {
    this.dss.clearUploads();
    this.props.onClose(task);
  };

  handleSubmit = async (values: ITaskModalFormValues, formik: any) => {
    const { t, task, engagementMilestoneGuid } = this.props;
    const { setStatus } = formik;
    const isNew = typeof task === 'undefined';
    const taskFormValues: ITaskValues = {
      title: values.title,
      description: values.description,
      engagementGuid: values.engagement!.engagementGuid,
      engagementMilestoneGuid: values.engagementMilestoneGuid || null,
      assignedToUserGuid: values.assignedToUser!.userGuid,
      dueDate: values.dueDate!.toISOString(),
      comment: values.comment.toJSON().text,
      attachments: values.attachments,
      fromMilestones:
        engagementMilestoneGuid !== undefined &&
        values.engagementMilestoneGuid !== '',
      isCurrentUserWatching: values.isCurrentUserWatching
    };

    // cancel any previous requests and save new token for current request
    this.source?.cancel('cancelling previous TaskModal fetch');
    this.source = ApiService.CancelToken.source();

    // determine whether or not the user is creating
    // a new Task or editing an existing Task
    const submitPromise = isNew
      ? ApiService.createTask(taskFormValues, this.source?.token)
      : ApiService.updateTask(task!, taskFormValues, this.source?.token);

    setStatus({ loading: submitPromise });

    try {
      const response = await submitPromise;
      const updatedTask = response.data;
      const { title } = updatedTask;

      pushToast({
        type: 'success',
        title: t(
          isNew
            ? 'task.modal.new.success.title'
            : 'task.modal.edit.success.title'
        ),
        content: (
          <Trans
            i18nKey={
              isNew
                ? 'task.modal.new.success.content'
                : 'task.modal.edit.success.content'
            }
          >
            <b>{{ title }}</b> has been successfully saved.
          </Trans>
        )
      });
      setStatus({ loading: null });
      this.context.refreshUpdateDate?.(taskFormValues.engagementGuid);
      this.handleClose(updatedTask);
    } catch (error) {
      if (!ApiService.isCancel(error)) {
        setStatus({
          loading: null,
          error: (
            <Message title={t('serverError.title')} type="error">
              {t('serverError.content')}
            </Message>
          )
        });
      }
    }
  };

  /**
   * Parses the initial form values from a task.
   */
  updateInitialValues = (values = {}): void => {
    const { task } = this.props;

    this.setState({
      initialValues: task
        ? {
            ...defaultValues,
            description: task.description,
            dueDate: task.dueDate,
            isCurrentUserWatching: task.isCurrentUserWatching,
            title: task.title,
            ...values
          }
        : {
            ...defaultValues,
            ...values
          }
    });
  };

  /**
   * Updates the task form validation schema.
   */
  updateSchema = (): void => {
    const { task, t } = this.props;
    this.setState({
      schema: yup.object({
        title: yup
          .string()
          .trim()
          .required(this.props.t('task.modal.fields.title.required'))
          .max(
            maxLengthTitle,
            t('task.modal.fields.title.maxLength', { length: maxLengthTitle })
          ),
        description: yup.string().max(
          maxLengthDescription,
          t('task.modal.fields.description.maxLength', {
            length: maxLengthDescription
          })
        ),
        engagement: yup
          .object()
          .nullable()
          .required(t('task.modal.fields.engagementGuid.required')),
        assignedToUser: yup
          .object()
          .nullable()
          .required(t('task.modal.fields.assignedToUserGuid.required')),
        dueDate: yup
          .date()
          .nullable()
          .required(t('task.modal.fields.dueDate.required'))
          .min(
            minDueDate(task),
            task
              ? t('task.modal.fields.dueDate.minCreated')
              : t('task.modal.fields.dueDate.minToday')
          ),
        engagementMilestoneGuid: yup.string(),
        comment: yup.mixed().validateCommentLength(
          maxLengthComment,
          t('task.modal.fields.comment.maxLength', {
            length: maxLengthComment
          })
        ),
        isCurrentUserWatching: yup.boolean()
      })
    });
  };

  render() {
    const {
      engagementGuid,
      engagementMilestoneGuid,
      open = true,
      task,
      t
    } = this.props;
    const { initialValues, schema } = this.state;
    const isNew = typeof task === 'undefined';

    return (
      <UploadModalWarning dss={this.dss} onClose={this.handleClose}>
        {({ dss, warning, onEscape, onCancel }) => (
          <Modal
            onClose={onEscape}
            open={open}
            title={t(isNew ? 'task.modal.new.title' : 'task.modal.edit.title')}
          >
            {warning}
            <Formik
              enableReinitialize
              initialStatus={{ loading: null }}
              initialValues={initialValues}
              onSubmit={this.handleSubmit}
              validationSchema={schema}
            >
              {formik => (
                <TaskModalInnerWrapped
                  dss={dss}
                  engagementGuid={engagementGuid}
                  engagementMilestoneGuid={engagementMilestoneGuid}
                  formik={formik}
                  onClose={onCancel}
                  task={task}
                  updateInitialValues={this.updateInitialValues}
                />
              )}
            </Formik>
          </Modal>
        )}
      </UploadModalWarning>
    );
  }
}

export default withTranslation()(TaskModal);
