// enum CommonColumnEnums {}
/**
 * NOTE: Common enums, defined as appearing 3 or more times,
 *     should be defined here and referenced below
 * NOTE: CommonColumnEnums should not be exported
 */

export enum WorklistColumnEnums {
  ACTION = 'action',
  ASSIGNED_TO = 'assignedTo',
  COMMENT = 'comment',
  DUE_DATE = 'dueDate',
  PROJECT = 'engagementDisplayNameShort',
  STATUS = 'status',
  TITLE = 'title',
  TYPE = 'entityType'
}
