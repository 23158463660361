import { IGetCommentProps } from './useWorkListColumns.interfaces';

import { EButtonSizes, EButtonVariant } from '@ryan/components';

import { IconEnums } from '../../../../utils/enums/IconEnums';
import { WorklistColumnEnums } from '../columns.enums';

export const getCommentProps: IGetCommentProps = ({
  commentCount,
  isReadOnlyByPermissions,
  isReadOnlyByState
}) => {
  const hasComments = commentCount > 0;
  const icon = hasComments ? IconEnums.COMMENT_ALERT : IconEnums.COMMENT;
  let path = hasComments ? 'commentsWithCount' : 'addAComment';

  if (isReadOnlyByState || isReadOnlyByPermissions) {
    path = 'viewCommentsWithCount';
  }

  return { icon, path };
};

export const tooltipProps = {
  button: {
    'aria-haspopup': true,
    className: 'comment-button',
    size: EButtonSizes.SMALL,
    variant: EButtonVariant.TEXT
  },
  tooltip: { placement: 'top' as any }
};

export const worklistColumnTemplate = [
  {
    id: WorklistColumnEnums.TITLE,
    label: WorklistColumnEnums.TITLE,
    sortable: true
  },
  {
    id: WorklistColumnEnums.PROJECT,
    label: WorklistColumnEnums.PROJECT,
    sortable: true
  },
  {
    id: WorklistColumnEnums.TYPE,
    label: WorklistColumnEnums.TYPE,
    sortable: true
  },
  {
    id: WorklistColumnEnums.STATUS,
    label: WorklistColumnEnums.STATUS,
    sortable: true
  },
  {
    id: WorklistColumnEnums.ASSIGNED_TO,
    label: WorklistColumnEnums.ASSIGNED_TO,
    sortable: true
  },
  {
    id: WorklistColumnEnums.DUE_DATE,
    label: WorklistColumnEnums.DUE_DATE,
    sortable: true
  },
  { id: WorklistColumnEnums.COMMENT },
  { id: WorklistColumnEnums.ACTION }
];
