import { WithDownload, withDownload } from 'contexts/DownloadContext';
import { IFile } from 'interfaces';
import { DirectoryItemStatus } from 'routes/DataAndFiles/Files/Files';

import classnames from 'classnames';
import React, { FunctionComponent, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, EButtonVariant, Icon, Tooltip } from '@ryan/components';

import './FileLink.scss';

export interface IFileLinkProps {
  engagementGuid: string;
  file: IFile;
  fileName?: ReactNode;
  fileDescription?: ReactNode;
  icon?: string;
  isDownloadingDisabled?: boolean;
  status?: DirectoryItemStatus;
}

const FileLink: FunctionComponent<IFileLinkProps & WithDownload> = ({
  engagementGuid,
  file,
  fileName = file.displayName,
  fileDescription,
  icon,
  isDownloadingDisabled,
  status,
  onDownloadFiles
}) => {
  const { t } = useTranslation();

  const className = classnames('bs bs--light file-link', {
    'bs--icon': icon !== undefined
  });

  if (file.isReady) {
    return (
      <div className="file-link-name">
        <Button
          className={className}
          disabled={isDownloadingDisabled}
          onClick={() => onDownloadFiles([file], engagementGuid)}
          variant={EButtonVariant.LINK}
        >
          {icon && <Icon name={icon} />}
          {status !== DirectoryItemStatus.ACTIVE && file.archiveDate ? (
            <i>
              <b>{fileName}</b>
            </i>
          ) : (
            <b>{fileName}</b>
          )}

          {fileDescription && <small>{fileDescription}</small>}
        </Button>
        {status !== DirectoryItemStatus.ACTIVE && file.archiveDate && (
          <div className="pill archived">{t('Archived')}</div>
        )}
      </div>
    );
  }

  return (
    <Tooltip
      content={t('file.processingTooltip')}
      placement="top-start"
      renderTarget={({ open, ref, ...props }) => (
        <Button
          aria-expanded={open}
          aria-haspopup="true"
          className={className}
          innerRef={ref}
          variant={EButtonVariant.LINK}
          {...props}
        >
          {icon && <Icon name={icon} />}
          <b>{fileName}</b>
          <small style={{ fontStyle: 'italic' }}>{t('file.processing')}</small>
        </Button>
      )}
    />
  );
};

export default withDownload(FileLink);
