import { TDataRequestStatusProps } from './DataRequestStatus.interfaces';

import classnames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, EButtonSizes, EButtonVariant } from '@ryan/components';

import StatusIcon from '../../../../../../components/StatusIcon/StatusIcon';
import { useUser } from '../../../../../../hooks';
import { isPastDue } from '../../../../../../utils/isPastDue';

import './DataRequestStatus.scss';

const DataRequestStatus = ({
  dataRequestDetails,
  isDisabled,
  onOpenFollowersModal,
  onToggleFollow
}: TDataRequestStatusProps) => {
  const ROOT_TO_TEXT =
    'table.expandedRow.worklist.dataRequestExpandedRow.status';
  const {
    assignedToDetails,
    createdByDetails,
    dueDate,
    followerCount,
    isFollowing,
    requestedDate,
    statusId,
    subStatusId,
    transferredFileCount
  } = dataRequestDetails;

  const { t: getTextToDisplay } = useTranslation();
  const { user: activeUser } = useUser();

  const details = [
    {
      detail:
        assignedToDetails.userGuid === activeUser.profile.memberGuid
          ? getTextToDisplay(`${ROOT_TO_TEXT}.assignedToYou`)
          : assignedToDetails.name,
      label: 'assignedTo'
    },
    {
      detail: dueDate,
      label: 'dueDate'
    },
    {
      detail:
        createdByDetails.userGuid === activeUser.profile.memberGuid
          ? getTextToDisplay(`${ROOT_TO_TEXT}.requestedByYou`)
          : createdByDetails.name,
      label: 'requestedBy'
    },
    {
      detail: requestedDate,
      label: 'requestedDate'
    },
    {
      label: 'followers'
    },
    ...(transferredFileCount > 0
      ? [
          {
            detail: transferredFileCount,
            label: 'transferredFiles'
          }
        ]
      : [])
  ];

  return (
    <div className="data-request-status">
      <div
        className={classnames('well', {
          'well--past-due': isPastDue(new Date(dueDate), new Date())
        })}
      >
        <StatusIcon
          {...(subStatusId && { subStatus: subStatusId })}
          status={statusId}
        />

        <div className="data-request-status__details">
          <ul>
            {details.map(({ detail, label }) => (
              <li key={label}>
                <label>{getTextToDisplay(`${ROOT_TO_TEXT}.${label}`)}</label>

                {label !== 'followers' ? (
                  <span
                    className={classnames({
                      'data-request-status__details__due-date':
                        label === 'dueDate'
                    })}
                  >
                    {detail}
                  </span>
                ) : (
                  <>
                    <Button
                      disabled={isDisabled}
                      onClick={onToggleFollow}
                      size={EButtonSizes.SMALL}
                      variant={EButtonVariant.TEXT}
                    >
                      {getTextToDisplay(
                        `${ROOT_TO_TEXT}.${isFollowing ? 'unfollow' : 'follow'}`
                      )}
                    </Button>
                    •
                    <Button
                      disabled={isDisabled}
                      onClick={onOpenFollowersModal}
                      size={EButtonSizes.SMALL}
                      variant={EButtonVariant.TEXT}
                    >
                      {getTextToDisplay(`${ROOT_TO_TEXT}.followersWithCount`, {
                        count: followerCount
                      })}
                    </Button>
                  </>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default DataRequestStatus;
