import * as yup from 'yup';

import { FormikProps } from 'formik';
import React from 'react';

import { MentionsValue, PasswordValidator } from '@ryan/components';

/**
 * Yup
 */

declare module 'yup' {
  interface MixedSchema {
    validateCommentLength(
      maxLength: number,
      errorMessage?: string
    ): MixedSchema;
  }

  interface StringSchema {
    equalTo(ref: yup.Ref, message: string): StringSchema;
    password(validator: typeof PasswordValidator): StringSchema;
  }
}

yup.addMethod<yup.StringSchema>(
  yup.string,
  'equalTo',
  function (this: yup.StringSchema, ref: yup.Ref, message: string) {
    return this.test('equalTo', message, function (value) {
      return value === this.resolve(ref);
    });
  }
);

yup.addMethod<yup.MixedSchema>(
  yup.mixed,
  'validateCommentLength',
  function (maxLength: number, errorMessage?: string) {
    const message: string =
      errorMessage ||
      `Comments cannot be greater than ${maxLength} characters.`;

    return this.test(
      'validateCommentLength',
      message,
      function (value: MentionsValue) {
        return value.length <= maxLength;
      }
    );
  }
);

yup.addMethod<yup.StringSchema>(
  yup.string,
  'password',
  function (validator: typeof PasswordValidator) {
    return this.test(
      'password',
      'password',
      (value: string) => validator(value) === undefined
    );
  }
);

export { yup };

/**
 * Formik
 */

export * from 'formik';

/**
 * Formik Adapters
 */

function formikValidation(name: string, formik: FormikProps<any>) {
  const { touched, error } = formik.getFieldMeta(name);
  return {
    invalid: touched && error !== undefined,
    feedback: error
  };
}

export function formikFieldProps(name: string, formik: FormikProps<any>) {
  const { value, onChange, onBlur } = formik.getFieldProps(name);
  return {
    name,
    value,
    onChange,
    onBlur,
    ...formikValidation(name, formik)
  };
}

/**
 * Formik Field Handler Factory
 */

function makePropsHandler(mapOnChange: (...args: any[]) => any) {
  return function (
    name: string,
    formik: FormikProps<any>,
    cb?: (...args: any[]) => any
  ) {
    return {
      ...formikFieldProps(name, formik),
      onChange(...args: any[]) {
        try {
          cb
            ? cb(name, formik, ...args)
            : formik.setFieldValue(name, mapOnChange(...args));
        } catch {
          // noop
        }
      }
    };
  };
}

/**
 * Number Input
 * https://stackoverflow.com/a/469362/517676
 */

export const formikNumberInputProps = makePropsHandler(
  (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (value === '') {
      return '0';
    }
    if (/^[0-9,]*[.]?[0-9]{0,2}$/.test(value)) {
      return value // replace leading zeros
        .replace(/^0*(\.)/, '0$1') // "00001" -> "1"
        .replace(/^0*([1-9])/, '$1') // "0000.0" -> "0.0"
        .replace(/,/, ''); // replace commas
    }
    throw new Error();
  }
);

const arg1 = makePropsHandler((x: any) => x);
const arg2 = makePropsHandler((_: any, x: any) => x);

export const formikAutocompleteProps = arg2;
export const formikAutocompleteAjaxProps = arg1;
export const formikCommentProps = arg1;
export const formikDatepickerProps = arg2;

/**
 * Checkbox
 */
export function formikCheckboxProps(name: string, formik: FormikProps<any>) {
  return {
    name,
    checked: formik.values[name],
    onChange: formik.handleChange,
    onBlur: formik.handleBlur,
    ...formikValidation(name, formik)
  };
}
