import { IFeatureToggle } from 'interfaces';
import ApiBase from './ApiBase';

export async function getFeatureToggles(this: ApiBase) {
  return this.server.get<IFeatureToggle[]>('/api/features');
}

export async function enableFeatureToggle(this: ApiBase, featureGuid: string) {
  return this.server.put<never>(`/api/features/enable/${featureGuid}`);
}

export async function disableFeatureToggle(this: ApiBase, featureGuid: string) {
  return this.server.put<never>(`/api/features/disable/${featureGuid}`);
}

export async function toggleFeatureToggle(
  this: ApiBase,
  featureGuid: string,
  isEnabled: boolean
) {
  if (isEnabled) {
    return enableFeatureToggle.call(this, featureGuid);
  }
  return disableFeatureToggle.call(this, featureGuid);
}
