import { CancelToken } from 'axios';

import {
  DSSDocument,
  IActivity,
  IDataRequest,
  IDataRequestComment,
  IDataRequestCreateV2,
  IDataRequestRequest,
  IDataRequestSearchParams,
  IDataRequestTypes,
  IDataRequestUploadFilesOnBehalfOfUserRequest,
  IDataRequestV2,
  IDataRequestV2UploadFiles,
  IDataRequestsCountByStatus,
  IDocumentTypes,
  IErpSystemTypes,
  IJurisdictions,
  IPagedDataResponse,
  ITaxEngineTypes,
  IUpcomingDataRequests,
  IUserSummary
} from '../../interfaces';
import { unwrapCommandResponse } from '../../utils/unwrapCommandResponse';
import ApiBase from './ApiBase';

//
//
// Data Requests
//
//

/**
 * GET Data Requests for a custom view
 */
export async function getCustomViewDataRequests(
  this: ApiBase,
  customViewGuid: string,
  params?: IDataRequestSearchParams,
  cancelToken?: CancelToken
) {
  return this.server.get<IPagedDataResponse<IDataRequest>>(
    `/api/customViews/${customViewGuid}/datarequests/search`,
    { cancelToken, params }
  );
}

/**
 * GET an Engagement's Data Requests
 */
export async function getEngagementDataRequests(
  this: ApiBase,
  engagementGuid: string,
  params?: IDataRequestSearchParams,
  cancelToken?: CancelToken
) {
  return this.server.get<IPagedDataResponse<IDataRequest>>(
    `/api/engagements/${engagementGuid}/datarequests/search`,
    { cancelToken, params }
  );
}

/**
 * GET assignable users for Data Requests on an Engagement
 */
export async function getEngagementDataRequestAssignableUsers(
  this: ApiBase,
  engagementGuid: string,
  query: string,
  cancelToken?: CancelToken
) {
  return this.server.get<IUserSummary[]>(
    `/api/engagements/${engagementGuid}/datarequests/assignableusers`,
    { cancelToken, params: { searchTerm: query } }
  );
}

/**
 * GET a Data Request
 */
export async function getDataRequest(
  this: ApiBase,
  dataRequestGuid: string,
  cancelToken?: CancelToken
) {
  // Not adding engagements to this route since we have an adjoining call once we get the response back that grabs the engagement itself.
  return this.server.get<IDataRequest>(`/api/datarequests/${dataRequestGuid}`, {
    cancelToken
  });
}

/**
 * GET a Data Request's Activity
 */
export async function getDataRequestActivity(
  this: ApiBase,
  engagementGuid: string,
  dataRequestGuid: string
) {
  return this.server.get<IActivity[]>(
    `/api/engagements/${engagementGuid}/datarequests/${dataRequestGuid}/activity`
  );
}

/**
 * GET a Data Request's Activity
 */
export async function getDataRequestActivityRestricted(
  this: ApiBase,
  engagementGuid: string,
  dataRequestGuid: string
) {
  return this.server.get<IActivity[]>(
    `/api/engagements/${engagementGuid}/datarequests/${dataRequestGuid}/activity/restricted`
  );
}

/**
 * Create a new Data Request
 */
export async function createDataRequest(
  this: ApiBase,
  dataRequest: IDataRequestRequest
) {
  return unwrapCommandResponse<IDataRequest>(
    this.server.post(
      `/api/engagements/${dataRequest.engagementGuid}/datarequests`,
      dataRequest
    )
  );
}

/**
 * Update a Data Request
 */
export async function updateDataRequest(
  this: ApiBase,
  dataRequest: IDataRequest,
  updates: IDataRequestRequest
) {
  return unwrapCommandResponse<IDataRequest>(
    this.server.put(
      `/api/engagements/${dataRequest.engagementGuid}/datarequests/${dataRequest.queueItemGuid}`,
      {
        title: dataRequest.title,
        description: dataRequest.description,
        assignedToUserGuid: dataRequest.assignedToUserGuid,
        dueDate: dataRequest.dueDate,
        dataSpecs: dataRequest.dataSpecs,
        statusId: dataRequest.statusId,
        isCurrentUserWatching: dataRequest.isCurrentUserWatching,
        ...updates
      }
    )
  );
}

/**
 * DELETE a Data Request
 */
export async function deleteDataRequest(
  this: ApiBase,
  engagementGuid: string,
  dataRequestGuid: string,
  deleteFiles?: boolean
) {
  return unwrapCommandResponse<IDataRequest>(
    this.server.delete(
      `/api/engagements/${engagementGuid}/datarequests/${dataRequestGuid}`,
      {
        params: {
          deleteFiles: deleteFiles ? 1 : undefined
        }
      }
    )
  );
}

//
//
// Upload Files
//
//

export async function uploadDataRequestFiles(
  this: ApiBase,
  engagementGuid: string,
  dataRequestGuid: string,
  documents: DSSDocument[]
) {
  return this.server.post(
    `/api/engagements/${engagementGuid}/datarequests/${dataRequestGuid}/documents`,
    { documents }
  );
}

export async function uploadDataRequestFilesOnBehalfOfUser(
  this: ApiBase,
  engagementGuid: string,
  dataRequestGuid: string,
  request: IDataRequestUploadFilesOnBehalfOfUserRequest
) {
  return unwrapCommandResponse(
    this.server.post(
      `/api/engagements/${engagementGuid}/datarequests/${dataRequestGuid}/documents/internal`,
      request
    )
  );
}

//
//
// Ad Hoc Data Request
//
//

export async function uploadAdHocFiles(
  this: ApiBase,
  engagementGuid: string,
  request: {
    title: string;
    description: string;
    comment: string;
    documents: DSSDocument[];
  }
) {
  return this.server.post<IDataRequest>(
    `/api/engagements/${engagementGuid}/datarequests/adhoc`,
    request
  );
}

//
//
// Data Request Comments
//
//

/**
 * Create a Data Request Comment
 */
export async function createDataRequestComment(
  this: ApiBase,
  engagementGuid: string,
  dataRequestGuid: string,
  comment: string
) {
  return unwrapCommandResponse<IDataRequestComment>(
    this.server.post(
      `/api/engagements/${engagementGuid}/datarequests/${dataRequestGuid}/comments`,
      {
        comment
      }
    )
  );
}

/**
 * GET a Data Request's Comments
 */
export async function getDataRequestComments(
  this: ApiBase,
  engagementGuid: string,
  dataRequestGuid: string
) {
  return this.server.get<IDataRequestComment[]>(
    `/api/engagements/${engagementGuid}/datarequests/${dataRequestGuid}/comments`
  );
}

/**
 * Update a Data Request Comment
 */
export async function updateDataRequestComment(
  this: ApiBase,
  engagementGuid: string,
  queueItemCommentGuid: string,
  commentText: string
) {
  return unwrapCommandResponse<IDataRequestComment>(
    this.server.put(
      `/api/engagements/${engagementGuid}/datarequests/comments/${queueItemCommentGuid}`,
      {
        comment: commentText
      }
    )
  );
}

/**
 * DELETE a Data Request Comment
 */
export async function deleteDataRequestComment(
  this: ApiBase,
  engagementGuid: string,
  queueItemCommentGuid: string
) {
  return unwrapCommandResponse<IDataRequestComment>(
    this.server.delete(
      `/api/engagements/${engagementGuid}/datarequests/comments/${queueItemCommentGuid}`
    )
  );
}

//
//
// Dashboards
//
//

export async function getUpcomingDataRequestsForCustomView(
  this: ApiBase,
  customViewGuid: string,
  cancelToken?: CancelToken
) {
  return this.server.get<IUpcomingDataRequests>(
    `/api/customViews/${customViewGuid}/datarequests/getupcoming`,
    { cancelToken }
  );
}

export async function getCustomViewDataRequestCount(
  this: ApiBase,
  customViewGuid: string,
  cancelToken?: CancelToken
) {
  return this.server.get<IDataRequestsCountByStatus>(
    `/api/customViews/${customViewGuid}/datarequests/findcountbystatus`,
    { cancelToken }
  );
}

export async function getEngagementDataRequestCount(
  this: ApiBase,
  engagementGuid: string,
  cancelToken?: CancelToken
) {
  return this.server.get<IDataRequestsCountByStatus>(
    `/api/engagements/${engagementGuid}/datarequests/findcountbystatus`,
    { cancelToken }
  );
}

//
//
// V2 Data requests
//
//

export async function getDataRequestTypes(this: ApiBase) {
  return this.server.get<IDataRequestTypes>(
    `api/datarequestsv2/dataRequestTypes`
  );
}

export async function getDocumentTypes(this: ApiBase) {
  return this.server.get<IDocumentTypes>(`api/datarequestsv2/documenttypes`);
}

export async function getErpSystemTypes(this: ApiBase, query?: string) {
  return this.server.get<IErpSystemTypes>(
    `api/datarequestsv2/erpsystems/${query}`
  );
}

export async function getTaxEngineTypes(this: ApiBase, query?: string) {
  return this.server.get<ITaxEngineTypes>(
    `api/datarequestsv2/taxengines/${query}`
  );
}

export async function getJurisdictionsV2(
  this: ApiBase,
  engagementGuid: string
) {
  return this.server.get<IJurisdictions>(
    `api/datarequestsv2/jurisdictions/engagements/${engagementGuid}`
  );
}

export async function createDataRequestV2(
  this: ApiBase,
  dataRequest: Partial<IDataRequestCreateV2>
) {
  return unwrapCommandResponse<Partial<IDataRequestCreateV2>>(
    this.server.post(`/api/datarequestsv2/`, dataRequest)
  );
}

export async function updateDataRequestV2(
  this: ApiBase,
  dataRequest: Partial<IDataRequestV2>
) {
  return unwrapCommandResponse<Partial<IDataRequestV2>>(
    this.server.post(`/api/datarequestsv2`, dataRequest)
  );
}

export async function updatePartialDataRequest(
  this: ApiBase,
  partialDataRequest: Partial<IDataRequestV2>
) {
  return unwrapCommandResponse<Partial<IDataRequestV2>>(
    this.server.put('/api/datarequestsv2/update', partialDataRequest)
  );
}

export async function createDataRequestV2Comment(
  this: ApiBase,
  engagementGuid: string,
  queueItemGuid: string,
  comment: string
) {
  return unwrapCommandResponse<IDataRequestComment>(
    this.server.post(
      `/api/datarequestsv2/comments/${queueItemGuid}/engagements/${engagementGuid}`,
      { comment }
    )
  );
}

export async function markDataRequestDataDelivered(
  this: ApiBase,
  partialDataRequest: Partial<IDataRequestV2>
) {
  return unwrapCommandResponse<Partial<IDataRequestV2>>(
    this.server.put('/api/datarequestsv2/markDataDelivered', partialDataRequest)
  );
}

export async function updateDataRequestV2Comment(
  this: ApiBase,
  engagementGuid: string,
  queueItemCommentGuid: string,
  comment: string
) {
  return unwrapCommandResponse<IDataRequestComment>(
    this.server.put(
      `/api/datarequestsv2/comments/${queueItemCommentGuid}/engagements/${engagementGuid}`,
      { comment }
    )
  );
}

export async function getDataRequestV2Comments(
  this: ApiBase,
  queueItemGuid: string
) {
  return this.server.get<IDataRequestComment[]>(
    `/api/datarequestsv2/comments/${queueItemGuid}`
  );
}

export async function deleteDataRequestV2Comment(
  this: ApiBase,
  engagementGuid: string,
  queueItemCommentGuid: string
) {
  return unwrapCommandResponse<IDataRequestComment>(
    this.server.delete(
      `/api/datarequestsv2/comments/${queueItemCommentGuid}/engagements/${engagementGuid}`
    )
  );
}

export async function uploadDataRequestsV2Files(
  this: ApiBase,
  request: IDataRequestV2UploadFiles
) {
  return unwrapCommandResponse(
    this.server.post(`/api/datarequestsv2/uploadfiles`, request)
  );
}

export async function getDataRequestV2(
  this: ApiBase,
  dataRequestGuid: string,
  cancelToken?: CancelToken
) {
  return this.server.get<IDataRequestV2>(
    `/api/datarequestsv2/${dataRequestGuid}`,
    {
      cancelToken
    }
  );
}
