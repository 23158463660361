import React, { PureComponent, createRef } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import {
  Button,
  ButtonGroup,
  EButtonSizes,
  EButtonVariant,
  EMessageTypes,
  MentionsValue,
  Message,
  Radio,
  RadioGroup,
  autocompleteHighlight,
  pushToast
} from '@ryan/components';

import { UserAutocomplete } from '../../components/AutocompleteAjax';
import CommentInput from '../../components/Comments/CommentInput';
import { makeOnSuggestionsRequested } from '../../components/Comments/CommentInput/CommentInput';
import ManageAttachments from '../../components/ManageAttachments';
import Modal from '../../components/Modal';
import UploadModalWarning from '../../components/UploadModalWarning/UploadModalWarning';
import {
  IAttachmentUpdates,
  IDataRequestV2,
  IDataRequestV2UploadFiles,
  IEngagement,
  IUserSummary,
  Status
} from '../../interfaces';
import ApiService from '../../services/ApiService';
import { DSSManager } from '../../utils/DSS';
import { SubStatusEnums } from '../../utils/enums/SubStatusEnums';
import {
  Formik,
  formikAutocompleteAjaxProps,
  formikCommentProps,
  formikFieldProps,
  yup
} from '../../utils/forms';

import './DataRequestRyanUploadModal.scss';

interface IDataRequestRyanUploadModalValues {
  attachments: IAttachmentUpdates;
  comment: MentionsValue;
  markAsDataDelivered: boolean | null;
  moreFilesWillBeUploaded: boolean | null;
  user: IUserSummary | null;
}

interface IDataRequestRyanUploadModalProps extends WithTranslation {
  engagement: IEngagement | null;
  dataRequest: IDataRequestV2 | null;
  open: boolean;
  onClose: (uploadedFiles: boolean) => void;
}

interface IDataRequestRyanUploadModalState {
  submitPromise: Promise<any> | null;
  message: React.ReactNode | null;
}

const initialValues: IDataRequestRyanUploadModalValues = {
  attachments: {
    addUploaded: [],
    deleteAttachments: [],
    addExisting: []
  },
  comment: new MentionsValue(),
  markAsDataDelivered: null,
  moreFilesWillBeUploaded: null,
  user: null
};

const maxLengthComment = 250;

class DataRequestRyanUploadModal extends PureComponent<
  IDataRequestRyanUploadModalProps,
  IDataRequestRyanUploadModalState
> {
  private dss = new DSSManager({ onChange: () => this.forceUpdate() });

  private errorContainerRef = createRef<HTMLDivElement>();
  private formRef = createRef<HTMLFormElement>();

  private schema: yup.ObjectSchema<IDataRequestRyanUploadModalValues>;

  constructor(props: IDataRequestRyanUploadModalProps) {
    super(props);

    const { t: getTextToDisplay } = props;

    this.schema = yup.object({
      attachments: yup.object<IAttachmentUpdates>(),
      comment: yup.mixed().validateCommentLength(
        maxLengthComment,
        getTextToDisplay('dataRequest.ryanUploadModal.commentMaxLength', {
          length: maxLengthComment
        })
      ),
      user: yup
        .object<IUserSummary>()
        .nullable()
        .required(getTextToDisplay('dataRequest.ryanUploadModal.userRequired')),

      markAsDataDelivered: yup.boolean(),
      moreFilesWillBeUploaded: yup.boolean()
    });

    this.state = {
      submitPromise: null,
      message: null
    };
  }

  async uploadFiles(
    dataRequest: IDataRequestV2,
    values: IDataRequestRyanUploadModalValues
  ) {
    const { t: getTextToDisplay } = this.props;

    const uploadRequest: IDataRequestV2UploadFiles = {
      attachmentsToUpsert: values.attachments,
      comment: values.comment.toJSON().text,
      engagementGuid: dataRequest.engagementGuid,
      markAsDataDelivered: values.markAsDataDelivered,
      moreFilesWillBeUploaded: values.moreFilesWillBeUploaded,
      onBehalfOfUserGuid: values.user!.userGuid,
      status: Status.InProgress,
      substatus: values.markAsDataDelivered
        ? SubStatusEnums.DataDelivered
        : null,
      queueItemGuid: dataRequest.queueItemGuid
    };

    try {
      await ApiService.uploadDataRequestsV2Files(uploadRequest);

      const uploadedCount = values.attachments.addUploaded.length;
      const existingCount = values.attachments.addExisting.length;
      const totalFiles = uploadedCount + existingCount;
      const firstUploadedFilename =
        values.attachments.addUploaded[0]?.documentName || '';

      let content;

      if (uploadedCount > 0 && existingCount > 0) {
        content = getTextToDisplay('uploadFilesSuccess.content_plural', {
          count: totalFiles
        });
      } else if (uploadedCount > 0) {
        content =
          uploadedCount === 1
            ? getTextToDisplay('uploadFilesSuccess.content', {
                filename: firstUploadedFilename
              })
            : getTextToDisplay('uploadFilesSuccess.content_plural', {
                count: uploadedCount
              });
      } else {
        content =
          existingCount === 1
            ? getTextToDisplay('uploadFilesSuccess.content_existing')
            : getTextToDisplay('uploadFilesSuccess.content_plural', {
                count: existingCount
              });
      }

      pushToast({
        type: EMessageTypes.SUCCESS,
        title: getTextToDisplay('uploadFilesSuccess.title'),
        content
      });

      this.setState({ submitPromise: null }, () => {
        this.close(true);
      });
    } catch (error) {
      this.setState({
        submitPromise: null,
        message: (
          <Message title={getTextToDisplay('serverError.title')} type="error">
            {getTextToDisplay('serverError.content')}
          </Message>
        )
      });

      throw error;
    }
  }

  handleSubmit = (values: IDataRequestRyanUploadModalValues) => {
    const { dataRequest } = this.props;

    if (
      dataRequest &&
      (values.attachments.addUploaded.length > 0 ||
        values.attachments.addExisting.length > 0)
    ) {
      this.setState({
        message: null,
        submitPromise: this.uploadFiles(dataRequest, values)
      });
    }
  };

  close(withChanges: boolean) {
    if (this.state.submitPromise === null) {
      this.setState({ message: null }, () => {
        this.dss.clearUploads();
        this.props.onClose(withChanges);
      });
    }
  }

  renderUser(user: IUserSummary, { query }: { query: string }) {
    return autocompleteHighlight(user.fullName, query);
  }

  render() {
    const { t: getTextToDisplay, dataRequest, open } = this.props;
    const { submitPromise, message } = this.state;

    if (dataRequest) {
      return (
        <UploadModalWarning dss={this.dss} onClose={() => this.close(false)}>
          {({ dss, warning, onEscape, onCancel }) => (
            <Modal
              className="data-request-ryan-upload-modal"
              onClose={onEscape}
              open={open}
              title={getTextToDisplay('Upload Files')}
            >
              {warning}
              {message}
              <div ref={this.errorContainerRef} />
              <Formik<IDataRequestRyanUploadModalValues>
                initialValues={initialValues}
                onSubmit={this.handleSubmit}
                validationSchema={this.schema}
              >
                {formik => {
                  const disabled =
                    (formik.values.attachments.addUploaded.length === 0 &&
                      formik.values.attachments.addExisting.length === 0) ||
                    formik.values.user === null ||
                    (formik.values.markAsDataDelivered === null &&
                      formik.values.moreFilesWillBeUploaded === null);

                  return (
                    <form
                      autoComplete="off"
                      onSubmit={formik.handleSubmit}
                      ref={this.formRef}
                    >
                      <div className="data-request-ryan-upload-modal__flex">
                        <div className="data-request-ryan-upload-modal__flex__left">
                          <ManageAttachments
                            {...formikFieldProps('attachments', formik)}
                            attachExisting={false}
                            attachments={dataRequest.attachments}
                            dss={dss}
                            engagementGuid={dataRequest.engagementGuid}
                            engagementName={
                              dataRequest.engagementDisplayNameShort
                            }
                            isInternalFolderShown={false}
                            isSelecting
                            onChange={updates =>
                              formik.setFieldValue('attachments', updates)
                            }
                            shouldDelete={false}
                            showExisting={false}
                            showLabel={false}
                            smallDropzone={false}
                            value={formik.values.attachments}
                          />
                        </div>
                        <div className="data-request-ryan-upload-modal__flex__right">
                          <div>
                            <h4 className="ry-h4">
                              {dataRequest.engagementDisplayNameShort}
                            </h4>
                            <h3 className="ry-h3">{dataRequest.title}</h3>
                            <UserAutocomplete
                              {...formikAutocompleteAjaxProps('user', formik)}
                              engagementGuid={dataRequest.engagementGuid}
                              label={getTextToDisplay(
                                'Uploading Files on Behalf of'
                              )}
                            />
                            <CommentInput
                              {...formikCommentProps('comment', formik)}
                              boundingParentRef={this.formRef}
                              label={getTextToDisplay(
                                'dataRequest.ryanUploadModal.commentLabel'
                              )}
                              makeOnSuggestionsRequestedCallback={() =>
                                makeOnSuggestionsRequested(
                                  dataRequest.engagementGuid,
                                  user => user.canBeMentionedInDRComment
                                )
                              }
                              placeholder={getTextToDisplay(
                                'dataRequest.ryanUploadModal.commentPlaceholder'
                              )}
                            />
                            <RadioGroup
                              name="fileDeliveryOptions"
                              onChange={e => {
                                formik.setFieldValue(
                                  'markAsDataDelivered',
                                  e.target.value === 'markAsDataDelivered'
                                );
                                formik.setFieldValue(
                                  'moreFilesWillBeUploaded',
                                  e.target.value === 'moreFilesWillBeUploaded'
                                );
                              }}
                              value={
                                formik.values.markAsDataDelivered
                                  ? 'markAsDataDelivered'
                                  : formik.values.moreFilesWillBeUploaded
                                  ? 'moreFilesWillBeUploaded'
                                  : ''
                              }
                            >
                              <Radio
                                label={getTextToDisplay(
                                  'dataRequest.ryanUploadModal.moreFilesWillBeUploaded'
                                )}
                                value="moreFilesWillBeUploaded"
                              />
                              <Radio
                                label={getTextToDisplay(
                                  'dataRequest.ryanUploadModal.markAsDataDelivered'
                                )}
                                value="markAsDataDelivered"
                              />
                            </RadioGroup>
                          </div>
                        </div>
                      </div>
                      <div className="data-request-ryan-upload-modal__buttons">
                        <ButtonGroup>
                          <Button
                            disabled={disabled}
                            loading={submitPromise}
                            size={EButtonSizes.LARGE}
                            text={getTextToDisplay('Upload')}
                            type="submit"
                            variant={EButtonVariant.PRIMARY}
                          />
                          <Button
                            disabled={submitPromise !== null}
                            onClick={onCancel}
                            size={EButtonSizes.LARGE}
                            text={getTextToDisplay('Cancel')}
                            variant={EButtonVariant.SECONDARY}
                          />
                        </ButtonGroup>
                      </div>
                    </form>
                  );
                }}
              </Formik>
            </Modal>
          )}
        </UploadModalWarning>
      );
    }

    return null;
  }
}

export default withTranslation()(DataRequestRyanUploadModal);
