import { FolderErrorsEnum } from './SelectFolder.utils';

import React, {
  ChangeEvent,
  MouseEvent,
  PureComponent,
  createRef
} from 'react';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';

import {
  Button,
  EButtonSizes,
  EButtonVariant,
  Icon,
  TextInput
} from '@ryan/components';

import { WithUser, withUser } from '../../contexts/UserContext';
import {
  Feature,
  FolderSelection,
  IFolder,
  IFolderTree,
  UserType
} from '../../interfaces';
import _findFolder from '../../utils/findFolder';
import { sortFolders } from './sortFolders';

import './SelectFolder.scss';

interface ISelectFolderProps extends WithTranslation, WithUser {
  autoOpen?: boolean;
  createMode?: 'toggle' | 'always' | 'never';
  disabled?: boolean;
  /**
   * A list of folder GUIDs that should not be selectable ie. prevent a folder
   * from being moved within itself.
   */
  disabledFolders?: string[];
  feedback?: string | null;
  folders: IFolderTree[];
  invalid?: boolean;
  isInternalOnly?: boolean;
  label?: string;
  moveFileCount?: number;
  onChange: (folder: FolderSelection) => void;
  rootName: string;
  value: FolderSelection;
}

interface ISelectFolderState {
  open: boolean;
  filteredFolders: IFolderTree[];
}

export class SelectFolder extends PureComponent<
  ISelectFolderProps,
  ISelectFolderState
> {
  static defaultProps = { createMode: 'toggle' as const };

  private ref = createRef<HTMLDivElement>();

  private wasMouseDowned = false;

  filterArchivedFolders = (folderTree: IFolderTree): IFolderTree | null => {
    if (folderTree.archiveDate) {
      return null;
    }

    const filteredChildFolders = folderTree.childFolders
      ?.map(this.filterArchivedFolders)
      .filter((folder): folder is IFolderTree => folder !== null);

    return {
      ...folderTree,
      childFolders: filteredChildFolders
    };
  };

  constructor(props: ISelectFolderProps) {
    super(props);

    const { folders, createMode, autoOpen } = props;
    const open = createMode === 'always' || autoOpen === true;

    const filteredFolders = folders
      .map(this.filterArchivedFolders)
      .filter((folderTree): folderTree is IFolderTree => folderTree !== null);

    this.state = { filteredFolders, open };
  }

  /**
   * If we started to create a new folder, focus and select on the input.
   * @todo assert updated `value` is a new folder if `createMode` is true
   */
  componentDidUpdate({
    folders: prevFolders,
    value: prevValue
  }: ISelectFolderProps) {
    const { folders, value } = this.props;
    if (
      value &&
      value.folderGuid === null &&
      (!prevValue || prevValue.folderGuid !== null) &&
      this.ref.current
    ) {
      const input = this.ref.current.querySelector('input');
      if (input) {
        input.focus();
        input.setSelectionRange(0, input.value.length);
      }
    }

    if (folders !== prevFolders) {
      this.setState({ filteredFolders: folders });
    }
  }

  findFolder(folderGuid: string) {
    const { filteredFolders: folders } = this.state;
    const match = _findFolder(folders, folderGuid);
    if (match) {
      return match.element;
    }

    throw new Error(`Could not find folder ${folderGuid} in the tree.`);
  }

  handleFocus = () => {
    this.wasMouseDowned = false;
    this.setState({ open: true });
  };

  handleMouseDown = () => {
    this.wasMouseDowned = true;
  };

  /**
   * Called on input blur.
   * If the blur was caused by a click on the component, we want
   * to reinstate focus on the input. Otherwise, close the options.
   */
  handleBlur = () => {
    if (this.wasMouseDowned) {
      this.wasMouseDowned = false;
      setTimeout(() => {
        if (this.ref.current) {
          const input = this.ref.current.querySelector('input');
          if (input) {
            input.focus();
          }
        }
      }, 0);
    }
  };

  handleToggle = () => {
    this.setState(({ open }) => ({ open: !open }));
  };

  /**
   * Go back to parent folder.
   * If we were creating a new folder, cancel.
   */
  handleBack = (e: MouseEvent) => {
    e.preventDefault();

    const { value, onChange, createMode } = this.props;
    let folder = value;

    // if creating a new folder
    if (folder !== null && folder.folderGuid === null) {
      // if new folder is at root
      if (folder.parentFolderGuid === null) {
        // if in create mode, do nothing
        // we don't even show the back button in this case, so this should never happen
        if (createMode === 'always') {
          return;
        }

        // cancel create, return to root
        onChange(null);
        return;
      }

      // get parent folder
      const parentFolder = this.findFolder(folder.parentFolderGuid);

      // if we're in create mode, take the new folder back
      if (createMode === 'always') {
        onChange({
          parentFolderGuid: parentFolder.parentFolderGuid,
          folderGuid: folder.folderGuid,
          folderName: folder.folderName,
          folderVisibleToUserTypes: folder.folderVisibleToUserTypes
        });
        return;
      }

      // cancel create, operate as usual
      // the current folder is the new folder's parent folder 0_0
      folder = parentFolder;
    }

    // already at root (not in a folder), cannot go higher
    if (folder === null) {
      return;
    }

    // go to root
    if (folder.parentFolderGuid === null) {
      onChange(null);
      return;
    }

    // go to parent folder
    const parentFolder = this.findFolder(folder.parentFolderGuid);
    onChange({
      parentFolderGuid: parentFolder.parentFolderGuid,
      folderGuid: parentFolder.folderGuid,
      folderName: parentFolder.folderName,
      folderVisibleToUserTypes: parentFolder.folderVisibleToUserTypes
    });
  };

  /**
   * Drill to selected folder.
   * If we were creating a new folder, cancel.
   */
  handleSelect = (folder: IFolder) => (e: MouseEvent) => {
    e.preventDefault();

    const { createMode, value, onChange } = this.props;

    if (
      createMode === 'always' &&
      value !== null &&
      value.folderGuid === null
    ) {
      onChange({
        ...value,
        parentFolderGuid: folder.folderGuid
      });
      return;
    }

    onChange({
      parentFolderGuid: folder.parentFolderGuid,
      folderGuid: folder.folderGuid,
      folderName: folder.folderName,
      folderVisibleToUserTypes: folder.folderVisibleToUserTypes
    });
  };

  /**
   * Create a new folder named something at the current level.
   */
  handleCreate = () => {
    const { t, value, onChange } = this.props;
    onChange({
      parentFolderGuid: value ? value.folderGuid : null,
      folderGuid: null,
      folderName: t('New Folder'),
      folderVisibleToUserTypes: value ? value.folderVisibleToUserTypes : null
    });
  };

  handleCreateChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, onChange } = this.props;
    if (value !== null && value.folderGuid === null) {
      onChange({
        parentFolderGuid: value.parentFolderGuid,
        folderGuid: null,
        folderName: e.target.value,
        folderVisibleToUserTypes: value.folderVisibleToUserTypes
      });
    }
  };

  handleCreateCancel = (e: MouseEvent) => {
    e.preventDefault();

    const { value, onChange } = this.props;
    if (value && value.folderGuid === null) {
      if (value.parentFolderGuid === null) {
        onChange(null);
      } else {
        const folder = this.findFolder(value.parentFolderGuid);
        onChange({
          parentFolderGuid: folder.parentFolderGuid,
          folderGuid: folder.folderGuid,
          folderName: folder.folderName,
          folderVisibleToUserTypes: folder.folderVisibleToUserTypes
        });
      }
    }
  };

  getSubFolders() {
    const { value: folder } = this.props;
    const { filteredFolders: folders } = this.state;
    const isCreating = folder !== null && folder.folderGuid === null;

    if (
      // at root
      folder === null ||
      // creating a new folder at root
      (isCreating && folder.parentFolderGuid === null)
    ) {
      return sortFolders(folders);
    }

    const parentFolderGuid = isCreating
      ? folder.parentFolderGuid
      : folder.folderGuid;
    const folderTree = this.findFolder(parentFolderGuid!);
    return sortFolders(folderTree.childFolders);
  }

  getParentFolderName() {
    const { rootName, value } = this.props;
    const isCreating = value !== null && value.folderGuid === null;

    if (
      // at root
      value === null ||
      // creating a new folder at root
      (isCreating && value.parentFolderGuid === null)
    ) {
      // we are at root, no parent
      return;
    }

    if (isCreating) {
      const parentFolder = this.findFolder(value.parentFolderGuid!);

      // if creating new folder,
      // the new folder's parent is the folder we show,
      // so it's parent is the name we're looking to return

      if (parentFolder.parentFolderGuid === null) {
        return rootName;
      }

      const parentParentFolder = this.findFolder(parentFolder.parentFolderGuid);
      return parentParentFolder.folderName;
    }
    // if parent is null, return rootName
    if (value.parentFolderGuid === null) {
      return rootName;
    }

    const parentFolder = this.findFolder(value.parentFolderGuid);
    return parentFolder.folderName;
  }

  getNewFolderParentFolderName() {
    const { rootName, value } = this.props;
    const { filteredFolders: folders } = this.state;
    if (folders && value !== null && value.folderGuid === null) {
      if (value.parentFolderGuid === null) {
        return rootName;
      }

      const parentFolder = this.findFolder(value.parentFolderGuid);
      return parentFolder.folderName;
    }
  }

  render() {
    const {
      createMode,
      disabled,
      disabledFolders,
      feedback,
      invalid,
      isFeatureToggled,
      label,
      moveFileCount,
      rootName,
      t: getTextToDisplay,
      value
    } = this.props;
    const { open } = this.state;
    const isCreating = value !== null && value.folderGuid === null;
    const isAtRoot =
      value === null || (isCreating && value.parentFolderGuid === null);
    const folderName = value ? value.folderName : rootName;
    const isInternalFilesFunctionalityVisible = isFeatureToggled(
      Feature.InternalFiles
    );

    const mappedFolderError =
      FolderErrorsEnum[feedback as keyof typeof FolderErrorsEnum];
    let modifiedFolderError;
    if (mappedFolderError) {
      modifiedFolderError = getTextToDisplay(
        `selectFolder.${mappedFolderError}`
      );
    }

    return (
      <div className="select-folder" ref={this.ref}>
        <div className="select-folder__selection">
          <TextInput
            disabled={disabled}
            feedback={modifiedFolderError || feedback || undefined}
            icon={open ? 'chevron-up' : 'chevron-down'}
            invalid={invalid}
            label={
              label ||
              getTextToDisplay(
                isCreating
                  ? 'selectFolder.labelNewFolder'
                  : 'selectFolder.label'
              )
            }
            onBlur={this.handleBlur}
            onChange={this.handleCreateChange}
            onFocus={this.handleFocus}
            onIconClick={this.handleToggle}
            placeholder={getTextToDisplay('selectFolder.label')}
            value={folderName}
          />
          {open && !disabled && (
            <div
              className="select-folder__selection__folder-explorer"
              onMouseDown={this.handleMouseDown}
            >
              <ul>
                {!isAtRoot && (
                  <li>
                    <button onClick={this.handleBack} type="button">
                      <Icon name="chevron-left" />
                      <Trans i18nKey="selectFolder.backToFolder">
                        <b />
                        {{ folderName: this.getParentFolderName() }}
                      </Trans>
                    </button>
                  </li>
                )}
                {isCreating && (
                  <li className="new-folder">
                    <button type="button">
                      <Icon
                        name={
                          value!.folderVisibleToUserTypes === UserType.Ryan &&
                          isInternalFilesFunctionalityVisible
                            ? 'folder-visibility'
                            : 'folder-open'
                        }
                      />
                      {value!.folderName}
                    </button>
                  </li>
                )}
                {this.getSubFolders()
                  .filter(folder => !folder.archiveDate)
                  .map(folder => {
                    return (
                      <li key={folder.folderGuid}>
                        <button
                          disabled={
                            disabledFolders &&
                            disabledFolders.indexOf(folder.folderGuid) > -1
                          }
                          onClick={this.handleSelect(folder)}
                          type="button"
                        >
                          <Icon
                            name={
                              folder.folderVisibleToUserTypes ===
                                UserType.Ryan &&
                              isInternalFilesFunctionalityVisible
                                ? 'folder-visibility'
                                : 'folder-open'
                            }
                          />
                          {folder.folderName}
                          <Icon name="chevron-right" />
                        </button>
                      </li>
                    );
                  })}
              </ul>
              <div className="select-folder__selection__folder-explorer__preview">
                {isCreating ? (
                  <Trans i18nKey="selectFolder.newFolderPreview">
                    <b />
                    {{ parentFolderName: this.getNewFolderParentFolderName() }}
                  </Trans>
                ) : (
                  <Trans count={moveFileCount} i18nKey="selectFolder.preview">
                    <b />
                    {{ folderName }}
                  </Trans>
                )}
              </div>
            </div>
          )}
        </div>
        {createMode === 'toggle' && (
          <Button
            {...(!isCreating && { icon: 'folder-add' })}
            disabled={disabled || (!isCreating && !!value?.archiveDate)}
            onClick={isCreating ? this.handleCreateCancel : this.handleCreate}
            size={EButtonSizes.SMALL}
            text={getTextToDisplay(
              `selectFolder.${isCreating ? 'newFolderCancel' : 'newFolder'}`
            )}
            variant={EButtonVariant.TEXT}
          />
        )}
      </div>
    );
  }
}

export default withTranslation()(withUser(SelectFolder));
