import { makeOnSuggestionsRequested } from 'components/Comments/CommentInput/CommentInput';

import { TFunction } from 'i18next';
import React, { FunctionComponent } from 'react';

import { MentionsValue, TabManager, TabPanel, Tabs } from '@ryan/components';

import { WithUser, withUser } from '../../contexts/UserContext';
import { IActivity, IComment, IDataRequestV2 } from '../../interfaces';
import ActivityFeed, { ActivityFeedSkeleton } from '../ActivityFeed';
import { Comments, NewComment } from '../Comments';
import Drawer from '../Drawer';

import './DataRequestDrawer.scss';

export interface IDataRequestDrawerProps extends WithUser {
  t: TFunction;
  dataRequest: IDataRequestV2 | null;
  tabKey: 'history' | 'comments';

  history: IActivity[] | null;
  historyLoading: boolean;

  comments: IComment[] | null;
  commentsLoading: boolean;

  canComment: boolean;

  onClose: () => void;
  onTabChange: (activeTabKey: 'history' | 'comments') => void;

  editComment: IComment | null;
  editCommentText: MentionsValue;
  editCommentLoading: Promise<any> | null;
  onEditComment: (comment: IComment) => void;
  onEditCommentChange: (commentText: MentionsValue) => void;
  onEditCommentSave: () => void;
  onEditCommentCancel: () => void;

  onDeleteComment: (deleteComment: IComment) => void;

  newCommentText: MentionsValue;
  newCommentLoading: Promise<any> | null;
  onNewCommentChange: (commentText: MentionsValue) => void;
  onNewCommentSave: () => void;
}

/**
 * Drawer displaying the update history and comments for a Data Request.
 */
const DataRequestDrawer: FunctionComponent<IDataRequestDrawerProps> = ({
  canComment,
  comments,
  commentsLoading,
  dataRequest,
  editComment,
  editCommentText,
  editCommentLoading,
  history,
  historyLoading,
  newCommentLoading,
  newCommentText,
  onClose,
  onDeleteComment,
  onEditComment,
  onEditCommentCancel,
  onEditCommentChange,
  onEditCommentSave,
  onNewCommentChange,
  onNewCommentSave,
  onTabChange,
  t: getTextToDisplay,
  tabKey
}) => {
  return dataRequest !== null ? (
    <Drawer className="data-request-drawer" onClose={onClose} open>
      <TabManager activeTabKey={tabKey} onTabChange={onTabChange as any}>
        <Drawer.Header>
          <h4 className="ry-h4">{dataRequest.engagementDisplayNameShort}</h4>
          <h3 className="ry-h3">{dataRequest.title}</h3>
          <h4 className="data-request-drawer__data-request-type-name">
            {dataRequest.dataRequestType?.name}
          </h4>
          <Tabs
            block
            tabs={[
              { tabKey: 'history', text: getTextToDisplay('History') },
              { tabKey: 'comments', text: getTextToDisplay('Comments') }
            ]}
          />
        </Drawer.Header>
        <TabPanel tabKey="history">
          <Drawer.Body>
            {historyLoading ? (
              <ActivityFeedSkeleton />
            ) : (
              <ActivityFeed activities={history || []} />
            )}
          </Drawer.Body>
        </TabPanel>
        <TabPanel tabKey="comments">
          <Drawer.Body>
            <Comments
              canComment={canComment}
              comments={comments}
              editComment={editComment}
              editCommentLoading={editCommentLoading}
              editCommentText={editCommentText}
              engagementGuid={dataRequest.engagementGuid}
              loading={commentsLoading}
              onDelete={onDeleteComment}
              onEdit={onEditComment}
              onEditCancel={onEditCommentCancel}
              onEditChange={onEditCommentChange}
              onEditSave={onEditCommentSave}
            />
          </Drawer.Body>
          {canComment && (
            <Drawer.Footer>
              <NewComment
                commentText={newCommentText}
                engagementGuid={dataRequest.engagementGuid}
                loading={newCommentLoading}
                makeOnSuggestionsRequestedCallback={() =>
                  makeOnSuggestionsRequested(
                    dataRequest.engagementGuid,
                    user => user.canBeMentionedInDRComment
                  )
                }
                onChange={onNewCommentChange}
                onSave={onNewCommentSave}
                t={getTextToDisplay}
              />
            </Drawer.Footer>
          )}
        </TabPanel>
      </TabManager>
    </Drawer>
  ) : null;
};

export default withUser(DataRequestDrawer);
