import {
  IMapToDataRequestExpandedRow,
  TDataRequestExpandedRowProps
} from './DataRequestExpandedRow.interfaces';

import React from 'react';

import { TWorklistDataRequestItem } from '../../../../../routes/Worklist/WorklistOpen/WorklistOpen.interfaces';
import { formatDate } from '../../../../../utils/formatDate';
import { handleToggleFollow } from '../Worklist.utils';
import DataRequestOverview from './DataRequestOverview/DataRequestOverview';
import DataRequestStatus from './DataRequestStatus/DataRequestStatus';

import './DataRequestExpandedRow.scss';

export const mapToDataRequestExpandedRow: IMapToDataRequestExpandedRow = ({
  assignedTo,
  attachments,
  commentCount,
  createdBy,
  createdDate,
  description,
  dueDate,
  engagementDisplayNameShort,
  engagementGuid,
  followerCount,
  isEngagementReadOnly,
  isFollowing,
  queueItemGuid,
  statusId,
  title,
  ...rest
}) => {
  const {
    dateRanges,
    dataSpecs,
    documentTypes,
    engagementDisplayNameLong,
    erpSystem,
    isMarkedAsDataDelivered,
    jurisdictions,
    subStatusId,
    subTypeName,
    otherDocumentType,
    otherErpSystem,
    otherTaxEngine,
    taxEngine,
    transferredFileCount,
    typeGuid
  } = rest as TWorklistDataRequestItem;

  return {
    engagementGuid,
    overview: {
      attachments,
      commentCount,
      dataSpecs,
      description,
      detailsByType: {
        dateRanges: dateRanges.map(({ dateEnd, dateStart }) =>
          dateEnd === dateStart
            ? `${formatDate(dateStart)}`
            : `${formatDate(dateStart)} - ${formatDate(dateEnd)}`
        ),
        documentTypes: documentTypes.map(({ name }) =>
          name === 'Other' ? otherDocumentType : name
        ),
        erpSystemName:
          erpSystem.name === 'Other' ? otherErpSystem : erpSystem.name,
        jurisdictions: jurisdictions.map(({ name }) => name),
        taxEngineName:
          taxEngine.name === 'Other' ? otherTaxEngine : taxEngine.name,
        typeGuid
      },
      engagementDisplayNameLong,
      engagementDisplayNameShort,
      isEngagementReadOnly,
      subTypeName,
      title
    },
    queueItemGuid,
    status: {
      assignedToDetails: {
        name: assignedTo.name,
        userGuid: assignedTo.userGuid
      },
      createdByDetails: { name: createdBy.name, userGuid: createdBy.userGuid },
      dueDate: formatDate(dueDate),
      followerCount,
      isFollowing,
      requestedDate: formatDate(createdDate),
      statusId,
      subStatusId: (isMarkedAsDataDelivered && subStatusId) || null,
      transferredFileCount
    }
  };
};

const DataRequestExpandedRow = ({
  dataRequestDetails,
  isDisabled,
  onOpenDataRequestCommentDrawer,
  onOpenDataRequestHistoryDrawer,
  onOpenFollowersModal,
  setIsLoading,
  setIsUpdatingFollowStatus
}: TDataRequestExpandedRowProps) => (
  <div className="data-request-expanded-row">
    <DataRequestOverview
      dataRequestDetails={{
        ...dataRequestDetails.overview,
        engagementGuid: dataRequestDetails.engagementGuid
      }}
      isDisabled={isDisabled}
      onOpenDataRequestCommentDrawer={onOpenDataRequestCommentDrawer}
      onOpenDataRequestHistoryDrawer={onOpenDataRequestHistoryDrawer}
    />

    <DataRequestStatus
      dataRequestDetails={dataRequestDetails.status}
      isDisabled={isDisabled}
      onOpenFollowersModal={onOpenFollowersModal}
      onToggleFollow={() => {
        handleToggleFollow({
          engagementGuid: dataRequestDetails.engagementGuid,
          isFollowing: dataRequestDetails.status.isFollowing,
          queueItemGuid: dataRequestDetails.queueItemGuid,
          setIsLoading,
          setIsUpdatingFollowStatus
        });
      }}
    />
  </div>
);

export default DataRequestExpandedRow;
