import React, { PureComponent, createRef } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import {
  Button,
  ButtonGroup,
  EButtonSizes,
  EButtonVariant,
  EMessageTypes,
  MentionsValue,
  Message,
  Radio,
  RadioGroup,
  pushToast
} from '@ryan/components';

import CommentInput, {
  makeOnSuggestionsRequested
} from '../../components/Comments/CommentInput/CommentInput';
import ManageAttachments from '../../components/ManageAttachments';
import Modal from '../../components/Modal';
import UploadModalWarning from '../../components/UploadModalWarning/UploadModalWarning';
import {
  IAttachmentUpdates,
  IDataRequestV2,
  IDataRequestV2UploadFiles,
  IEngagement,
  Status
} from '../../interfaces';
import ApiService from '../../services/ApiService';
import { DSSManager } from '../../utils/DSS';
import { SubStatusEnums } from '../../utils/enums/SubStatusEnums';
import {
  Formik,
  formikCommentProps,
  formikFieldProps,
  yup
} from '../../utils/forms';

import './DataRequestUploadModal.scss';

interface IDataRequestUploadModalProps extends WithTranslation {
  dataRequest: IDataRequestV2 | null;
  engagement: IEngagement | null;
  onClose: (uploadedFiles: boolean) => void;
  open: boolean;
}

interface IDataRequestUploadModalValues {
  comment: MentionsValue;
  attachments: IAttachmentUpdates;
  markAsDataDelivered: boolean | null;
  moreFilesWillBeUploaded: boolean | null;
}

interface IDataRequestUploadModalState {
  message: React.ReactNode | null;
}

const initialValues: IDataRequestUploadModalValues = {
  comment: new MentionsValue(),
  attachments: {
    addUploaded: [],
    deleteAttachments: [],
    addExisting: []
  },
  markAsDataDelivered: null,
  moreFilesWillBeUploaded: null
};

const maxLengthComment = 250;

interface IDataRequestUploadModalState {
  submitPromise: Promise<any> | null;
  message: React.ReactNode | null;
}

class DataRequestUploadModal extends PureComponent<
  IDataRequestUploadModalProps,
  IDataRequestUploadModalState
> {
  private dss = new DSSManager({ onChange: () => this.forceUpdate() });

  private formRef = createRef<HTMLFormElement>();

  private schema: yup.ObjectSchema<IDataRequestUploadModalValues>;

  readonly state: IDataRequestUploadModalState = {
    message: null,
    submitPromise: null
  };

  constructor(props: IDataRequestUploadModalProps) {
    super(props);

    const { t: getTextToDisplay } = props;

    this.schema = yup.object({
      comment: yup.mixed().validateCommentLength(
        maxLengthComment,
        getTextToDisplay('dataRequest.ryanUploadModal.commentMaxLength', {
          length: maxLengthComment
        })
      ),
      attachments: yup.object(),
      markAsDataDelivered: yup.boolean(),
      moreFilesWillBeUploaded: yup.boolean()
    });

    this.state = {
      submitPromise: null,
      message: null
    };
  }

  async uploadFiles(
    dataRequest: IDataRequestV2,
    values: IDataRequestUploadModalValues
  ) {
    const { t: getTextToDisplay } = this.props;

    const uploadRequest: IDataRequestV2UploadFiles = {
      attachmentsToUpsert: values.attachments,
      comment: values.comment.toJSON().text,
      engagementGuid: dataRequest.engagementGuid,
      markAsDataDelivered: values.markAsDataDelivered,
      moreFilesWillBeUploaded: values.moreFilesWillBeUploaded,
      status: Status.InProgress,
      substatus: values.markAsDataDelivered
        ? SubStatusEnums.DataDelivered
        : null,
      queueItemGuid: dataRequest.queueItemGuid
    };

    try {
      await ApiService.uploadDataRequestsV2Files(uploadRequest);

      const uploadedCount = values.attachments.addUploaded.length;
      const firstUploadedFilename =
        values.attachments.addUploaded[0]?.documentName || '';

      let content;

      if (uploadedCount > 1) {
        content = getTextToDisplay('uploadFilesSuccess.content_plural', {
          count: uploadedCount
        });
      } else {
        content = getTextToDisplay('uploadFilesSuccess.content', {
          filename: firstUploadedFilename
        });
      }

      pushToast({
        type: EMessageTypes.SUCCESS,
        title: getTextToDisplay('uploadFilesSuccess.title'),
        content
      });

      this.setState({ submitPromise: null }, () => {
        this.close(true);
      });
    } catch (error) {
      this.setState({
        submitPromise: null,
        message: (
          <Message title={getTextToDisplay('serverError.title')} type="error">
            {getTextToDisplay('serverError.content')}
          </Message>
        )
      });

      throw error;
    }
  }

  handleSubmit = (values: IDataRequestUploadModalValues) => {
    const { dataRequest } = this.props;

    if (
      dataRequest &&
      (values.attachments.addUploaded.length > 0 ||
        values.attachments.addExisting.length > 0)
    ) {
      this.setState({
        message: null,
        submitPromise: this.uploadFiles(dataRequest, values)
      });
    }
  };

  close(withChanges: boolean) {
    if (this.state.submitPromise === null) {
      this.setState({ message: null }, () => {
        this.dss.clearUploads();
        this.props.onClose(withChanges);
      });
    }
  }

  render() {
    const { dataRequest, open, t: getTextToDisplay } = this.props;

    if (open && dataRequest) {
      return (
        <UploadModalWarning dss={this.dss} onClose={() => this.close(false)}>
          {({ dss, warning, onEscape, onCancel }) => (
            <Modal
              className="data-request-upload-modal"
              onClose={onEscape}
              open
              title={getTextToDisplay('Upload Files')}
            >
              {warning}
              {this.state.message}
              <Formik<IDataRequestUploadModalValues>
                initialValues={initialValues}
                onSubmit={this.handleSubmit}
                validationSchema={this.schema}
              >
                {formik => {
                  return (
                    <form
                      autoComplete="off"
                      onSubmit={formik.handleSubmit}
                      ref={this.formRef}
                    >
                      <div className="data-request-upload-modal__flex">
                        <div className="data-request-upload-modal__flex__left">
                          <ManageAttachments
                            {...formikFieldProps('attachments', formik)}
                            attachExisting={false}
                            attachments={dataRequest.attachments}
                            dss={dss}
                            engagementGuid={dataRequest.engagementGuid}
                            engagementName={
                              dataRequest.engagementDisplayNameShort
                            }
                            isInternalFolderShown={false}
                            isSelecting
                            onChange={updates =>
                              formik.setFieldValue('attachments', updates)
                            }
                            shouldDelete={false}
                            showExisting={false}
                            showLabel={false}
                            smallDropzone={false}
                            value={formik.values.attachments}
                          />
                        </div>
                        <div className="data-request-upload-modal__flex__right">
                          <h4 className="ry-h4">
                            {dataRequest.engagementDisplayNameShort}
                          </h4>
                          <h3 className="ry-h3">{dataRequest.title}</h3>

                          <CommentInput
                            {...formikCommentProps('comment', formik)}
                            boundingParentRef={this.formRef}
                            label={getTextToDisplay(
                              'dataRequest.ryanUploadModal.commentLabel'
                            )}
                            makeOnSuggestionsRequestedCallback={() =>
                              makeOnSuggestionsRequested(
                                dataRequest.engagementGuid,
                                user => user.canBeMentionedInDRComment
                              )
                            }
                            placeholder={getTextToDisplay(
                              'dataRequest.ryanUploadModal.commentPlaceholder'
                            )}
                          />
                          <RadioGroup
                            name="fileDeliveryOptions"
                            onChange={e => {
                              formik.setFieldValue(
                                'markAsDataDelivered',
                                e.target.value === 'markAsDataDelivered'
                              );
                              formik.setFieldValue(
                                'moreFilesWillBeUploaded',
                                e.target.value === 'moreFilesWillBeUploaded'
                              );
                            }}
                            value={
                              formik.values.markAsDataDelivered
                                ? 'markAsDataDelivered'
                                : formik.values.moreFilesWillBeUploaded
                                ? 'moreFilesWillBeUploaded'
                                : ''
                            }
                          >
                            <Radio
                              label={getTextToDisplay(
                                'dataRequest.ryanUploadModal.moreFilesWillBeUploaded'
                              )}
                              value="moreFilesWillBeUploaded"
                            />
                            <Radio
                              label={getTextToDisplay(
                                'dataRequest.ryanUploadModal.markAsDataDelivered'
                              )}
                              value="markAsDataDelivered"
                            />
                          </RadioGroup>
                        </div>
                      </div>
                      <div className="data-request-upload-modal__buttons">
                        <ButtonGroup>
                          <Button
                            disabled={
                              formik.values.attachments.addUploaded.length ===
                                0 ||
                              (formik.values.markAsDataDelivered === null &&
                                formik.values.moreFilesWillBeUploaded === null)
                            }
                            loading={this.state.submitPromise}
                            size={EButtonSizes.LARGE}
                            text={getTextToDisplay('Upload')}
                            type="submit"
                            variant={EButtonVariant.PRIMARY}
                          />
                          <Button
                            disabled={this.state.submitPromise !== null}
                            onClick={onCancel}
                            size={EButtonSizes.LARGE}
                            text={getTextToDisplay('Cancel')}
                            variant={EButtonVariant.SECONDARY}
                          />
                        </ButtonGroup>
                      </div>
                    </form>
                  );
                }}
              </Formik>
            </Modal>
          )}
        </UploadModalWarning>
      );
    }

    return null;
  }
}

export default withTranslation()(DataRequestUploadModal);
