import { TDataRequestModalsProps } from './DataRequestModals.interfaces';
import {
  DataRequestModalActionEnums,
  mapAssignmentToAssignDataUploadRequest,
  mapAssignmentToCompleteDataRequest,
  mapAssignmentToDeleteDataRequest,
  mapAssignmentToEditDataRequest
} from './DataRequestModals.utils';

import React from 'react';

import DataRequestCompleteModal from '../../../components/DataRequestActions/DataRequestCompleteModal';
import DataRequestDeleteModal from '../../../components/DataRequestActions/DataRequestDeleteModal';
import UpdateDataRequestModal from '../../../components/DataRequestModal/UpdateDataRequestModal/UpdateDataRequestModal';
import AssignDataUploadModal from '../../../components/Modal/DataRequestModal/AssignDataUploadModal/AssignDataUploadModal';
import { IDataRequest, IDataRequestV2 } from '../../../interfaces';

const DataRequestModals = ({
  assigmentToAssignDataUpload,
  assignmentToComplete,
  assignmentToDelete,
  assignmentToEdit,
  onClose
}: TDataRequestModalsProps) => {
  let modalAction: DataRequestModalActionEnums | null = null;
  let dataRequest;

  if (assigmentToAssignDataUpload) {
    modalAction = DataRequestModalActionEnums.ASSIGN_DATA_UPLOAD;
    dataRequest = mapAssignmentToAssignDataUploadRequest(
      assigmentToAssignDataUpload
    );
  } else if (assignmentToComplete) {
    modalAction = DataRequestModalActionEnums.COMPLETE;
    dataRequest = mapAssignmentToCompleteDataRequest(assignmentToComplete);
  } else if (assignmentToDelete) {
    modalAction = DataRequestModalActionEnums.DELETE;
    dataRequest = mapAssignmentToDeleteDataRequest(assignmentToDelete);
  } else if (assignmentToEdit) {
    modalAction = DataRequestModalActionEnums.EDIT;
    dataRequest = mapAssignmentToEditDataRequest(assignmentToEdit);
  }

  return (
    <>
      {modalAction === DataRequestModalActionEnums.ASSIGN_DATA_UPLOAD && (
        <AssignDataUploadModal
          dataRequest={dataRequest as IDataRequestV2}
          onClose={(isUpdated?: boolean) => {
            onClose(
              isUpdated ? ({} as IDataRequest) : undefined,
              modalAction as DataRequestModalActionEnums
            );
          }}
          open
        />
      )}
      {modalAction === DataRequestModalActionEnums.COMPLETE && (
        <DataRequestCompleteModal
          dataRequest={dataRequest as IDataRequestV2}
          onClose={(updatedDataRequest?: IDataRequestV2) => {
            onClose(
              updatedDataRequest as IDataRequest,
              modalAction as DataRequestModalActionEnums
            );
          }}
        />
      )}
      {modalAction === DataRequestModalActionEnums.DELETE && (
        <DataRequestDeleteModal
          dataRequest={dataRequest as IDataRequestV2}
          onClose={(isDeleted: boolean) => {
            onClose(
              isDeleted ? ({} as IDataRequest) : undefined,
              modalAction as DataRequestModalActionEnums
            );
          }}
        />
      )}
      {modalAction === DataRequestModalActionEnums.EDIT && (
        <UpdateDataRequestModal
          dataRequest={dataRequest as IDataRequestV2}
          // HACK: Worklist: Partial updates for editing DR is disabled.
          //    Workaround: Enable edit DR to pull full DR on open
          isFetchCompleteDataRequest
          onClose={(updatedDataRequest?: IDataRequestV2) => {
            onClose(
              updatedDataRequest as IDataRequest,
              modalAction as DataRequestModalActionEnums
            );
          }}
        />
      )}
    </>
  );
};

export default DataRequestModals;
