import classNames from 'classnames';
import { FormikErrors } from 'formik';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '@ryan/components';

import { FileAttach } from '../../components/FileAttachments';
import { AttachmentLink } from '../../components/FileLink';
import { IAttachmentUpdates, IDirectoryFile, IFile } from '../../interfaces';
import { DSSManager } from '../../utils/DSS';

import './ManageAttachments.scss';

export interface IManageAttachmentsProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange' | 'value'> {
  attachExisting?: boolean;
  attachments: IFile[];
  dss: DSSManager;
  engagementGuid: string | null;
  engagementName: string | null;
  errors?: FormikErrors<any>;
  existingFile?: IDirectoryFile[];
  isInternalFolderShown?: boolean;
  isSelecting?: boolean;
  label?: string;
  multiple?: boolean;
  setExistingFile?: React.Dispatch<React.SetStateAction<IDirectoryFile[]>>;
  shouldDelete?: boolean;
  showExisting?: boolean;
  showLabel?: boolean;
  smallDropzone?: boolean;
  value: IAttachmentUpdates;
  canDeleteAttachment?: (file: IFile) => boolean;
  onChange: (value: IAttachmentUpdates) => void;
}

/**
 * Component to manage the attachments on an entity, like
 * a Data Request, Task, or Learning. Used in Modals.
 */
const ManageAttachments: FunctionComponent<IManageAttachmentsProps> = ({
  attachExisting,
  attachments,
  className,
  dss,
  engagementGuid,
  engagementName,
  errors,
  existingFile,
  isInternalFolderShown = true,
  isSelecting,
  label,
  multiple = true,
  setExistingFile,
  shouldDelete = true,
  showExisting = true,
  showLabel = true,
  smallDropzone,
  value,
  canDeleteAttachment,
  onChange,
  ...rest
}) => {
  const { t } = useTranslation();

  /**
   * Add new attachments
   */
  function onAddAttachmentChange(updates: IAttachmentUpdates) {
    onChange({
      // FileAttach only gives us the adds
      addUploaded: updates.addUploaded,
      addExisting: updates.addExisting,
      // Don't overwrite deletes!
      deleteAttachments: value.deleteAttachments
    });
  }

  /**
   * Delete an existing attachment
   */
  function onDeleteExistingAttachment(file: IFile) {
    onChange({
      ...value,
      deleteAttachments: [...value.deleteAttachments, file.documentGuid]
    });
  }

  const existingAttachments = attachments
    ?.filter(file => !value.deleteAttachments?.includes(file.documentGuid))
    .sort((a, b) => {
      const aDate = new Date(a.uploadedDate).getTime();
      const bDate = new Date(b.uploadedDate).getTime();
      return aDate - bDate;
    });

  return (
    <div className="manage-attachments">
      {showLabel &&
        (label ? (
          <label className="ry-label">{label}</label>
        ) : (
          <label className="ry-label">{t('Attach a File (Optional)')}</label>
        ))}
      <div
        className={classNames('manage-attachments__inner', className, {
          'manage-attachments__inner--error': errors?.attachments
        })}
        {...rest}
      >
        {/**
         * Attach new files
         */}
        <FileAttach
          attachExisting={attachExisting}
          dss={dss}
          engagementGuid={engagementGuid}
          engagementName={engagementName}
          excludeFromSearchResults={attachments?.map(file => file.documentGuid)}
          existingFile={existingFile}
          isInternalFolderShown={isInternalFolderShown}
          isSelecting={isSelecting}
          multiple={multiple}
          onAttachmentUpdate={onAddAttachmentChange}
          setExistingFile={setExistingFile}
          smallDropzone={smallDropzone}
        />

        {/**
         * Remove existing file attachments
         */}
        {showExisting && existingAttachments?.length > 0 && (
          <>
            <label className="ry-label">
              {t('Attachments', { context: 'files' })}
            </label>
            <ul className="manage-attachments__inner__existing">
              {existingAttachments.map(file => (
                <li key={`${file.documentGuid}`}>
                  <div className="manage-attachments__inner__existing-name">
                    <Icon name="paperclip" />
                    <AttachmentLink
                      engagementGuid={engagementGuid}
                      file={file}
                    />
                  </div>
                  {(canDeleteAttachment === undefined ||
                    canDeleteAttachment(file)) &&
                    shouldDelete && (
                      <button
                        aria-label={t('Unattach', { name: file.displayName })}
                        className="manage-attachments__inner__existing-delete"
                        onClick={() => onDeleteExistingAttachment(file)}
                        type="button"
                      >
                        <Icon name="trash" />
                      </button>
                    )}
                </li>
              ))}
            </ul>
          </>
        )}
      </div>
      {errors?.attachments && (
        <div className="ry-text-input__feedback manage-attachments__error-message">
          <Icon name="error" />
          {errors.attachments}
        </div>
      )}
    </div>
  );
};

export default ManageAttachments;
