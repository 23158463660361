import classnames from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Icon, TextInput } from '@ryan/components';

import { useStateMounted, useUser } from '../../../hooks';
import {
  DirectoryItemType,
  Feature,
  FolderSelection,
  IDirectoryFile,
  IDirectoryItem,
  IFolder,
  IFolderTree
} from '../../../interfaces';
import ApiService, { CancelTokenSource } from '../../../services/ApiService';
import _findFolder from '../../../utils/findFolder';
import getDirectoryItemIcon from '../../../utils/getDirectoryItemIcon';
import pushServerErrorToast from '../../../utils/pushServerErrorToast';
import { RYAN_INTERNAL } from '../utils/FileDirectoryEnums';

import './FileDirectoryBrowser.scss';

/**
 * Returns guid of directory item.
 */
const getItemGuid = (directoryItem: IDirectoryItem): string =>
  directoryItem.type === DirectoryItemType.File
    ? (directoryItem.item as IDirectoryFile).documentGuid
    : (directoryItem.item as IFolder).folderGuid;

interface IFileDirectoryBrowserProps {
  // guid of engagement to browse within
  engagementGuid: string | null;

  // the name of the engagement to be used as the root folder name
  engagementName: string | null;

  // a list of folder and document guids to exclude from view in browser
  exclude?: string[];

  // whether to show Ryan Internal folder in folder selection
  isInternalFolderShown?: boolean;

  // label to apply to directory browser field
  label?: string;

  // called on selection of directory item
  onChange?: (item: IDirectoryItem) => void;
}

/**
 * Renders a field to browse an engagement's files and folders in a dropdown
 * navigator.
 * @todo: consolidate with `SelectFolder` component.
 */
const FileDirectoryBrowser: React.FC<IFileDirectoryBrowserProps> = ({
  engagementGuid,
  engagementName: rootName,
  exclude = [],
  isInternalFolderShown,
  label,
  onChange
}) => {
  // axios cancel tokens
  const sourceRefDirectoryItems = useRef<CancelTokenSource>();
  const sourceRefEngagementFolders = useRef<CancelTokenSource>();

  // a list of items at the current folder being viewed
  const [currentDirectoryItems, setCurrentDirectoryItems] = useState<
    IDirectoryItem[]
  >([]);

  // current folder being viewed, `null` indicates the root folder
  const [currentFolder, setCurrentFolder] =
    useStateMounted<FolderSelection | null>(null);

  // the folder tree for the engagement
  const [folders, setFolders] = useState<IFolderTree[]>();

  // map of folder guids to the items contained within ("root" key represents
  // the root folder); map is updated as folders are selected
  const [folderMap, setFolderMap] = useState<{
    [folderGuid: string]: IDirectoryItem[];
  }>({});

  // indicates component is loading content for the specified folder GUID;
  // `undefined` represents the root folder and `null` means not loading
  const [loadingFolderGuid, setLoadingFolderGuid] = useStateMounted<
    string | undefined | null
  >(null);

  // controls visibility of the browser dropdown
  const [open, setOpen] = useState<boolean>(false);

  // folder queued to be loaded, a queued folder will trigger an API call to
  // fetch contents
  const [queuedFolder, setQueuedFolder] = useState<FolderSelection | null>(
    null
  );
  const rootRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const { isFeatureToggled } = useUser();
  const [isInternalFilesFeatureEnabled] = useState(
    isFeatureToggled(Feature.InternalFiles)
  );

  const folderName = currentFolder ? currentFolder.folderName : rootName;
  const isAtRoot = !currentFolder;

  // disable initiation of browse if engagement's folder tree not received or
  // engagement contains no items (filter out items that are excluded)
  const isDisabled =
    !folders ||
    !folderMap.root ||
    folderMap.root.filter(item => exclude.indexOf(getItemGuid(item)) === -1)
      .length === 0;

  /**
   * Finds a folder within a folder tree by guid.
   */
  const findFolder = (folderGuid: string) => {
    const match = folders && _findFolder(folders, folderGuid);
    return match && match.element;
  };

  /**
   * Gets the name of the parent folder.
   */
  const getParentFolderName = () => {
    const parentFolder =
      currentFolder &&
      currentFolder.parentFolderGuid &&
      findFolder(currentFolder.parentFolderGuid);
    return parentFolder ? parentFolder.folderName : rootName;
  };

  /**
   * Navigates to the parent folder.
   */
  const handleBack = (e: React.MouseEvent) => {
    e.preventDefault();

    // return if already at root folder
    if (!currentFolder) return;

    // go to root folder if only one level deep or parent folder otherwise
    if (currentFolder.parentFolderGuid === null) {
      setCurrentFolder(null);
    } else {
      const parentFolder = findFolder(currentFolder.parentFolderGuid);

      if (parentFolder) {
        setCurrentFolder({
          parentFolderGuid: parentFolder.parentFolderGuid,
          folderGuid: parentFolder.folderGuid,
          folderName: parentFolder.folderName,
          folderVisibleToUserTypes: parentFolder.folderVisibleToUserTypes
        });
      }
    }
  };

  /**
   * Closes browser dropdown on click outside of component container.
   */
  const handleBackgroundClick = useCallback((e: MouseEvent) => {
    const target = e.target as Element;

    // add test for directory item selector as the item element is no longer
    // contained within the container once navigated away from
    if (
      rootRef.current &&
      !rootRef.current.contains(target) &&
      !target.closest('.file-directory-browser__item')
    ) {
      setOpen(false);
    }
  }, []);

  /**
   * Opens dropdown on click within input field.
   */
  const handleFocus = () => {
    setOpen(true);
  };

  /**
   * Handles a click on a directory item. Clicking on a folder navigates to the
   * selected folder. Clicking on a file attaches the file and closes the modal.
   */
  const handleSelect = (e: React.MouseEvent, item: IDirectoryItem) => {
    e.preventDefault();

    if (item.type === DirectoryItemType.Folder) {
      // navigate to folder by placing in queue to have contents loaded
      const folder = item.item as IFolder;
      setQueuedFolder({
        parentFolderGuid: folder.parentFolderGuid,
        folderGuid: folder.folderGuid,
        folderName: folder.folderName,
        folderVisibleToUserTypes: folder.folderVisibleToUserTypes
      });
    }

    if (typeof onChange === 'function') {
      onChange(item);
    }
  };

  /**
   * Renders a directory item (folder or file).
   */
  const renderDirectoryItem = (directoryItem: IDirectoryItem) => {
    const isFile = directoryItem.type === DirectoryItemType.File;
    const guid = getItemGuid(directoryItem);
    const key = `filebrowse-${guid}`;
    const displayName = isFile
      ? (directoryItem.item as IDirectoryFile).displayName
      : (directoryItem.item as IFolder).folderName;

    // only render items that have not been excluded
    return exclude.indexOf(guid) === -1 ? (
      <li className="file-directory-browser__item" key={key}>
        <button onClick={e => handleSelect(e, directoryItem)} type="button">
          <Icon name={getDirectoryItemIcon(directoryItem)} />
          {displayName}
          {!isFile && (
            <Icon
              className={classnames('file-directory-browser__item__into', {
                'loading-spin':
                  loadingFolderGuid === directoryItem.item.folderGuid
              })}
              name={
                loadingFolderGuid === directoryItem.item.folderGuid
                  ? 'loading'
                  : 'chevron-right'
              }
            />
          )}
        </button>
      </li>
    ) : null;
  };

  // get root engagement folder to browse within on mount
  useEffect(() => {
    if (engagementGuid) {
      const fetchEngagementFolders = async () => {
        // cancel any previous requests and save new token for current request
        sourceRefEngagementFolders.current?.cancel(
          'cancelling previous FileDirectoryBrowser fetch'
        );
        sourceRefEngagementFolders.current = ApiService.CancelToken.source();

        try {
          const { data } = await ApiService.getEngagementFolders(
            engagementGuid,
            sourceRefEngagementFolders.current?.token
          );
          const foldersWithoutInternal = data.filter(
            folder => folder.folderName !== RYAN_INTERNAL
          );

          if (isInternalFilesFeatureEnabled && isInternalFolderShown) {
            setFolders(data);
          } else {
            setFolders(foldersWithoutInternal);
          }
        } catch (error) {
          if (!ApiService.isCancel(error)) {
            pushServerErrorToast();
          }
        }
      };

      fetchEngagementFolders();

      return () => {
        sourceRefEngagementFolders.current?.cancel();
      };
    }
  }, [engagementGuid, isInternalFilesFeatureEnabled, isInternalFolderShown]);

  // retrieve list of directory items in folder queued to be loaded
  useEffect(() => {
    const fetchDirectoryItems = async () => {
      if (!engagementGuid || !folders) return;

      // an undefined guid represents the root folder
      const folderGuid = queuedFolder?.folderGuid || undefined;

      // get list of directory items in selected folder if previously fetched,
      // otherwise fetch and cache results in map
      const folderMapKey = folderGuid || 'root';
      let directoryItems = folderMap[folderMapKey];

      if (!directoryItems) {
        // cancel any previous requests and save new token for current request
        sourceRefDirectoryItems.current?.cancel(
          'cancelling previous FileDirectoryBrowser fetch'
        );
        sourceRefDirectoryItems.current = ApiService.CancelToken.source();

        try {
          setLoadingFolderGuid(folderGuid);
          const { data } = await ApiService.getEngagementDirectoryByFolder(
            engagementGuid,
            {
              folderGuid,
              itemsPerPage: 999,
              pageNumber: 1
            },
            folders,
            sourceRefDirectoryItems.current?.token
          );
          directoryItems = data.results.filter(
            ({ item }) => !Boolean(item.archiveDate)
          );
          const directoryItemsWithoutInternal = directoryItems.filter(item => {
            if (!('folderName' in item.item)) {
              return true;
            }
            return item.item.folderName !== RYAN_INTERNAL;
          });

          if (isInternalFilesFeatureEnabled && isInternalFolderShown) {
            setFolderMap(prevFolderMap => ({
              ...prevFolderMap,
              [folderMapKey]: directoryItems
            }));
          } else {
            setFolderMap(prevFolderMap => ({
              ...prevFolderMap,
              [folderMapKey]: directoryItemsWithoutInternal
            }));
          }
        } catch (error) {
          if (!ApiService.isCancel(error)) {
            pushServerErrorToast();
          }
        } finally {
          setLoadingFolderGuid(null);
        }
      }

      setCurrentFolder(queuedFolder);
    };

    fetchDirectoryItems();

    return () => {
      sourceRefDirectoryItems.current?.cancel();
    };
  }, [
    engagementGuid,
    folders,
    folderMap,
    isInternalFilesFeatureEnabled,
    isInternalFolderShown,
    queuedFolder,
    setCurrentFolder,
    setLoadingFolderGuid
  ]);

  // update current list of directory items once the current folder updates
  useEffect(() => {
    if (loadingFolderGuid === null) {
      setCurrentDirectoryItems(folderMap[currentFolder?.folderGuid || 'root']);
    }
  }, [currentFolder, folderMap, loadingFolderGuid]);

  // bind event listeners to close browser dropdown on click outside of
  // component
  useEffect(() => {
    if (open) {
      if (rootRef.current) rootRef.current.focus();
      document.addEventListener('click', handleBackgroundClick);
    }

    return () => {
      document.removeEventListener('click', handleBackgroundClick);
    };
  }, [open, handleBackgroundClick]);

  return (
    <div className="file-directory-browser" ref={rootRef}>
      <TextInput
        disabled={isDisabled}
        icon={open ? 'chevron-up' : 'chevron-down'}
        label={label || t('Browse Files')}
        onFocus={handleFocus}
        onIconClick={() => setOpen(prevOpen => !prevOpen)}
        value={folderName || ''}
      />
      {!isDisabled && open && (
        <div className="file-directory-browser__container">
          <ul className="file-directory-browser__options">
            {!isAtRoot && loadingFolderGuid === null && (
              <li className="file-directory-browser__item file-directory-browser__item--back">
                <button onClick={handleBack} type="button">
                  <Icon name="chevron-left" />
                  <Trans i18nKey="selectFolder.backToFolder">
                    <b />
                    {{ folderName: getParentFolderName() }}
                  </Trans>
                </button>
              </li>
            )}
            {currentDirectoryItems.map(directoryItem =>
              renderDirectoryItem(directoryItem)
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default FileDirectoryBrowser;
