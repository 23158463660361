import {
  IRender,
  TUseWorkListColumnsProps
} from './useWorkListColumns.interfaces';
import * as WorkListColumnUtils from './useWorkListColumns.utils';

import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, ITableColumn, Tooltip } from '@ryan/components';

import { useAppReadOnly, useUser } from '../../../../hooks';
import { EntityType, Permission } from '../../../../interfaces';
import {
  TWorklistDataRequestItem,
  TWorklistItem
} from '../../../../routes/Worklist/WorklistOpen/WorklistOpen.interfaces';
import { formatDate } from '../../../../utils/formatDate';
import StatusIcon from '../../../StatusIcon/StatusIcon';
import { WorklistColumnEnums } from '../columns.enums';
import ActionsByEntityType from './ActionsByEntityType/ActionsByEntityType';

const useWorkListColumns = ({
  onDataRequestCommentClick,
  onMarkDataDelivered,
  onMarkTaskInProgress,
  onSetWorklistItemToState,
  onTaskCommentClick
}: TUseWorkListColumnsProps) => {
  const ROOT_TO_TEXT = 'table.columns.worklist';

  const isAppReadOnly = useAppReadOnly();
  const { t: getTextToDisplay } = useTranslation();
  const {
    permissionService,
    user: { profile: activeUserProfile }
  } = useUser();

  return useMemo((): ITableColumn<TWorklistItem>[] => {
    const renderAction: IRender = worklistItem => {
      const {
        createdBy,
        entityTypeId,
        isEngagementReadOnly,
        queueItemGuid,
        statusId
      } = worklistItem;
      const { codeAssistance, isMarkedAsDataDelivered, typeGuid } =
        worklistItem as TWorklistDataRequestItem;

      return (
        <ActionsByEntityType
          commonEntityTypeProps={{ statusId }}
          dataRequestTypeProps={{
            codeAssistance,
            isMarkedAsDataDelivered,
            onMarkDataDelivered: () => {
              onMarkDataDelivered(worklistItem);
            },
            queueItemGuid,
            typeGuid
          }}
          entityTypeId={entityTypeId}
          isEngagementReadOnly={isEngagementReadOnly}
          onSetWorklistItemToState={stateAction => {
            onSetWorklistItemToState(stateAction, worklistItem);
          }}
          taskTypeProps={{
            createdByUserType: createdBy.userType,
            onMarkTaskInProgress: () => {
              onMarkTaskInProgress(worklistItem);
            }
          }}
        />
      );
    };

    const renderAssignedTo: IRender = ({ assignedTo }) => (
      <span>
        {assignedTo.userGuid === activeUserProfile.memberGuid
          ? getTextToDisplay(`${ROOT_TO_TEXT}.assignedToYou`)
          : assignedTo.name}
      </span>
    );

    const renderComment: IRender = worklistItem => {
      const { commentCount, entityTypeId, isEngagementReadOnly } = worklistItem;

      const isReadOnlyByState = isAppReadOnly || isEngagementReadOnly;
      const getCommentArgs = { commentCount, isReadOnlyByState };

      let commentProps: any;

      switch (entityTypeId) {
        case EntityType.DataRequest:
          commentProps = {
            ...WorkListColumnUtils.getCommentProps({
              ...getCommentArgs,
              isReadOnlyByPermissions: permissionService.hasPermission(
                Permission.DataRequestsContribute
              )
            }),
            onClick: () => {
              onDataRequestCommentClick(worklistItem);
            }
          };
          break;
        case EntityType.Task:
          commentProps = {
            ...WorkListColumnUtils.getCommentProps({
              ...getCommentArgs,
              isReadOnlyByPermissions: permissionService.hasPermission(
                Permission.TasksContribute
              )
            }),
            onClick: () => {
              onTaskCommentClick(worklistItem);
            }
          };
          break;
        default:
      }

      if (!commentProps) {
        return <></>;
      }

      return (
        <Tooltip
          {...WorkListColumnUtils.tooltipProps.tooltip}
          content={getTextToDisplay(`${ROOT_TO_TEXT}.${commentProps.path}`, {
            count: commentCount
          })}
          renderTarget={({ open, ref, ...props }) => (
            <Button
              {...props}
              {...WorkListColumnUtils.tooltipProps.button}
              aria-expanded={open}
              icon={commentProps.icon}
              innerRef={ref}
              onClick={commentProps.onClick}
            />
          )}
        />
      );
    };

    const renderDueDate: IRender = ({ dueDate }) => (
      <span className="column-due-date">{formatDate(dueDate)}</span>
    );

    const renderProject: IRender = ({
      engagementDisplayNameShort,
      engagementGuid,
      engagementId
    }) => (
      <div className="column-project">
        <span>{engagementDisplayNameShort}</span>
        {permissionService.isRyan() && <small>{engagementId}</small>}
      </div>

      // TODO: Implement in 2024.5.1
      // <Link
      //   className="column-project table-link"
      //   to={`/app/project/${engagementGuid}`}
      // >
      //   <span>{engagementDisplayNameShort}</span>
      //   {permissionService.isRyan() && <small>{engagementId}</small>}
      // </Link>
    );

    const renderStatus: IRender = worklistItem => {
      const { statusId, entityTypeId } = worklistItem;
      const { isMarkedAsDataDelivered, subStatusId } =
        worklistItem as TWorklistDataRequestItem;

      return (
        <div className="column-status">
          <StatusIcon
            {...(entityTypeId === EntityType.DataRequest &&
              isMarkedAsDataDelivered && { subStatus: subStatusId })}
            status={statusId}
          />
        </div>
      );
    };

    const renderTitle: IRender = ({ accountName, title }) => (
      <div className="column-title">
        <span>{title}</span>
        <small>{accountName}</small>
      </div>
    );

    const renderType: IRender = worklistItem => {
      const { entityTypeId } = worklistItem;
      const { subTypeName } = worklistItem as TWorklistDataRequestItem;

      return (
        <div className="column-type">
          <span>
            {getTextToDisplay(
              `${ROOT_TO_TEXT}.entityTypes.${
                Object.values(EntityType)[entityTypeId - 1]
              }`
            )}
          </span>
          {entityTypeId === EntityType.DataRequest && (
            <small>{subTypeName}</small>
          )}
        </div>
      );
    };

    const columnToRenderMap = {
      [WorklistColumnEnums.ACTION]: renderAction,
      [WorklistColumnEnums.ASSIGNED_TO]: renderAssignedTo,
      [WorklistColumnEnums.COMMENT]: renderComment,
      [WorklistColumnEnums.DUE_DATE]: renderDueDate,
      [WorklistColumnEnums.PROJECT]: renderProject,
      [WorklistColumnEnums.STATUS]: renderStatus,
      [WorklistColumnEnums.TITLE]: renderTitle,
      [WorklistColumnEnums.TYPE]: renderType
    };

    return WorkListColumnUtils.worklistColumnTemplate.map(column => ({
      ...column,
      label: column.label
        ? getTextToDisplay(`${ROOT_TO_TEXT}.${column.label}`)
        : '',
      render: columnToRenderMap[column.id as keyof typeof columnToRenderMap]
    }));
  }, [
    activeUserProfile,
    getTextToDisplay,
    isAppReadOnly,
    onDataRequestCommentClick,
    onMarkDataDelivered,
    onMarkTaskInProgress,
    onSetWorklistItemToState,
    onTaskCommentClick,
    permissionService
  ]);
};

export default useWorkListColumns;
