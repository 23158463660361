import { IMapAssignmentToVariousDataRequestModals } from './DataRequestModals.interfaces';

import { TWorklistDataRequestItem } from '../WorklistOpen/WorklistOpen.interfaces';

export enum DataRequestModalActionEnums {
  ASSIGN_DATA_UPLOAD = 'assignDataUpload',
  COMPLETE = 'complete',
  DELETE = 'delete',
  EDIT = 'edit'
}

export const mapAssignmentToAssignDataUploadRequest: IMapAssignmentToVariousDataRequestModals =
  ({
    attachments,
    engagementDisplayNameShort,
    engagementGuid,
    queueItemGuid,
    title
  }) => ({
    attachments,
    engagementDisplayNameShort,
    engagementGuid,
    queueItemGuid,
    title
  });

export const mapAssignmentToCompleteDataRequest: IMapAssignmentToVariousDataRequestModals =
  ({
    assignedTo,
    description,
    dueDate,
    engagementGuid,
    isFollowing,
    queueItemGuid,
    statusId,
    title,
    ...rest
  }) => {
    const { dataSpecs } = rest as TWorklistDataRequestItem;

    return {
      assignedToUserGuid: assignedTo.userGuid,
      dataSpecs,
      description,
      dueDate: dueDate,
      engagementGuid,
      isCurrentUserWatching: isFollowing,
      queueItemGuid,
      statusId,
      title
    };
  };

export const mapAssignmentToDeleteDataRequest: IMapAssignmentToVariousDataRequestModals =
  ({ engagementGuid, queueItemGuid, title }) => ({
    engagementGuid,
    queueItemGuid,
    title
  });

export const mapAssignmentToEditDataRequest: IMapAssignmentToVariousDataRequestModals =
  ({
    accountName,
    assignedTo,
    attachments,
    description,
    dueDate,
    engagementDisplayNameShort,
    engagementGuid,
    queueItemGuid,
    title,
    ...rest
  }) => {
    const {
      dateRanges,
      dataSpecs,
      defaultFolderGuid,
      documentTypes,
      engagementDisplayNameLong,
      erpSystem,
      jurisdictions,
      otherDocumentType,
      otherErpSystem,
      otherTaxEngine,
      taxEngine,
      typeGuid
    } = rest as TWorklistDataRequestItem;

    return {
      accountName,
      assignedTo: {
        fullName: assignedTo.name,
        memberGuid: assignedTo.userGuid
      },
      attachments,
      dataRequestTypeGuid: typeGuid,
      dataSpecs,
      dateRange: dateRanges.length > 0 ? dateRanges : null, // TODO: Table Refactor: Modal should be able to handle empty list
      defaultFolderGuid,
      description,
      documentTypes,
      dueDate,
      engagementDisplayNameLong,
      engagementDisplayNameShort,
      engagementGuid,
      erpSystem,
      jurisdictions,
      queueItemGuid,
      otherDocumentType,
      otherErpSystem,
      otherTaxEngine,
      taxEngine: taxEngine,
      title
    };
  };
